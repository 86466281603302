import React, { useEffect, useState } from 'react';
import { useTicketDB } from '../../../contexts/TicketDBContext';
import { useAuth } from '../../../contexts/AuthContext';

import SelectorLink from '../../../utils/SelectorLink';

export default function SideMenu() {
	const { ticketsGet } = useTicketDB();
	const { currentUser } = useAuth();

	const [tickets, setTickets] = useState([]);

	useEffect(() => {
		ticketsGet().then(tickets => {
			setTickets(
				tickets.filter(ticket => {
					return (
						ticket.supportID === currentUser.uid &&
						ticket.messages?.[ticket.messages.length - 1].sender === 'client' &&
						ticket.status !== 'closed'
					);
				})
			);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className='col-xl-3 col-lg-12 col-md-12 col-sm-12'>
				<h2 className='bold black'>
					<b>Support Panel</b>
				</h2>
				<hr className='line'></hr>
				<div className='settings-card'>
					<h5 className='bold black'>Tickets</h5>

					<SelectorLink
						to='/support/active-tickets'
						fuzz={['/support/ticket-page']}
						overwrite={['/support/active-tickets']}
						className='account-sb-link'>
						Active Tickets<span className='alert'>{tickets.length + 2}</span>
					</SelectorLink>
					<SelectorLink to='/support/availible-tickets' className='account-sb-link'>
						Holding Tickets
					</SelectorLink>
					{/* <p className='bold black'>Live Support</p> */}
					<SelectorLink
						to='/support/activechats'
						// fuzz={['/support/ticket-page']}
						// overwrite={['/support/active-tickets']}
						className='account-sb-link'>
						Live Chats
					</SelectorLink>
				</div>
			</div>
		</>
	);
}
