import React, { useRef, useState } from 'react';
// import Header from '../components/ui/Header';
import Multiselect from 'multiselect-react-dropdown';
import curvefoot from '../assets/curvefoot.svg';
import { useTranslation } from 'react-i18next';

import serverApi from '../lib/Server';
import { useSiteData } from '../contexts/SiteDataContext';
import { langs } from '../utils/Languages';

export default function SiteOffer() {
	const { t } = useTranslation();
	const { i18n } = useTranslation();
	const { uploadImage } = useSiteData();

	const categoryRef = useRef(null);
	const pageCountRef = useRef(null);
	const langCountRef = useRef(null);
	const domainRef = useRef(null);
	const languageRef = useRef(null);
	const detailsRef = useRef(null);

	const [selectedImage, setSelectedImage] = useState(null);

	function handleUpload(e) {
		setSelectedImage(e.target.files[0]);
	}

	async function handleSubmit(e) {
		e.preventDefault();
		const imageUrl = await uploadImage(selectedImage, selectedImage.name);
		const message = `
      <h1>Web site request</h1>
      <p>Category: ${categoryRef.current.value}</p>
      <p>Page count: ${pageCountRef.current.value}</p>
      <p>Language Count: ${langCountRef.current.value}</p>
      <p>Domain: ${domainRef.current.value}</p>
      <img style='max-width: 120px' src='${imageUrl}'/>
      <p>Languages: ${languageRef.current.getSelectedItems()}</p>
      <p>Details: ${detailsRef.current.value}</p>
      `;
		await serverApi('post', '/sendmail', {
			to: 'khizerminai@gmail.com',
			subject: 'Web site request',
			html: message,
		});
		categoryRef.current.value = '';
		pageCountRef.current.value = '';
		langCountRef.current.value = '';
		domainRef.current.value = '';
		// languageRef.current.value = '';
		detailsRef.current.value = '';
		setSelectedImage(null);
	}

	return (
		<>
			<section className='header'>
				<h1 className='n-font head-title' style={{ color: 'white', fontWeight: 'bold' }}>
					{t('get-offer')}
				</h1>
			</section>
			<section
				style={{
					direction: `${i18n.language === 'ar' ? 'rtl' : ''}`,
					backgroundColor: '#F5F7FC',
				}}
				className='service-container'>
				<h1 className='bold-ser black'>{t('offer.specification')}</h1>
				<div className='space-sm'></div>
				<div className='container offer'>
					<div className='row'>
						<div
							style={{
								textAlign: `${i18n.language === 'ar' ? 'right' : ''}`,
							}}
							className='col-md-6'>
							<div className='offer-item'>
								<label
									style={{
										float: `${i18n.language === 'ar' ? 'none' : ''}`,
										textAlign: `${i18n.language === 'ar' ? 'right' : ''}`,
									}}
									for='Sort'>
									{t('offer.app-cat')} <b className='red'>*</b> :
								</label>
								<select
									ref={categoryRef}
									className='form-field-select-two form-field-select-two-select'
									id='Sort'
									name='Sort By'>
									<option value=''>{t('offer.choose-cat')}</option>

									<option value='new'>{t('store')}</option>
									<option value='old'>{t('real-estate')}</option>
									<option value='high'>{t('tourism')} </option>
									<option value='low'>{t('medical')}</option>
									<option value='low'>{t('restaurants')}</option>
									<option value='low'>{t('education')}</option>
									<option value='low'>{t('other')}</option>
								</select>
							</div>
							<div className='offer-item'>
								<label
									style={{
										float: `${i18n.language === 'ar' ? 'none' : ''}`,
										textAlign: `${i18n.language === 'ar' ? 'right' : ''}`,
									}}
									for='pages'>
									{t('pages')} <b className='red'>*</b> :
								</label>
								<input
									ref={pageCountRef}
									type='number'
									className='form-field-select-two'
									placeholder={t('how-many-pages')}
									id='pages'
								/>
							</div>
							<div className='offer-item'>
								<label
									style={{
										float: `${i18n.language === 'ar' ? 'none' : ''}`,
										textAlign: `${i18n.language === 'ar' ? 'right' : ''}`,
									}}
									for='langNum'>
									{t('languages')} <b className='red'>*</b> :
								</label>
								<input
									ref={langCountRef}
									type='number'
									className='form-field-select-two'
									placeholder={t('how-many-languages')}
									id='langNum'
								/>
							</div>
						</div>
						<div
							style={{
								textAlign: `${i18n.language === 'ar' ? 'right' : ''}`,
							}}
							className='col-md-6'>
							<div className='offer-item'>
								<label
									style={{
										float: `${i18n.language === 'ar' ? 'none' : ''}`,
										textAlign: `${i18n.language === 'ar' ? 'right' : ''}`,
									}}
									for='domain'>
									{t('domain')} :
								</label>
								<input
									ref={domainRef}
									type='text'
									className='form-field-select-two'
									placeholder={t('type-your-domain')}
									id='domain'
								/>
								{/* </div> */}
							</div>
							<div className='offer-item'>
								<label
									style={{
										float: `${i18n.language === 'ar' ? 'none' : ''}`,
										textAlign: `${i18n.language === 'ar' ? 'right' : ''}`,
									}}
									for='logo'>
									{t('logo')} :
								</label>
								{selectedImage ? (
									<img style={{ width: '50%', padding: '1rem' }} src={URL.createObjectURL(selectedImage)} alt='logo' />
								) : (
									<input onChange={handleUpload} type='file' id='logo' name='Logo' className='form-field-select-two' />
								)}
							</div>
							<div className='offer-item'>
								<label
									style={{
										float: `${i18n.language === 'ar' ? 'none' : ''}`,
										textAlign: `${i18n.language === 'ar' ? 'right' : ''}`,
									}}
									for='langNum'>
									{t('select-language')} <b className='red'>*</b> :
								</label>
								<div className='muli-select'>
									<Multiselect
										isObject={false}
										onKeyPressFn={function noRefCheck() {}}
										onRemove={function noRefCheck() {}}
										onSearch={function noRefCheck() {}}
										onSelect={function noRefCheck() {}}
										placeholder={t('select-language')}
										options={langs}
										showCheckbox
										ref={languageRef}
										className='multiselect'
									/>
								</div>
							</div>
						</div>

						<div className='col-md-12'>
							<div
								style={{
									textAlign: `${i18n.language === 'ar' ? 'right' : 'left'}`,
								}}
								className='offer-item-text'>
								<label
									style={{
										float: `${i18n.language === 'ar' ? 'none' : 'left'}`,
										textAlign: `${i18n.language === 'ar' ? 'right' : 'left'}`,
									}}
									className='mb-3'
									for='details'>
									{t('more-details')} :
								</label>
								<div className='details'>
									<textarea ref={detailsRef} rows='5' type='text' id='details' />
								</div>
							</div>
						</div>
						<div className='space-sm' />
						<div className='col-md-12'>
							<button onClick={handleSubmit} className='button-sm'>
								{t('send')}
							</button>
						</div>
					</div>
				</div>
				{/* </div> */}
			</section>
			<img alt='' className='z' src={curvefoot} />
			<div className='curvefoot'></div>
			<div className='curvefoot'></div>
			<div className='curvefoot'></div>
		</>
	);
}
