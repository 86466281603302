import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Link } from 'react-router-dom';

import Logo from '../../assets/logo.svg';
import PossitiveSSL from '../../assets/positiveSSL.png';
import { useTranslation } from 'react-i18next';

export default function Footer() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  return (
    <>
      <section
        style={{
          direction: i18n.language === 'ar' ? 'rtl' : 'ltr',
        }}
        className='footer'
      >
        <div className='container'>
          <div className='row'>
            <div className='col-lg-1' />
            <div className='col-lg-3 col-md-6 col-sm-12 center'>
              <img src={Logo} alt='logo' />
              <p className='black center mt-3'>{t('footer.desc')}</p>
            </div>
            <div className='col-lg-8 col-md-6 col-sm-12 center'>
              <div className='row'>
                <div className='col-lg-4 col-md-6 col-sm-12'>
                  <div className='footer-item'>
                    <Link to='/' className='bold black Link'>
                      {t('home')}
                    </Link>
                  </div>
                  <div className='footer-item'>
                    <Link to='/domains' className='bold black Link'>
                      {t('domains')}
                    </Link>
                  </div>
                  <div className='footer-item'>
                    <Link to='/contact-us' className='bold black Link'>
                      {t('contact-us')}
                    </Link>
                  </div>
                  <div className='footer-item'>
                    <Link to='/blogs' className='bold black Link'>
                      {t('blogs.title')}
                    </Link>
                  </div>
                  <div className='footer-item'>
                    <Link to='/privacy-policy' className='bold black Link'>
                      {t('privacy')}
                    </Link>
                  </div>
                </div>

                <div className='col-lg-4 col-md-6 col-sm-12'>
                  <div className='footer-item'>
                    <Link to='/about-us' className='bold black Link'>
                      {t('about-us')}
                    </Link>
                  </div>
                  <div className='footer-item'>
                    <Link to='/applications' className='bold black Link'>
                      {t('applications')}
                    </Link>
                  </div>
                  <div className='footer-item'>
                    <Link to='/projects' className='bold black Link'>
                      {t('our-works')}
                    </Link>
                  </div>
                  <div className='footer-item'>
                    <Link to='/terms-of-service' className='bold black Link'>
                      {t('terms')}
                    </Link>
                  </div>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-12'>
                  <div className='footer-item'>
                    <Link style={{ fontSize: '1.35rem' }} to='/hosting' className='bold blue Link'>
                      {t('hosting')}
                    </Link>
                  </div>
                  <div className='footer-item'>
                    <Link to='/hosting/shared-hosting' className='bold black Link'>
                      {t('hosting.shared')}
                    </Link>
                  </div>
                  <div className='footer-item'>
                    <Link to='/hosting/reseller-hosting' className='bold black Link'>
                      {t('hosting.reseller')}
                    </Link>
                  </div>
                  <div className='footer-item'>
                    <Link to='/hosting/wordpress-hosting' className='bold black Link'>
                      {t('hosting.wordpress')}
                    </Link>
                  </div>
                  <div className='footer-item'>
                    <Link to='/hosting/email-hosting' className='bold black Link'>
                      {t('hosting.email')}
                    </Link>
                  </div>
                  <div className='footer-item'>
                    <Link to='/hosting/vps-hosting' className='bold black Link'>
                      {t('hosting.vps')}
                    </Link>
                  </div>
                  <div className='footer-item'>
                    <Link to='/hosting/dedicated-hosting' className='bold black Link'>
                      {t('hosting.dedicated')}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-1' />
            <div className='col-lg-3 col-md-6 col-sm-12 center'>
              <div className='footer-icon'>
                <div className='icon'>
                  <a href='https://www.instagram.com/whynot.it/' target='_blank'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='35'
                      height='35'
                      viewBox='0 0 35 35'
                    >
                      <g
                        id='Group_5870'
                        data-name='Group 5870'
                        transform='translate(-250 -8058.807)'
                      >
                        <ellipse
                          id='Ellipse_14'
                          data-name='Ellipse 14'
                          cx='17.5'
                          cy='17.5'
                          rx='17.5'
                          ry='17.5'
                          transform='translate(250 8058.807)'
                          fill='#408bd4'
                        />
                        <path
                          id='Path_8227'
                          data-name='Path 8227'
                          d='M8,0C5.829,0,5.556.01,4.7.048A5.9,5.9,0,0,0,2.76.42a3.917,3.917,0,0,0-1.417.923A3.927,3.927,0,0,0,.42,2.76,5.86,5.86,0,0,0,.048,4.7C.01,5.555,0,5.827,0,8s.01,2.444.048,3.3A5.894,5.894,0,0,0,.42,13.24a4.1,4.1,0,0,0,2.339,2.34,5.873,5.873,0,0,0,1.942.372C5.555,15.99,5.827,16,8,16s2.444-.01,3.3-.048a5.906,5.906,0,0,0,1.943-.372,4.1,4.1,0,0,0,2.339-2.34,5.92,5.92,0,0,0,.372-1.942C15.99,10.445,16,10.173,16,8s-.01-2.445-.048-3.3A5.913,5.913,0,0,0,15.58,2.76a3.926,3.926,0,0,0-.923-1.417A3.911,3.911,0,0,0,13.24.42,5.885,5.885,0,0,0,11.3.048C10.443.01,10.172,0,8,0H8ZM7.283,1.442H8c2.136,0,2.389.007,3.232.046a4.433,4.433,0,0,1,1.486.275,2.482,2.482,0,0,1,.92.6,2.472,2.472,0,0,1,.6.92,4.394,4.394,0,0,1,.275,1.485c.039.843.047,1.1.047,3.231s-.008,2.389-.047,3.232a4.426,4.426,0,0,1-.275,1.485,2.652,2.652,0,0,1-1.519,1.517,4.4,4.4,0,0,1-1.485.276c-.843.038-1.1.047-3.232.047s-2.39-.009-3.233-.047a4.419,4.419,0,0,1-1.485-.276,2.478,2.478,0,0,1-.92-.6,2.48,2.48,0,0,1-.6-.92,4.42,4.42,0,0,1-.275-1.485c-.038-.843-.046-1.1-.046-3.233s.008-2.388.046-3.231a4.426,4.426,0,0,1,.276-1.486,2.482,2.482,0,0,1,.6-.92,2.472,2.472,0,0,1,.92-.6,4.419,4.419,0,0,1,1.485-.276c.738-.034,1.024-.044,2.515-.045v0ZM12.271,2.77a.96.96,0,1,0,.96.96.96.96,0,0,0-.96-.96ZM8,3.892A4.109,4.109,0,1,0,12.11,8,4.108,4.108,0,0,0,8,3.892ZM8,5.333A2.667,2.667,0,1,1,5.334,8,2.667,2.667,0,0,1,8,5.333Z'
                          transform='translate(260 8068)'
                          fill='#fff'
                        />
                      </g>
                    </svg>
                  </a>
                </div>

                <div className='icon'>
                  <a href='https://twitter.com/whynot_it' target='_blank'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='35'
                      height='35'
                      viewBox='0 0 35 35'
                    >
                      <g
                        id='Group_5869'
                        data-name='Group 5869'
                        transform='translate(-304.124 -8058.807)'
                      >
                        <ellipse
                          id='Ellipse_13'
                          data-name='Ellipse 13'
                          cx='17.5'
                          cy='17.5'
                          rx='17.5'
                          ry='17.5'
                          transform='translate(304.124 8058.807)'
                          fill='#408bd4'
                        />
                        <path
                          id='Path_8226'
                          data-name='Path 8226'
                          d='M5.026,15a9.277,9.277,0,0,0,9.341-9.334c0-.14,0-.282-.006-.422A6.685,6.685,0,0,0,16,3.542a6.658,6.658,0,0,1-1.889.518,3.3,3.3,0,0,0,1.447-1.817,6.533,6.533,0,0,1-2.087.793,3.286,3.286,0,0,0-5.6,2.994A9.325,9.325,0,0,1,1.108,2.6,3.289,3.289,0,0,0,2.126,6.983,3.323,3.323,0,0,1,.64,6.575V6.62A3.288,3.288,0,0,0,3.272,9.838a3.2,3.2,0,0,1-.865.115A3.23,3.23,0,0,1,1.793,9.9,3.283,3.283,0,0,0,4.86,12.173,6.588,6.588,0,0,1,.78,13.58,6.32,6.32,0,0,1,0,13.535,9.344,9.344,0,0,0,5.026,15Z'
                          transform='translate(314 8068.001)'
                          fill='#fff'
                        />
                      </g>
                    </svg>
                  </a>
                </div>
                <div className='icon'>
                  <a href='https://www.facebook.com/whynot.it/' target='_blank'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='35'
                      height='35'
                      viewBox='0 0 35 35'
                    >
                      <g
                        id='Group_5869'
                        data-name='Group 5869'
                        transform='translate(-358.248 -8058.807)'
                      >
                        <ellipse
                          id='Ellipse_12'
                          data-name='Ellipse 12'
                          cx='17.5'
                          cy='17.5'
                          rx='17.5'
                          ry='17.5'
                          transform='translate(358.248 8058.807)'
                          fill='#408bd4'
                        />
                        <path
                          id='Path_8225'
                          data-name='Path 8225'
                          d='M16,8.049A8,8,0,1,0,6.748,16V10.376H4.718V8.05H6.75V6.275A2.832,2.832,0,0,1,9.772,3.144a12.237,12.237,0,0,1,1.791.157v1.98H10.554a1.16,1.16,0,0,0-1.3,1.258v1.51h2.218l-.354,2.326H9.25V16A8.037,8.037,0,0,0,16,8.049Z'
                          transform='translate(367.749 8068.307)'
                          fill='#fff'
                        />
                      </g>
                    </svg>
                  </a>
                </div>
                <div className='icon'>
                  <a
                    href='https://www.linkedin.com/company/why-not-tech/mycompany/'
                    target='_blank'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='35'
                      height='35'
                      viewBox='0 0 35 35'
                    >
                      <g
                        id='Group_5868'
                        data-name='Group 5868'
                        transform='translate(-412.372 -8058.807)'
                      >
                        <ellipse
                          id='Ellipse_15'
                          data-name='Ellipse 15'
                          cx='17.5'
                          cy='17.5'
                          rx='17.5'
                          ry='17.5'
                          transform='translate(412.372 8058.807)'
                          fill='#408bd4'
                        />
                        <path
                          id='Path_8224'
                          data-name='Path 8224'
                          d='M0,1.146A1.161,1.161,0,0,1,1.175,0h13.65A1.161,1.161,0,0,1,16,1.146V14.854A1.161,1.161,0,0,1,14.825,16H1.175A1.161,1.161,0,0,1,0,14.854ZM4.943,13.394V6.169h-2.4v7.225Zm-1.2-8.212a1.252,1.252,0,1,0,.016-2.5,1.251,1.251,0,1,0-.032,2.5Zm4.908,8.212V9.359a1.63,1.63,0,0,1,.08-.586,1.313,1.313,0,0,1,1.232-.878c.869,0,1.216.662,1.216,1.634v3.865h2.4V9.25C13.58,7.03,12.4,6,10.816,6A2.386,2.386,0,0,0,8.651,7.191v.025H8.635l.016-.025V6.169h-2.4c.03.678,0,7.225,0,7.225Z'
                          transform='translate(422 8068)'
                          fill='#fff'
                        />
                      </g>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='footer-footer'>
        <p className='thin white'>
          This website is protected by copyright © 2008–2022 Why Not Tech, Inc.
        </p>
        <img src={PossitiveSSL} alt='Positive SSL' />
      </section>
    </>
  );
}
