import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useAuth } from '../../../contexts/AuthContext';
import { useTicketDB } from '../../../contexts/TicketDBContext';

function Ticket({ num, title, status, replies, department, activity, ticketID }) {
	return (
		<tr>
			<td>{num}</td>
			<td>
				<Link className='bold blue Link' to={`/support/ticket-page/${ticketID}`}>
					{title}
				</Link>
			</td>
			<td className={status ? 'green' : 'red'}>{status ? 'Active' : 'Closed'}</td>
			<td>{replies}</td>
			<td>{department}</td>
			<td>{activity}</td>
		</tr>
	);
}

export default function MyTickets() {
	const { ticketsGet } = useTicketDB();
	const { currentUser } = useAuth();
	let count = 0;

	const [tickets, setTickets] = useState([]);

	useEffect(() => {
		ticketsGet().then(tickets => setTickets(tickets.filter(ticket => ticket.supportID === currentUser.uid)));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className='col-xl-8 col-lg-12 col-md-12 col-sm-12'>
				<div className='space'></div>
				<h4 className='semibold gray'>My Tickets</h4>
				<div className='space-sm'></div>
				<div className='options'></div>

				<div className='space-sm'></div>
				<table className='table'>
					<thead>
						<tr>
							<th>#</th>
							<th>Title</th>
							<th>Status</th>
							<th>Replies</th>
							<th>Department</th>
							<th>Activity</th>
						</tr>
					</thead>
					<tbody>
						{tickets
							.filter(ticket => ticket.status !== 'closed')
							.map(ticket => {
								count++;
								return (
									<Ticket
										key={ticket._id}
										num={count}
										title={ticket.title}
										status={ticket.status}
										replies={ticket.messages?.length}
										department={ticket.department}
										activity={
											ticket.updatedAt
												? Math.floor((Date.now() - ticket.updatedAt.toDate()) / (1000 * 3600 * 24)) + ' days ago'
												: 'No Activity'
										}
										ticketID={ticket.id}
									/>
								);
							})}
					</tbody>
				</table>
			</div>
		</>
	);
}
