import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
// import { Link } from 'react-router-dom';
import Service from '../../../components/cards/Service';
import AppDesign from '../../../assets/services/App-design.svg';
import WebDesign from '../../../assets/services/Web-design.svg';
import WebHosting from '../../../assets/services/Web-hosting.svg';
import Marketing from '../../../assets/services/Marketing.svg';
import GraphicDesign from '../../../assets/services/Graphic-design.svg';
import MotionGraphic from '../../../assets/services/Motion-graphic.svg';
import { useTranslation } from 'react-i18next';
export default function ServiceContainer() {
  const { t } = useTranslation();
  return (
    <>
      <section className='service-container'>
        <h1 className='bold-ser black'>{t('providebestservices')}</h1>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4 col-md-6 col-sm-12'>
              <Service
                img={AppDesign}
                Title={t('appdesign')}
                desc={t('appdesign.desc')}
                link='/applications'
              />
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12'>
              <Service
                img={WebDesign}
                Title={t('webdesign')}
                desc={t('webdesign.desc')}
                link='/websites'
              />
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12'>
              <Service
                img={WebHosting}
                Title={t('webhosting')}
                desc={t('webhosting.desc')}
                link='/hosting'
              />
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12'>
              <Service
                img={Marketing}
                Title={t('marketing')}
                desc={t('marketing.desc')}
                link='/marketing'
              />
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12'>
              <Service
                img={GraphicDesign}
                Title={t('graphicdesign')}
                desc={t('graphicdesign.desc')}
                link='/graphic-design'
              />
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12'>
              <Service
                img={MotionGraphic}
                Title={t('motiongraphic')}
                desc={t('motiongraphic.desc')}
                link='/motion-graphic'
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
