import React, { useState, useRef } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export default function ChangePassword() {
  const { t } = useTranslation();
  const oldPassswordRef = useRef();
  const newPasswordRef = useRef();
  const confirmPasswordRef = useRef();

  const { updatePassword, reauthenticate } = useAuth();
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    if (newPasswordRef.current.value !== confirmPasswordRef.current.value)
      return setError('Passwords do not match');

    if (!oldPassswordRef.current.value) return setError('Please enter your old password');

    setLoading(true);

    try {
      await reauthenticate(oldPassswordRef.current.value);
      await updatePassword(newPasswordRef.current.value);
      setError(null);
      setMessage('Password updated successfully');
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
    setLoading(false);
  }

  return (
    <>
      <div className='col-xl-8 col-lg-12 col-md-12 col-sm-12'>
        <div className='space'></div>
        <div className='change-pass'>
          <h4 className='form-font center'>{t('sidemenu.change-password')}</h4>
          {message && <Alert variant='success'>{message}</Alert>}
          {error && <Alert variant='danger'>{error}</Alert>}
          <form onSubmit={handleSubmit}>
            <input
              ref={oldPassswordRef}
              type='password'
              className='form-field'
              placeholder={t('old-password')}
            />
            <input
              ref={newPasswordRef}
              type='password'
              className='form-field'
              placeholder={t('new-password')}
            />
            <input
              ref={confirmPasswordRef}
              type='password'
              className='form-field'
              placeholder={t('confirm-new-password')}
            />
            <div className='form-elements'>
              <button
                disabled={loading}
                variant='primary'
                type='submit'
                className='button signin-button'
              >
                {t('reset-password')}
              </button>
            </div>
          </form>
        </div>
        <div className='space'></div>
      </div>
    </>
  );
}
