import React, { useRef, useState } from 'react';
import { Form, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useAuth } from '../contexts/AuthContext';
// import background from '../assets/sinebg.svg';

export default function Signup() {
  const emailRef = useRef();
  const { resetPassword } = useAuth();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setMessage(null);
      setError(null);
      setLoading(true);
      await resetPassword(emailRef.current.value);
      setMessage('Password reset email sent.');
    } catch (error) {
      setError('Password reset failed. Please try again.');
    }
    setLoading(false);
  }

  return (
    <>
      <label></label>
      <div className='card-area'>
        <div className='row'>
          <div className='d-table'>
            <div className='d-table-cell'>
              <div className='signup-form'>
                <h4 className='form-font center'>Reset Password</h4>
                {error && <Alert variant='danger'>{error}</Alert>}
                {message && <Alert variant='success'>{message}</Alert>}
                <Form onSubmit={handleSubmit}>
                  <input name='email' type='text' className='form-field' placeholder='Email' />
                  <div className='form-elements'>
                    <Link className='form-link' to='/signup'>
                      Need an account? Sign Up
                    </Link>
                  </div>
                  <div className='form-elements'>
                    <Link className='signup-button' to='/login'>
                      Sign In
                    </Link>
                  </div>

                  <button
                    disabled={loading}
                    variant='primary'
                    type='submit'
                    className='button signin-button'
                  >
                    Reset Password
                  </button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <svg
        className='svg'
        xmlns='http://www.w3.org/2000/svg'
        width='100vw'
        height='100%'
        viewBox='0 0 2023.912 163.611'
      >
        <path
          id='Path_6220'
          data-name='Path 6220'
          d='M.2,3694.044s422-163.17,1104.437,42.178c440.684,132.6,919.161,0,919.161,0'
          transform='translate(-0.016 -3632.031)'
          fill='none'
          stroke='#ff6653'
          stroke-width='1'
        />
      </svg>
    </>
  );
}
