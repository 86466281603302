import React, { useRef, useState, useEffect } from 'react';
import { Form, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../contexts/AuthContext';
import { useUserDB } from '../../../contexts/UserDBContext';

export default function UpdateInfo() {
	const { i18n } = useTranslation();
	const { t } = useTranslation();
	const emailRef = useRef();
	const userNameRef = useRef();
	const firstNameRef = useRef();
	const lastNameRef = useRef();
	const organizationRef = useRef();
	const phoneRef = useRef();
	const passwordRef = useRef();

	const { currentUser } = useAuth();
	const { userInfoUpdate } = useUserDB();

	const [selectedImage, setSelectedImage] = useState(false);
	const [error, setError] = useState(null);
	const [message, setMessage] = useState(null);
	const [loading, setLoading] = useState(false);
	const [pPop, setpPop] = useState(false);

	function handleUpload(e) {
		setSelectedImage(e.target.files[0]);
	}

	function saveChanges(e) {
		e.preventDefault();
		setpPop(true);
	}

	async function handleSubmit(e) {
		e.preventDefault();
		setError(null);
		setMessage(null);
		setLoading(true);
		setpPop(null);
		try {
			await userInfoUpdate({
				email: emailRef.current.value,
				userName: userNameRef.current.value,
				firstName: firstNameRef.current.value,
				lastName: lastNameRef.current.value,
				organization: organizationRef.current.value,
				phoneNumber: phoneRef.current.value,
				password: passwordRef.current.value,
				...(selectedImage && { file: selectedImage }),
			});
			setMessage('Profile updated successfully');
		} catch (error) {
			setError(error.message);
		}
		setLoading(false);
	}

	useEffect(() => {
		emailRef.current.placeholder = currentUser.data.email;
		userNameRef.current.placeholder = currentUser.data.userName;
		firstNameRef.current.placeholder = currentUser.data.firstName;
		lastNameRef.current.placeholder = currentUser.data.lastName;
		organizationRef.current.placeholder = currentUser.data.organization || '';
		phoneRef.current.placeholder = currentUser.data.phoneNumber || '';
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{pPop && (
				<section className='password-popup'>
					<div className='signup-form'>
						<h4 className='form-font center'>{t('confirm-password')}</h4>
						<Form>
							<input ref={passwordRef} name='email' type='password' className='form-field' placeholder='Password' />
							<button
								onClick={handleSubmit}
								disabled={loading}
								variant='primary'
								type='submit'
								className='button signin-button'>
								{t('confirm-password')}
							</button>
						</Form>
					</div>
				</section>
			)}
			<div className='col-xl-8 col-lg-12 col-md-12 col-sm-12'>
				<div className='space'></div>

				<h4 className='semibold gray'>{t('general-information')}</h4>
				{error && <Alert variant='danger'>{error}</Alert>}
				{message && <Alert variant='success'>{message}</Alert>}
				<div className='d-table'>
					<div className='form-fields'>
						<Form>
							<div>
								<p className='input-lable'>{t('email')}:</p>
								<input
									style={{
										direction: `${i18n.language === 'ar' ? 'ltr' : ''}`,
										textAlign: `${i18n.language === 'ar' ? 'left' : 'left'}`,
										marginRight: `${i18n.language === 'ar' ? '10rem' : ''}`,
									}}
									ref={emailRef}
									type='text'
									className='form-field-small'
								/>
							</div>
							<div>
								<p className='input-lable'>{t('username')}:</p>
								<input
									style={{
										direction: `${i18n.language === 'ar' ? 'ltr' : ''}`,
										textAlign: `${i18n.language === 'ar' ? 'left' : 'left'}`,
										marginRight: `${i18n.language === 'ar' ? '10rem' : ''}`,
									}}
									ref={userNameRef}
									type='text'
									className='form-field-small'
								/>
							</div>
							<div>
								<p className='input-lable'>{t('firstname')}:</p>
								<input
									style={{
										marginRight: `${i18n.language === 'ar' ? '10rem' : ''}`,
									}}
									ref={firstNameRef}
									type='text'
									className='form-field-small'
								/>
							</div>
							<div>
								<p className='input-lable'>{t('lastname')}:</p>
								<input
									style={{
										marginRight: `${i18n.language === 'ar' ? '10rem' : ''}`,
									}}
									ref={lastNameRef}
									type='text'
									className='form-field-small'
								/>
							</div>
							<div>
								<p className='input-lable'>{t('organization')}:</p>
								<input
									style={{
										marginRight: `${i18n.language === 'ar' ? '10rem' : ''}`,
									}}
									ref={organizationRef}
									type='text'
									className='form-field-small'
								/>
							</div>
							<div>
								<p className='input-lable'>{t('phone')}:</p>
								<input
									style={{
										direction: `${i18n.language === 'ar' ? 'ltr' : ''}`,
										textAlign: `${i18n.language === 'ar' ? 'left' : 'left'}`,
										marginRight: `${i18n.language === 'ar' ? '10rem' : ''}`,
									}}
									ref={phoneRef}
									type='text'
									className='form-field-small'
								/>
							</div>
							{currentUser?.data?.role !== 'client' && (
								<div>
									<p className='input-lable'>{t('upload-profile-picture')}:</p>
									<div
										style={{
											direction: `${i18n.language === 'ar' ? 'ltr' : ''}`,
											textAlign: `${i18n.language === 'ar' ? 'right' : 'left'}`,
											marginLeft: `${i18n.language === 'ar' ? '0%' : '30%'}`,
											marginRight: `${i18n.language === 'ar' ? '30%' : '0'}`,
										}}>
										{selectedImage ? (
											<>
												<div
													style={{
														float: `${i18n.language === 'ar' ? 'right' : 'left'}`,
														width: 'fit-content',
													}}></div>
												<img src={URL.createObjectURL(selectedImage)} style={{ height: '50%', width: '30%' }} alt='' />
												<button className='btn btn-danger m-5' onClick={() => setSelectedImage(null)}>
													{t('remove')}
												</button>
											</>
										) : !(selectedImage || currentUser?.data?.imageUrl) ? (
											<input
												className='signup-button'
												style={{
													width: `${i18n.language === 'ar' ? '70%' : '80%'}`,
													marginLeft: `${i18n.language === 'ar' ? '0' : '2rem'}`,
													marginRight: `${i18n.language === 'ar' ? '2rem' : '0'}`,
												}}
												onChange={handleUpload}
												type='file'
												id='img'
												name='img'
												accept='image/*'
											/>
										) : currentUser?.data?.imageUrl ? (
											<>
												<img src={currentUser?.data?.imageUrl} style={{ height: '50%', width: '30%' }} alt='' />
												<input
													className='signup-button'
													style={{
														width: `${i18n.language === 'ar' ? '70%' : '80%'}`,
														marginLeft: `${i18n.language === 'ar' ? '0' : '2rem'}`,
														marginRight: `${i18n.language === 'ar' ? '2rem' : '0'}`,
													}}
													onChange={handleUpload}
													type='file'
													id='img'
													name='img'
													accept='image/*'
												/>
											</>
										) : null}
									</div>
								</div>
							)}
							<button disabled={loading} onClick={saveChanges} className='button-sm'>
								{t('save-changes')}
							</button>
						</Form>
					</div>
				</div>
			</div>
		</>
	);
}
