import React, { createContext, useContext } from 'react';
import { firestore, storageRef } from '../lib/Firebase';

const SiteDataContext = createContext();

export function useSiteData() {
	return useContext(SiteDataContext);
}

export function SiteDataProvider({ children }) {
	async function uploadImage(file, path) {
		const fileRef = storageRef.child(path);
		await fileRef.put(file);
		const url = await fileRef.getDownloadURL();
		return url;
	}

	async function hostingPlanAdd(name, docNum, info) {
		await firestore.collection('siteData').doc('HostingPlans').collection(name).doc(docNum).set(info, { merge: true });
	}

	async function hostingPlanUpdate(name, docNum, hostingPlan) {
		await firestore
			.collection('siteData')
			.doc('HostingPlans')
			.collection(name)
			.doc(docNum)
			.update(hostingPlan, { merge: true });
	}

	async function hostingPlanGet(name) {
		try {
			const hostingPlan = await firestore.collection('siteData').doc('HostingPlans').collection(name).get();
			return hostingPlan.docs.map(doc => doc.data());
		} catch (error) {
			console.log(error);
		}
	}

	async function hostingPlanDelete(name, docNum) {
		try {
			await firestore.collection('siteData').doc('HostingPlans').collection(name).doc(`${docNum}`).delete();
		} catch (error) {
			console.log(error);
		}
	}

	async function blogAdd(info, file) {
		const imageUrl = await uploadImage(file, `BlogImages/${file.name}`);
		const blog = {
			...info,
			imageUrl,
			createdAt: Date.now(),
		};
		await firestore.collection('siteData').doc('Content').collection('Blogs').doc().set(blog, { merge: true });
	}

	async function blogUpdate(id, info, image = null) {
		let imageUrl = null;
		if (image) {
			imageUrl = await uploadImage(image, `BlogImages/${image.name}`);
		}

		const blog = {
			...(info && { ...info }),
			...(imageUrl && { imageUrl }),
			createdAt: Date.now(),
		};
		await firestore.collection('siteData').doc('Content').collection('Blogs').doc(id).update(blog);
	}

	async function blogGet(id) {
		try {
			const blog = await firestore.collection('siteData').doc('Content').collection('Blogs').doc(id).get();
			return { _id: blog.id, ...blog.data() };
		} catch (error) {
			console.log(error);
		}
	}

	async function blogsGet() {
		try {
			const blogs = await firestore.collection('siteData').doc('Content').collection('Blogs').get();
			return blogs.docs.map(doc => {
				return { _id: doc.id, ...doc.data() };
			});
		} catch (error) {
			console.log(error);
		}
	}

	async function blogDelete(id) {
		try {
			await firestore.collection('siteData').doc('Content').collection('Blogs').doc(id).delete();
		} catch (error) {
			console.log(error);
		}
	}

	async function projectAdd(info, files) {
		const imageUrls = await Promise.all(
			files.map(async file => {
				return await uploadImage(file, `ProjectImages/${file.name}`);
			})
		);

		const project = {
			...info,
			imageUrls,
			createdAt: Date.now(),
		};
		await firestore.collection('siteData').doc('Content').collection('Projects').doc().set(project, { merge: true });
	}

	async function projectUpdate(id, info, files = null) {
		let imageUrls = null;
		let notEmpty = false;
		for (let i in files) if (i) notEmpty = true;

		if (notEmpty) {
			const oldImageUrls = await firestore
				.collection('siteData')
				.doc('Content')
				.collection('Projects')
				.doc(id)
				.get()
				.then(doc => doc.data().imageUrls);

			imageUrls = await Promise.all(
				files.map(async (file, i) => {
					if (!file) {
						return oldImageUrls[i];
					}
					return await uploadImage(file, `ProjectImages/${file.name}`);
				})
			);
		}

		const project = {
			...info,
			...(imageUrls && { imageUrls }),
			createdAt: Date.now(),
		};
		await firestore.collection('siteData').doc('Content').collection('Projects').doc(id).update(project);
	}

	async function projectGet(id) {
		try {
			const project = await firestore.collection('siteData').doc('Content').collection('Projects').doc(id).get();
			return { _id: project.id, ...project.data() };
		} catch (error) {
			console.log(error);
		}
	}

	async function projectsGet() {
		try {
			const projects = await firestore.collection('siteData').doc('Content').collection('Projects').get();
			return projects.docs.map(doc => {
				return { _id: doc.id, ...doc.data() };
			});
		} catch (error) {
			console.log(error);
		}
	}

	async function projectDelete(id) {
		try {
			await firestore.collection('siteData').doc('Content').collection('Projects').doc(id).delete();
		} catch (error) {
			console.log(error);
		}
	}

	async function premiumDomainAdd(domain, price, renewPrice) {
		const premiumDomain = {
			domain,
			price,
			renewPrice,
			createdAt: Date.now(),
		};
		await firestore
			.collection('siteData')
			.doc('PremiumDomains')
			.collection('Domains')
			.doc()
			.set(premiumDomain, { merge: true });
	}

	async function premiumDomainUpdate(id, domain, price, renewPrice) {
		const premiumDomain = {
			domain,
			price,
			renewPrice,
			createdAt: Date.now(),
		};
		await firestore.collection('siteData').doc('PremiumDomains').collection('Domains').doc(id).update(premiumDomain);
	}

	async function premiumDomainGet(id) {
		try {
			const premiumDomain = await firestore
				.collection('siteData')
				.doc('PremiumDomains')
				.collection('Domains')
				.doc(id)
				.get();
			return { _id: premiumDomain.id, ...premiumDomain.data() };
		} catch (error) {
			console.log(error);
		}
	}

	async function premiumDomainsGet() {
		try {
			const premiumDomains = await firestore.collection('siteData').doc('PremiumDomains').collection('Domains').get();
			return premiumDomains.docs.map(doc => {
				return { _id: doc.id, ...doc.data() };
			});
		} catch (error) {
			console.log(error);
		}
	}

	async function premiumDomainDelete(id) {
		try {
			await firestore.collection('siteData').doc('PremiumDomains').collection('Domains').doc(id).delete();
		} catch (error) {
			console.log(error);
		}
	}

	async function premiumDomainRenew(id) {
		try {
			await firestore.collection('siteData').doc('PremiumDomains').collection('Domains').doc(id).update({
				renewedAt: Date.now(),
			});
		} catch (error) {
			console.log(error);
		}
	}

	const value = {
		uploadImage,
		hostingPlanAdd,
		hostingPlanGet,
		hostingPlanUpdate,
		hostingPlanDelete,
		blogAdd,
		blogsGet,
		blogGet,
		blogUpdate,
		blogDelete,
		projectAdd,
		projectUpdate,
		projectGet,
		projectsGet,
		projectDelete,
		premiumDomainAdd,
		premiumDomainUpdate,
		premiumDomainGet,
		premiumDomainsGet,
		premiumDomainDelete,
		premiumDomainRenew,
	};
	return <SiteDataContext.Provider value={value}>{children}</SiteDataContext.Provider>;
}
