import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { useSiteData } from '../../../contexts/SiteDataContext';

export default function ListPlans() {
	const { type } = useParams();
	const planType = type
		.split('-')
		.map(word => word[0].charAt(0).toUpperCase() + word.slice(1))
		.join(' ');

	const { hostingPlanGet, hostingPlanDelete } = useSiteData();

	const [plans, setPlans] = useState([]);

	async function handleDelete(i) {
		hostingPlanDelete(planType, i).then(getPlans());
	}

	function getPlans() {
		hostingPlanGet(planType).then(data => setPlans(data));
	}

	useEffect(() => {
		getPlans();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<section className='blue-bg'>
				<Link to='/cpanel' className='signup-button Link'>
					Return to cPanel
				</Link>
				<div className='space'></div>
				<div className='container'>
					<h1 className='center bolder blue'>{planType}</h1>
					<div className='row'>
						<Link to={`/cpanel/addplan/${type}`} className='signup-button Link'>
							Add a plan
						</Link>
						<div className='col-md-12'>
							<table className='table center'>
								<thead>
									<tr>
										<th>
											<h5 className='bold'>Plan name</h5>
										</th>
										<th>View</th>
										<th>Edit</th>
										<th>
											<button className='button-sm'>Delete</button>
										</th>
									</tr>
								</thead>
								<tbody>
									{plans &&
										plans.map((plan, i) => (
											<tr>
												<th>
													<h5 className='bold'>{plan.planName}</h5>
												</th>
												<th>
													<Link to={`/hosting/${type}`} className='Link blue'>
														View
													</Link>
												</th>
												<th>
													<Link to={`/cpanel/postplanupdate/${type}/${i + 1}`} className='Link blue'>
														Edit
													</Link>
												</th>
												<th>
													<button
														onClick={e => {
															e.preventDefault();
															handleDelete(i + 1);
														}}
														className='button-sm'>
														Delete
													</button>
												</th>
											</tr>
										))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
