import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
export default function Error404() {
  const { t } = useTranslation();
  return (
    <>
      <div style={{ padding: '10% 0' }} className='blue-bg center'>
        <h1 className='bolder black mb-2'>{t('404')}</h1>
        <h2 className='bold gray mb-5'>{t('404-message')}</h2>
        <Link to='/' className='signin-button Link'>
          {t('home')}
        </Link>
      </div>
    </>
  );
}
