import React, { useRef, useState } from 'react';
import { Form, Alert } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';

export default function SupportLogin() {
	const emailRef = useRef();
	const passwordRef = useRef();
	const { login } = useAuth();
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	async function handleSubmit(e) {
		e.preventDefault();

		try {
			setError(null);
			setLoading(true);
			await login(emailRef.current.value, passwordRef.current.value);
			navigate('/support');
		} catch (error) {
			setError('Log in failed. Please try again.');
		}
		setLoading(false);
	}

	return (
		<>
			<div className='card-area'>
				<div className='row'>
					<div className='d-table'>
						<div className='d-table-cell'>
							<div className='signup-form'>
								<h4 className='form-font center'>Support Sign In</h4>
								{error && <Alert variant='danger'>{error}</Alert>}
								<Form onSubmit={handleSubmit}>
									<div className='form-fields'>
										<Form.Group id='email'>
											<input className='form-field' type='email' ref={emailRef} placeholder='Email' />
										</Form.Group>
										<Form.Group id='password'>
											<input className='form-field' type='password' ref={passwordRef} placeholder='Password' />
										</Form.Group>
									</div>

									<div className='form-elements'>
										<div className='form-font'>
											<input type='checkbox' id='check' className='form-check-input'></input> Remember me
										</div>
										<Link className='form-link' to='/reset-password'>
											<i>Forgot Password?</i>
										</Link>
									</div>
									<div className='form-elements'>
										<button disabled={loading} variant='primary' type='submit' className='button signin-button'>
											Sign In
										</button>
										<Link className='button signup-button' to='/signup'>
											Sign Up
										</Link>
									</div>
								</Form>
							</div>
						</div>
					</div>
				</div>
			</div>
			<svg className='svg' xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' viewBox='0 0 2023.912 163.611'>
				<path
					id='Path_6220'
					data-name='Path 6220'
					d='M.2,3694.044s422-163.17,1104.437,42.178c440.684,132.6,919.161,0,919.161,0'
					transform='translate(-0.016 -3632.031)'
					fill='none'
					stroke='#ff6653'
					strokeWidth='1'
				/>
			</svg>
		</>
	);
}
