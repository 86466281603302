import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { motion } from 'framer-motion';
export default function Blog(props) {
  return (
    <>
      <Link to={props.link} className='Link'>
        <motion.div
          initial={{ y: -30, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          className='card-spacer'
        >
          <div className='blog-card'>
            <img alt={props.title} src={props.img} />
          </div>
          <div className='blog-card-link bold'>{props.title}</div>
        </motion.div>
      </Link>
    </>
  );
}
