import React, { useEffect, useRef, useState } from 'react';

import { useStates } from '../contexts/StatesContext';
import { useSiteData } from '../contexts/SiteDataContext';
import { useProductDB } from '../contexts/ProductDBContext';
import { useUserDB } from '../contexts/UserDBContext';
import { useAuth } from '../contexts/AuthContext';

import Mouse from '../assets/domainmouse.svg';
import bg from '../assets/NewArt/Domains.svg';
// import curvehead from '../assets/curvehead.svg';
import Service from '../components/cards/Service';
import curvefoot from '../assets/curvefoot.svg';
import img1 from '../assets/Domains/image1.svg';
import img2 from '../assets/Domains/image2.svg';
import img3 from '../assets/Domains/image3.svg';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function domInCart(cart, domain) {
  const c = cart.filter((item) => item.data().name === domain);
  return c.length > 0;
}

export default function Domains() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const discoutPercent = 1.5;
  const searchRef = useRef(null);
  const { searchFullDomain } = useProductDB();
  const { premiumDomainsGet } = useSiteData();
  const { userAddToCart } = useUserDB();
  const { currentUser } = useAuth();
  const { cart } = useStates();

  const [searchResults, setSearchResults] = useState([]);
  const [premiumDomains, setPremiumDomains] = useState([]);
  const [available, setAvailable] = useState(false);
  const [nextAvailable, setNextAvailable] = useState(null);
  const [mainCarted, setMainCarted] = useState(false);
  const defaultTlds = ['com', 'net', 'org', 'biz', 'info', 'io'];

  function handleAddToCart(e) {
    e.preventDefault();

    userAddToCart(currentUser.uid, {
      price: parseInt(searchResults.price),
      period: 'monthly',
      type: 'Domain',
      name: searchResults.name,
      data: {
        features: searchResults.features,
        specs: searchResults.specs,
      },
    });
  }

  function handleSearch(e) {
    e.preventDefault();

    const dparts = searchRef.current.value.split('.');
    const dname = dparts[0];
    const searchTld = dparts[1];

    if (defaultTlds.includes(searchTld)) {
      defaultTlds.splice(defaultTlds.indexOf(searchTld), 1);
    }

    defaultTlds.unshift(searchTld);
    if (defaultTlds.includes(undefined)) defaultTlds.splice(defaultTlds.indexOf(undefined), 1);
    searchFullDomain(dname, defaultTlds).then((res) => {
      console.log(res);
      if (res.length > 1 && !res[0].success && res.filter((item) => item.success).length > 0)
        res[0].available = 'kinda';
      setSearchResults(res);
    });
  }

  useEffect(() => {
    if (searchResults?.[0]?.available === 'true') {
      setAvailable(true);
    } else {
      setAvailable(false);
      const nAv = searchResults.find((res) => res.available === 'true');
      nAv && setNextAvailable(nAv);
    }
  }, [searchResults]);

  useEffect(() => {
    premiumDomainsGet().then((res) => setPremiumDomains(res));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <img src={bg} style={{ zIndex: '-1', width: '100%', position: 'absolute', left: '0' }} />
      <section className='domain-search'>
        <div className='container'>
          <div className='row'>
            {/* <div className='space-sm' /> */}
            <div className='col-xl-8 col-lg-12 col-md-12 col-sm-12'>
              {/* <img className='domain-img' alt='' width='80%' src={Mouse} /> */}
            </div>
            <div className='col-xl-4 col-lg-12 col-md-12 col-sm-12 domain-title'>
              <div className='dom-space' />
              <h1
                style={{
                  direction: `${i18n.language === 'ar' ? 'rtl' : ''}`,
                  textAlign: `${i18n.language === 'ar' ? 'right' : 'left'}`,
                  paddingRight: `${i18n.language === 'ar' ? '4vw' : '0'}`,
                }}
                className='bolder blue'
              >
                {t('domain.search-for-your')}
                <br></br>
                <b className='orange'>{t('domain.project-name')}</b> {t('domain.now')}
              </h1>

              <div className='search-bar'>
                <Form onSubmit={handleSearch}>
                  <input
                    style={{
                      direction: `${i18n.language === 'ar' ? 'rtl' : ''}`,
                      textAlign: `${i18n.language === 'ar' ? 'right' : 'left'}`,
                    }}
                    ref={searchRef}
                    type='text'
                    placeholder={t('findyourdomain')}
                  ></input>
                  <button type='submit'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='16'
                      height='16'
                      fill='currentColor'
                      className='bi bi-search'
                      viewBox='0 0 16 16'
                    >
                      <path d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'></path>
                    </svg>
                  </button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        {searchResults?.find((res) => res.available === 'true') ? (
          <>
            {searchResults?.length >= 1 && (
              <>
                <div className='space'></div>
                <div className='container'>
                  <div className='row'>
                    <div className='col-12'>
                      <div className='domain-result-container'>
                        <div className='domain-result'>
                          <div className='container'>
                            <div className='row'>
                              <div style={{ textAlign: 'left' }} className='col-lg-6 col-sm-12'>
                                <p style={{ margin: 0 }} className='gray thin'>
                                  {searchResults?.[0]?.domain ||
                                    searchResults?.filter((d) => d.available === 'true')?.[0]
                                      ?.domain}{' '}
                                  is
                                  {available
                                    ? ''
                                    : searchResults?.[0]?.available === 'kinda'
                                    ? ''
                                    : 'not'}{' '}
                                  available
                                </p>
                                <h4 className='bolder black'>
                                  {available ? searchResults?.[0]?.domain : nextAvailable?.domain}
                                </h4>
                              </div>
                              <div style={{ textAlign: 'right' }} className='col-lg-4 col-sm-12'>
                                <div style={{ display: 'inline-block', textAlign: 'center' }}>
                                  <p style={{ margin: 0 }} className='red bold'>
                                    ON SALE
                                  </p>
                                  <div style={{ display: 'flex' }}>
                                    <h5
                                      style={{
                                        textDecoration: 'line-through',
                                        marginRight: '0.6rem',
                                      }}
                                      className='thin gray'
                                    >
                                      $
                                      {(
                                        parseFloat(
                                          available
                                            ? searchResults?.[0]?.registerPrice
                                            : nextAvailable?.registerPrice
                                        ) * discoutPercent
                                      ).toFixed(2)}
                                    </h5>
                                    <h4 style={{ margin: 0 }} className='bolder black'>
                                      $
                                      {available
                                        ? searchResults?.[0]?.registerPrice
                                        : nextAvailable?.registerPrice}
                                    </h4>
                                  </div>
                                  <p style={{ margin: 0, fontSize: '.8rem' }} className='thin gray'>
                                    Renewal: $
                                    {available
                                      ? searchResults?.[0]?.renewPrice
                                      : nextAvailable?.renewPrice}
                                  </p>
                                </div>
                              </div>
                              <div className='col-lg-2 col-sm-12'>
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (!mainCarted) {
                                      userAddToCart(currentUser.uid, {
                                        price: parseFloat(
                                          available
                                            ? searchResults?.[0]?.registerPrice
                                            : nextAvailable?.registerPrice
                                        ),
                                        type: 'Domain',
                                        name: available
                                          ? searchResults?.[0]?.domain
                                          : nextAvailable?.domain,
                                        period: 'yearly',
                                      });
                                      setMainCarted(true);
                                    }
                                  }}
                                  className='addcart-button'
                                >
                                  {console.log(currentUser)}
                                  {!mainCarted ? (
                                    <>{t('add-to-cart')}</>
                                  ) : (
                                    <>
                                      {/* Added to cart */}
                                      <div
                                        style={{
                                          transform: `${
                                            i18n.language === 'ar' ? 'scaleX(-1)' : ''
                                          }`,
                                        }}
                                      >
                                        {/* <i class='bi bi-check-lg'></i> */}
                                        <i class='bi bi-cart-check'></i>
                                      </div>
                                    </>
                                  )}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {searchResults?.length > 1 &&
                          searchResults?.filter((res) => res.available === 'true').length > 2 &&
                          searchResults.slice(1).map((res) => {
                            if (res?.available === 'true') {
                              return (
                                <div className='domain-display'>
                                  <p className='blue bold-ser no-raid'>{res?.domain}</p>
                                  <p className='bold-ser orange'>
                                    <span className='bold-ser gray'>
                                      $
                                      {(parseFloat(res?.registerPrice) * discoutPercent).toFixed(2)}
                                    </span>
                                    ${res?.registerPrice}
                                  </p>
                                  <p className='bold-ser gray'>Renewal ${res?.renewPrice}</p>
                                  <p className='no-raid'>
                                    {!domInCart(cart, res?.domain) ? (
                                      <a
                                        onClick={(e) => {
                                          e.preventDefault();
                                          console.log(res);
                                          userAddToCart(currentUser.uid, {
                                            price: parseFloat(res?.registerPrice),
                                            type: 'Domain',
                                            name: res?.domain,
                                            period: 'yearly',
                                          });
                                        }}
                                        className='bold orange Link '
                                        href='/profile'
                                      >
                                        <>{t('add-to-cart')}</>
                                      </a>
                                    ) : (
                                      <>
                                        <a className='bold orange Link ' href='#1'>
                                          Added to cart
                                          <div
                                            style={{
                                              transform: `${
                                                i18n.language === 'ar' ? 'scaleX(-1)' : ''
                                              }`,
                                            }}
                                          >
                                            <i class='bi bi-check-lg'></i>
                                          </div>
                                        </a>
                                      </>
                                    )}
                                  </p>
                                </div>
                              );
                            }
                            return '';
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            {searchResults?.length > 0 && (
              <div className='container'>
                <div className='row'>
                  <div style={{ textAlign: 'left' }} className='col-lg-6 col-sm-12'>
                    <h4 className='bolder black'>
                      {searchResults?.[0]?.domain} {t('domain.not-available')}
                    </h4>
                  </div>
                  <div style={{ textAlign: 'right' }} className='col-lg-4 col-sm-12'>
                    <div className='col-lg-2 col-sm-12'>
                      <button className='addcart-button'>{t('domain.find-another')}</button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        <div className='space-sm'></div>
        <h1 className='bold-ser black center'>{t('domain.premuim')}</h1>
        <div className='blue-bg'>
          <div className='container blue-bg'>
            <div
              style={{
                direction: `${i18n.language === 'ar' ? 'rtl' : ''}`,
                textAlign: `${i18n.language === 'ar' ? 'right' : 'left'}`,
              }}
              className='domain-display-container'
            >
              <div className='domain-text d-none d-sm-none d-md-flex'>
                <p className='bold-ser black'>{t('domain')}</p>
                <p className='bold-ser black'>{t('price')}</p>
                <p className='bold-ser black'>{t('renew')}</p>
              </div>
              <div className='row'>
                {premiumDomains?.map((domain) => (
                  <div className='col-12'>
                    <div className='domain-display d-none d-sm-none d-md-flex'>
                      <p className='blue bold-ser'>
                        {domain.domain}
                        <br></br>
                        {!domInCart(cart, domain.domain) ? (
                          <>
                            <a
                              style={{ display: 'inline-flex' }}
                              onClick={(e) => {
                                e.preventDefault();
                                userAddToCart(currentUser.uid, {
                                  price: parseFloat(domain.price),
                                  type: 'Domain',
                                  name: domain.domain,
                                  period: 'yearly',
                                });
                              }}
                              className='bold orange Link'
                              href='/'
                            >
                              {' '}
                              {t('get-started')}
                              <div
                                style={{
                                  transform: `${i18n.language === 'ar' ? 'scaleX(-1)' : ''}`,
                                }}
                              >
                                <i className='bi bi-arrow-right'></i>
                              </div>
                            </a>
                          </>
                        ) : (
                          <>
                            <a className='bold orange Link' href='#1'>
                              Added to cart
                              <div
                                style={{
                                  transform: `${i18n.language === 'ar' ? 'scaleX(-1)' : ''}`,
                                }}
                              >
                                <i class='bi bi-check-lg'></i>
                              </div>
                            </a>
                          </>
                        )}
                      </p>
                      <p className='bold-ser orange'>
                        <p
                          style={{
                            backgroundColor: 'none',
                            border: 'none',
                            marginTop: '.5rem',
                            marginBottom: 'auto',
                          }}
                        >
                          <span className='bold-ser gray'>${Math.floor(domain.price * 1.8)} </span>$
                          {domain.price}
                        </p>
                      </p>
                      <p className='bold-ser gray'>
                        <p
                          style={{
                            backgroundColor: 'none',
                            border: 'none',
                            marginTop: '.5rem',
                            marginBottom: 'auto',
                          }}
                        >
                          ${domain.renewPrice}
                        </p>
                      </p>
                    </div>
                    <div className='domain-display-mobile d-block d-sm-block d-md-none'>
                      <p className='blue bold-ser'>{domain.domain}</p>
                    </div>

                    <div
                      style={{
                        direction: `${i18n.language === 'ar' ? 'ltr' : ''}`,
                      }}
                      className='domain-display d-flex d-sm-flex d-md-none '
                    >
                      <p className='bold-ser orange'>
                        {t('price')}:{' '}
                        <span className='bold-ser gray'>${Math.floor(domain.price * 1.8)} </span>$
                        {domain.price}
                      </p>
                      <p className='bold-ser gray'>
                        {t('renew')}: ${domain.renewPrice}
                      </p>
                    </div>
                    <div className='domain-display d-block d-sm-block d-md-none'>
                      <p className='blue bold-ser'>
                        <a
                          style={{ display: 'inline-flex' }}
                          className='bold orange Link'
                          href='/profile'
                        >
                          {t('get-started')}{' '}
                          <div
                            style={{
                              transform: `${i18n.language === 'ar' ? 'scaleX(-1)' : ''}`,
                            }}
                          >
                            <i className='bi bi-arrow-right'></i>
                          </div>
                        </a>
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className='space'></div>
          <h1 className='bold-ser black center'>{t('domain.1.title')}</h1>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-4 col-md-6 col-sm-12'>
                <Service
                  img={img1}
                  Title={t('marketing')}
                  desc={t('marketing.desc')}
                  link='/marketing'
                />
              </div>
              <div className='col-lg-4 col-md-6 col-sm-12'>
                <Service
                  img={img2}
                  Title={t('graphicdesign')}
                  desc={t('graphicdesign.desc')}
                  link='/graphicdesign'
                />
              </div>
              <div className='col-lg-4 col-md-6 col-sm-12'>
                <Service
                  img={img3}
                  Title={t('motiongraphic')}
                  desc={t('motiongraphic.desc')}
                  link='/motiongraphic'
                />
              </div>
            </div>
          </div>
        </div>
        <div className='space'></div>
        <div
          style={{
            direction: `${i18n.language === 'ar' ? 'rtl' : ''}`,
            textAlign: `${i18n.language === 'ar' ? 'right' : 'left'}`,
          }}
          className='container'
        ></div>
        <div className='space'></div>
      </section>
    </>
  );
}
