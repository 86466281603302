import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Editor } from 'react-bootstrap-editor';

import { useSiteData } from '../../../contexts/SiteDataContext';

export default function PostBlog() {
	const { blogAdd } = useSiteData();
	const navigate = useNavigate();

	const titleRef = useRef();
	const contentRef = useRef();
	const ARtitleRef = useRef();
	const ARcontentRef = useRef();
	const TRtitleRef = useRef();
	const TRcontentRef = useRef();

	const [selectedImage, setSelectedImage] = useState(null);
	const [content, setContent] = useState('');
	const [ARcontent, setARContent] = useState('');
	const [TRcontent, setTRContent] = useState('');

	function handleUpload(e) {
		setSelectedImage(e.target.files[0]);
	}

	async function handleSubmit(e) {
		e.preventDefault();
		await blogAdd(
			{
				title: titleRef.current.value || '',
				content: content || '',
				ARtitle: ARtitleRef.current.value || '',
				ARcontent: ARcontent || '',
				TRtitle: TRtitleRef.current.value || '',
				TRcontent: TRcontent || '',
				imageName: selectedImage.name || '',
			},
			selectedImage
		);
		navigate('/cpanel/listblogs');
	}

	return (
		<>
			<section className='blue-bg'>
				<Link to='/cpanel' className='signup-button Link'>
					Return to cPanel
				</Link>
				<div className='space'></div>
				<div className='container'>
					<h1 className='center bolder blue'>Post Blog</h1>
					<Form onSubmit={handleSubmit}>
						<div className='row'>
							<div className='col-lg-6 col-sm-12 center'>
								<h2 className='Bold black'>Title English</h2>
								<div className='space-sm'></div>
								<input ref={titleRef} type='text' className='form-control' />
								<div className='space'></div>
							</div>
							<div className='col-lg-6 col-sm-12 center'>
								<h2 className='Bold black'>Title Arabic</h2>
								<div className='space-sm'></div>
								<input ref={ARtitleRef} type='text' className='form-control' />
								<div className='space'></div>
							</div>
							<div className='col-lg-6 col-sm-12 center'>
								<h2 className='Bold black'>Title Turkish</h2>
								<div className='space-sm'></div>
								<input ref={TRtitleRef} type='text' className='form-control' />
								<div className='space'></div>
							</div>
							<div className='col-lg-6 col-sm-12 center'>
								<h2 className='Bold black'>Content</h2>
								<div className='space-sm'></div>
								{/* <textarea ref={contentRef} className='form-control' rows='10' /> */}
								<Editor
									ref={contentRef}
									onChange={text => {
										setContent(text);
									}}
								/>
								<div className='space'></div>
							</div>
							<div className='col-lg-6 col-sm-12 center'>
								<h2 className='Bold black'>Content Arabic</h2>
								<div className='space-sm'></div>
								{/* <textarea ref={contentRef} className='form-control' rows='10' /> */}
								<Editor
									ref={ARcontentRef}
									onChange={text => {
										setARContent(text);
									}}
								/>
								<div className='space'></div>
							</div>
							<div className='col-lg-6 col-sm-12 center'>
								<h2 className='Bold black'>Content Turkish</h2>
								<div className='space-sm'></div>
								{/* <textarea ref={contentRef} className='form-control' rows='10' /> */}
								<Editor
									ref={TRcontentRef}
									onChange={text => {
										setTRContent(text);
									}}
								/>
								<div className='space'></div>
							</div>
							<div className='col-lg-6 col-sm-12 center'>
								<h2 className='Bold black'>Image</h2>
								<div className='space-sm'></div>
								{selectedImage && (
									<>
										<img src={URL.createObjectURL(selectedImage)} style={{ height: '50%', width: '30%' }} alt='' />
										<button onClick={() => setSelectedImage(null)}>Remove</button>
									</>
								)}
								{!selectedImage && <input onChange={handleUpload} type='file' id='img' name='img' accept='image/*' />}

								<div className='space'></div>
							</div>
							<button style={{ width: '5rem', height: '3rem' }} type='submit' className='btn btn-primary center'>
								Post
							</button>
						</div>
					</Form>
				</div>
			</section>
		</>
	);
}
