import serverApi from '../lib/Server';
import { firestore } from '../lib/Firebase';

import genEmail from '../templates/EmailTemp';

async function sendEmail(recID, subject, message) {
	const recRef = firestore.collection('users').doc(recID + '/');
	const rec = await recRef.get();
	const recInfo = rec.data();
	await serverApi('post', '/sendmail', {
		to: recInfo.email,
		subject,
		html: genEmail(message.title, message.text, {
			text: message.buttonText,
			link: `http://localhost:3000${message.buttonLink}`,
		}),
	});
}

export default sendEmail;
