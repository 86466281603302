import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useSiteData } from '../../../contexts/SiteDataContext';

export default function PostPlan() {
	const mpriceRef = useRef(null);
	const mpriceOldRef = useRef(null);
	const ypriceRef = useRef(null);
	const ypriceOldRef = useRef(null);
	const mpriceCpuRef1 = useRef(null);
	const mpriceCpuRef2 = useRef(null);
	const mpriceCpuRef3 = useRef(null);
	const ypriceCpuRef1 = useRef(null);
	const ypriceCpuRef2 = useRef(null);
	const ypriceCpuRef3 = useRef(null);
	const mpriceRamRef1 = useRef(null);
	const mpriceRamRef2 = useRef(null);
	const mpriceRamRef3 = useRef(null);
	const ypriceRamRef1 = useRef(null);
	const ypriceRamRef2 = useRef(null);
	const ypriceRamRef3 = useRef(null);

	const typeCpuRef1 = useRef(null);
	const typeCpuRef2 = useRef(null);
	const typeCpuRef3 = useRef(null);
	const typeRamRef1 = useRef(null);
	const typeRamRef2 = useRef(null);
	const typeRamRef3 = useRef(null);

	const titleRef = useRef(null);
	const cpuSpecRef = useRef(null);
	const ramSpecRef = useRef(null);
	const bwSpecRef = useRef(null);
	const ddSpecRef = useRef(null);
	const cpuRef = useRef(null);
	const ramRef = useRef(null);
	const bwRef = useRef(null);
	const ddRef = useRef(null);
	const perksRef = useRef(null);

	const ARtitleRef = useRef(null);
	const ARcpuSpecRef = useRef(null);
	const ARramSpecRef = useRef(null);
	const ARbwSpecRef = useRef(null);
	const ARddSpecRef = useRef(null);
	const ARcpuRef = useRef(null);
	const ARramRef = useRef(null);
	const ARbwRef = useRef(null);
	const ARddRef = useRef(null);
	const ARperksRef = useRef(null);

	const TRtitleRef = useRef(null);
	const TRcpuSpecRef = useRef(null);
	const TRramSpecRef = useRef(null);
	const TRbwSpecRef = useRef(null);
	const TRddSpecRef = useRef(null);
	const TRcpuRef = useRef(null);
	const TRramRef = useRef(null);
	const TRbwRef = useRef(null);
	const TRddRef = useRef(null);
	const TRperksRef = useRef(null);

	const navigate = useNavigate();
	const { type, index } = useParams();

	const emptySpec = {
		name: '',
		ARname: '',
		TRname: '',
		monthlyPrice: '',
		yearlyPrice: '',
	};
	const emptyFeature = {
		name: '',
		ARname: '',
		TRname: '',
		specs: [emptySpec],
	};

	const [features, setFeatures] = useState([]);

	function handleFeautureAdd(e) {
		e.preventDefault();
		setFeatures([...features, emptyFeature]);
	}

	const { hostingPlanGet, hostingPlanUpdate } = useSiteData();
	const [plan, setPlan] = useState({});

	const planType = type
		.split('-')
		.map(word => word[0].charAt(0).toUpperCase() + word.slice(1))
		.join(' ');

	useEffect(() => {
		hostingPlanGet(planType).then(data => {
			setPlan(data[index - 1]);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setFeatures(plan.valueFeatures);
		mpriceRef.current.value = plan?.monthlyPrice || '';
		mpriceOldRef.current.value = plan?.monthlyPriceOld || '';
		ypriceRef.current.value = plan?.yearlyPrice || '';
		ypriceOldRef.current.value = plan?.yearlyPriceOld || '';
		titleRef.current.value = plan?.planName || '';
		perksRef.current.value = plan?.features?.toString() || '';
		cpuRef.current.value = plan?.specs?.CPU || '';
		ramRef.current.value = plan?.specs?.RAM || '';
		bwRef.current.value = plan?.specs?.Bandwidth || '';
		ddRef.current.value = plan?.specs?.['Disk Drive'] || '';
		if (plan?.specs) {
			for (const [key, value] of Object.entries(plan?.specs)) {
				if (!cpuRef.current.value) {
					cpuSpecRef.current.value = key;
					cpuRef.current.value = value;
				}
				if (!ramRef.current.value) {
					ramSpecRef.current.value = key;
					ramRef.current.value = value;
				}
				if (!bwRef.current.value) {
					bwSpecRef.current.value = key;
					bwRef.current.value = value;
				}
				if (!ddRef.current.value) {
					ddSpecRef.current.value = key;
					ddRef.current.value = value;
				}
			}
		}
		ARtitleRef.current.value = plan?.ARplanName || '';
		ARperksRef.current.value = plan?.ARfeatures?.toString() || '';
		ARcpuRef.current.value = plan?.ARspecs?.CPU || '';
		ARramRef.current.value = plan?.ARspecs?.RAM || '';
		ARbwRef.current.value = plan?.ARspecs?.Bandwidth || '';
		ARddRef.current.value = plan?.ARspecs?.['Disk Drive'] || '';
		if (plan?.ARspecs) {
			for (const [key, value] of Object.entries(plan?.ARspecs)) {
				if (!ARcpuRef.current.value) {
					ARcpuSpecRef.current.value = key;
					ARcpuRef.current.value = value;
				}
				if (!ARramRef.current.value) {
					ARramSpecRef.current.value = key;
					ARramRef.current.value = value;
				}
				if (!ARbwRef.current.value) {
					ARbwSpecRef.current.value = key;
					ARbwRef.current.value = value;
				}
				if (!ARddRef.current.value) {
					ARddSpecRef.current.value = key;
					ARddRef.current.value = value;
				}
			}
		}
		TRtitleRef.current.value = plan?.TRplanName || '';
		TRperksRef.current.value = plan?.TRfeatures?.toString() || '';
		TRcpuRef.current.value = plan?.TRspecs?.CPU || '';
		TRramRef.current.value = plan?.TRspecs?.RAM || '';
		TRbwRef.current.value = plan?.TRspecs?.Bandwidth || '';
		TRddRef.current.value = plan?.TRspecs?.['Disk Drive'] || '';
		if (plan?.TRspecs) {
			for (const [key, value] of Object.entries(plan?.TRspecs)) {
				if (!cpuRef.current.value) {
					cpuSpecRef.current.value = key;
					cpuRef.current.value = value;
				}
				if (!ramRef.current.value) {
					ramSpecRef.current.value = key;
					ramRef.current.value = value;
				}
				if (!bwRef.current.value) {
					bwSpecRef.current.value = key;
					bwRef.current.value = value;
				}
				if (!ddRef.current.value) {
					ddSpecRef.current.value = key;
					ddRef.current.value = value;
				}
			}
		}
	}, [plan]);

	async function handleSubmit(e) {
		e.preventDefault();
		await hostingPlanUpdate(planType, index, {
			monthlyPrice: mpriceRef.current.value,
			monthlyPriceOld: mpriceOldRef.current.value,
			yearlyPrice: ypriceRef.current.value,
			yearlyPriceOld: ypriceOldRef.current.value,
			valueFeatures: features,
			planName: titleRef.current.value,
			features: perksRef.current.value.split(','),
			specs: {
				[cpuSpecRef.current.value]: cpuRef.current.value,
				[ramSpecRef.current.value]: ramRef.current.value,
				[bwSpecRef.current.value]: bwRef.current.value,
				[ddSpecRef.current.value]: ddRef.current.value,
			},
			ARplanName: ARtitleRef.current.value,
			ARfeatures: ARperksRef.current.value.split(','),
			ARspecs: {
				[ARcpuSpecRef.current.value]: ARcpuRef.current.value,
				[ARramSpecRef.current.value]: ARramRef.current.value,
				[ARbwSpecRef.current.value]: ARbwRef.current.value,
				[ARddSpecRef.current.value]: ARddRef.current.value,
			},
			TRplanName: TRtitleRef.current.value,
			TRfeatures: TRperksRef.current.value.split(','),
			TRspecs: {
				[TRcpuSpecRef.current.value]: TRcpuRef.current.value,
				[TRramSpecRef.current.value]: TRramRef.current.value,
				[TRbwSpecRef.current.value]: TRbwRef.current.value,
				[TRddSpecRef.current.value]: TRddRef.current.value,
			},
		});
		navigate('/cpanel/listplans/' + planType);
	}

	return (
		<>
			<section className='blue-bg'>
				<Link to='/cpanel' className='signup-button Link'>
					Return to cPanel
				</Link>
				<div className='space'></div>
				<div className='container'>
					<h1 className='center bolder blue'>Edit {planType} plan</h1>
					<Form onSubmit={handleSubmit}>
						<div className='row'>
							<div className='col-lg-6 col-sm-12 center'>
								<h2 className='Bold black'>Title</h2>
								<div className='space-sm'></div>
								<input ref={titleRef} type='text' className='form-control' />
								<div className='space'></div>
							</div>
							<div className='col-lg-6 col-sm-12 center'>
								<h2 className='Bold black'>Title Arabic</h2>
								<div className='space-sm'></div>
								<input ref={ARtitleRef} type='text' className='form-control' />
								<div className='space'></div>
							</div>
							<div className='col-lg-6 col-sm-12 center'>
								<h2 className='Bold black'>Title Turkish</h2>
								<div className='space-sm'></div>
								<input ref={TRtitleRef} type='text' className='form-control' />
								<div className='space'></div>
							</div>
							<div className='col-lg-6 col-sm-12'>
								<h2 className='Bold black'>Price</h2>
								<div className='space-sm'></div>
								<p>monthly</p>
								<input ref={mpriceRef} type='text' className='form-control' />
								<div className='space-sm'></div>
								<p>annual</p>
								<input ref={ypriceRef} type='text' className='form-control' />
								<div className='space-sm'></div>

								<h2 className='Bold black'>Old price</h2>
								<p>monthly</p>
								<input ref={mpriceOldRef} type='text' className='form-control' />
								<div className='space-sm'></div>
								<p>annual</p>
								<input ref={ypriceOldRef} type='text' className='form-control' />
								<div className='space-sm'></div>
							</div>
							<h2 className='Bold black'>Features</h2>
							{features.map((feature, i) => (
								<>
									<h3>name</h3>
									<input
										defaultValue={features[i].name}
										onChange={e => {
											features[i].name = e.target.value;
										}}
										type='text'
										className='form-control'
									/>
									<p>Arabic name</p>
									<input
										defaultValue={features[i].ARname}
										onChange={e => {
											features[i].ARname = e.target.value;
										}}
										type='text'
										className='form-control'
									/>
									<p>Turkish name</p>
									<input
										defaultValue={features[i].TRname}
										onChange={e => {
											features[i].TRname = e.target.value;
										}}
										type='text'
										className='form-control'
									/>
									<div className='space-sm'></div>
									<h3>Specs</h3>
									{features[i].specs.map((spec, j) => (
										<>
											<p>spec name</p>
											<input
												defaultValue={spec.name}
												onChange={e => {
													spec.name = e.target.value;
												}}
												type='text'
												className='form-control'
											/>
											<div className='space-sm'></div>
											<p>spec arabic name</p>
											<input
												defaultValue={spec.ARname}
												onChange={e => {
													spec.ARname = e.target.value;
												}}
												type='text'
												className='form-control'
											/>
											<div className='space-sm'></div>
											<p>spec turkish name</p>
											<input
												defaultValue={spec.TRname}
												onChange={e => {
													spec.TRname = e.target.value;
												}}
												type='text'
												className='form-control'
											/>
											<div className='space-sm'></div>
											<p>monthly price</p>
											<input
												defaultValue={spec.monthlyPrice}
												onChange={e => {
													spec.monthlyPrice = e.target.value;
												}}
												type='text'
												className='form-control'
											/>
											<div className='space-sm'></div>
											<p>annual price</p>
											<input
												defaultValue={spec.yearlyPrice}
												onChange={e => {
													spec.yearlyPrice = e.target.value;
												}}
												type='text'
												className='form-control'
											/>
											<div className='space-sm'></div>
											<button
												onClick={e => {
													e.preventDefault();
													const nfeatures = [...features];
													nfeatures[i].specs.splice(j, 1);
													setFeatures(nfeatures);
												}}
												type='button'
												className='btn btn-primary'>
												Remove feature spec
											</button>
											<hr />
										</>
									))}
									<button
										onClick={e => {
											e.preventDefault();
											const nfeatures = [...features];
											nfeatures[i].specs.push(emptySpec);
											setFeatures(nfeatures);
										}}
										type='button'
										className='btn btn-primary'>
										Add feature spec
									</button>
								</>
							))}
							<button onClick={handleFeautureAdd} type='button' className='btn btn-primary'>
								Add feature
							</button>
							<div className='col-lg-6 col-sm-12'>
								<h2 className='Bold black'>Specifications</h2>
								<div className='space-sm'></div>
								<hr />
								<input ref={cpuSpecRef} type='text' className='form-control' />
								<input ref={cpuRef} type='text' className='form-control' />
								<div className='space-sm'></div>
								<hr />
								<input ref={ramSpecRef} type='text' className='form-control' />
								<input ref={ramRef} type='text' className='form-control' />
								<div className='space-sm'></div>
								<hr />
								<input ref={bwSpecRef} type='text' className='form-control' />
								<input ref={bwRef} type='text' className='form-control' />
								<div className='space-sm'></div>
								<hr />
								<input ref={ddSpecRef} type='text' className='form-control' />
								<input ref={ddRef} type='text' className='form-control' />
								<div className='space-sm'></div>
								<div className='space-sm'></div>
								<div className='space'></div>
							</div>
							<div className='col-lg-6 col-sm-12'>
								<h2 className='Bold black'>Specifications Arabic</h2>
								<div className='space-sm'></div>
								<hr />
								<input ref={ARcpuSpecRef} type='text' className='form-control' />
								<input ref={ARcpuRef} type='text' className='form-control' />
								<div className='space-sm'></div>
								<hr />
								<input ref={ARramSpecRef} type='text' className='form-control' />
								<input ref={ARramRef} type='text' className='form-control' />
								<div className='space-sm'></div>
								<hr />
								<input ref={ARbwSpecRef} type='text' className='form-control' />
								<input ref={ARbwRef} type='text' className='form-control' />
								<div className='space-sm'></div>
								<hr />
								<input ref={ARddSpecRef} type='text' className='form-control' />
								<input ref={ARddRef} type='text' className='form-control' />
								<div className='space-sm'></div>
								<div className='space-sm'></div>
								<div className='space'></div>
							</div>
							<div className='col-lg-6 col-sm-12'>
								<h2 className='Bold black'>Specifications Turkish</h2>
								<div className='space-sm'></div>
								<hr />
								<input ref={TRcpuSpecRef} type='text' className='form-control' />
								<input ref={TRcpuRef} type='text' className='form-control' />
								<div className='space-sm'></div>
								<hr />
								<input ref={TRramSpecRef} type='text' className='form-control' />
								<input ref={TRramRef} type='text' className='form-control' />
								<div className='space-sm'></div>
								<hr />
								<input ref={TRbwSpecRef} type='text' className='form-control' />
								<input ref={TRbwRef} type='text' className='form-control' />
								<div className='space-sm'></div>
								<hr />
								<input ref={TRddSpecRef} type='text' className='form-control' />
								<input ref={TRddRef} type='text' className='form-control' />
								<div className='space-sm'></div>
								<div className='space-sm'></div>
								<div className='space'></div>
							</div>
							<div className='col-md-6 center'>
								<h2 className='Bold black'>Perks</h2>
								<div className='space-sm'></div>
								<textarea ref={perksRef} className='form-control' rows='10' />
								<div className='space'></div>
							</div>
							<div className='col-md-6 center'>
								<h2 className='Bold black'>Perks Arabic</h2>
								<div className='space-sm'></div>
								<textarea ref={ARperksRef} className='form-control' rows='10' />
								<div className='space'></div>
							</div>
							<div className='col-md-6 center'>
								<h2 className='Bold black'>Perks Turkish</h2>
								<div className='space-sm'></div>
								<textarea ref={TRperksRef} className='form-control' rows='10' />
								<div className='space'></div>
							</div>
							<button type='submit' className='btn btn-primary'>
								Update
							</button>
						</div>
					</Form>
				</div>
			</section>
		</>
	);
}
