import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Header from '../../components/ui/Header';
import Blog from '../../components/cards/Blog';

import { useSiteData } from '../../contexts/SiteDataContext';
import { useTranslation } from 'react-i18next';

export default function BlogPage() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { blogsGet, blogGet } = useSiteData();
  const { _id } = useParams();

  const [blog, setBlog] = useState({});
  const [blogs, setBlogs] = useState([]);
  const [state, setState] = useState(0);

  function forceRerender(e) {
    setState(state + 1);
  }

  useEffect(() => {
    blogGet(_id).then((res) => setBlog(res));
    blogsGet().then((res) => setBlogs(res));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  // <div className='col-lg-4 col-md-6 col-sm-12 pad-one'>
  return (
    <>
      <Header
        title={
          i18n.language === 'ar'
            ? blog?.ARtitle
            : i18n.language === 'tr'
            ? blog?.TRtitle
            : blog?.title
        }
        desc=''
        arrow={true}
      />
      <section
        style={{
          direction: `${i18n.language === 'ar' ? 'rtl' : ''}`,
          textAlign: `${i18n.language === 'ar' ? 'right' : 'left'}`,
        }}
        className='post'
      >
        <div className='container'>
          <div className='row'>
            <div className='col-lg-1' />
            <div className='col-lg-4 col-md-6 col-sm-12 pad-one'>
              <img alt='' className='post-img' src={blog?.imageUrl} />
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12'>
              <h3 className='bold-ser black mt-3'>
                {i18n.language === 'ar'
                  ? blog?.ARtitle
                  : i18n.language === 'tr'
                  ? blog?.TRtitle
                  : blog?.title}
              </h3>
              <p
                style={{
                  padding: `${i18n.language === 'ar' ? '0 1rem' : ''}`,
                }}
                className='gray'
                dangerouslySetInnerHTML={{
                  __html:
                    i18n.language === 'ar'
                      ? blog?.ARcontent
                      : i18n.language === 'tr'
                      ? blog?.TRcontent
                      : blog?.content,
                }}
              />
              <div className='share-contain'>
                <div className='share'>
                  <h5 className='bold black'>{t('share')}: </h5>
                  <a
                    className='black'
                    href={
                      'https://www.facebook.com/sharer/sharer.php?u=whynot-tech.com/blogs/page/' +
                      _id
                    }
                  >
                    <i className='bi bi-facebook'></i>
                  </a>
                  <a
                    className='black'
                    href={
                      'https://twitter.com/intent/tweet?text=https://test.whynot-tech.com/blogs/page/' +
                      _id
                    }
                  >
                    <i className='bi bi-twitter'></i>
                  </a>
                  <a
                    className='black'
                    href={'whatsapp://send?text=whynot-tech.com/blogs/page/' + _id + "')"}
                  >
                    <i className='bi bi-whatsapp'></i>
                  </a>
                  <a
                    className='black'
                    href={
                      'https://www.linkedin.com/shareArticle?mini=true&url=https://whynot-tech.com/blogs/page/' +
                      _id
                    }
                  >
                    <i className='bi bi-linkedin'></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div style={{ paddingBottom: '4rem' }} className='space'></div>
      <section className='service-container'>
        <h1 style={{ paddingBottom: '1rem' }} className='bold-ser blue'>
          {t('similar-articles')}
        </h1>
        <div className='container'>
          <div className='row'>
            {blogs &&
              blogs
                .filter((blg) => blg._id !== blog._id)
                .slice(0, 4)
                .map((blg, i) => (
                  <div key={i} onClick={forceRerender} className='col-lg-3 col-md-4 col-s-12'>
                    <Blog
                      title={
                        i18n.language === 'ar'
                          ? blg?.ARtitle
                          : i18n.language === 'tr'
                          ? blg?.TRtitle
                          : blg?.title
                      }
                      link={`/blogs/page/${blg._id}`}
                      img={blg.imageUrl}
                    />
                  </div>
                ))}
          </div>
        </div>
      </section>
    </>
  );
}
