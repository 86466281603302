import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { motion } from 'framer-motion';

export default function Project(props) {
  return (
    <>
      <motion.div
        initial={{ y: -30, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        className='card-container'
      >
        <div className='project-card'>
          <Link to={props.link}>
            <img alt='img' src={props.img} />
          </Link>
        </div>
      </motion.div>
    </>
  );
}
