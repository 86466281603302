import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import PricingCard from '../../../components/cards/PricingCard';
import background from '../../../assets/sinebg.svg';

import { useSiteData } from '../../../contexts/SiteDataContext';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function Pricing() {
  const { t } = useTranslation();
  const { hostingPlanGet } = useSiteData();
  const [prices, setPrices] = useState([]);
  const [period, setPeriod] = useState('monthly');

  useEffect(() => {
    hostingPlanGet('Home Page').then((data) => {
      setPrices(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log(prices.map(i => i.monthlyPriceOld i.monthlyPrice));
  return (
    <>
      <section className='pricing'>
        <h1 className='bold-ser black'>{t('pricing.title')}</h1>
        <div className='plan-selection-container'>
          <div className='plan-selection'>
            <div className='left-b'>
              <button
                onClick={() => setPeriod('monthly')}
                className={`bold ${period === 'monthly' && 'active-b'}`}
              >
                {t('montly')}
              </button>
            </div>
            <div className='right-b'>
              <button
                onClick={() => setPeriod('yearly')}
                id='year'
                className={`bold ${period === 'yearly' && 'active-b'}`}
              >
                {t('yearly')}
              </button>
            </div>
          </div>
        </div>

        <div className='container '>
          <div className='row'>
            {prices &&
              prices.map((info, i) => {
                const discount =
                  period === 'monthly'
                    ? calculatePercentage(info.monthlyPriceOld, info.monthlyPrice)
                    : calculatePercentage(info.yearlyPriceOld, info.yearlyPrice);
                return (
                  <div className='col-md-6 col-lg-3 col-s-12 cards-nth'>
                    <PricingCard
                      title={info.planName}
                      price={period === 'monthly' ? info.monthlyPrice : info.yearlyPrice}
                      per={period === 'monthly' ? '/mo' : '/yr'}
                      oldPrice={period === 'monthly' ? info.monthlyPriceOld : info.yearlyPriceOld}
                      discount={discount}
                      type={'Home Page'}
                      link={`/hosting/home-page/plan/${i}`}
                      perk={info.features}
                    />
                  </div>
                );
              })}
          </div>
        </div>
        <Link to='/hosting'>
          <button className='more-button'>{t('more')}</button>
        </Link>
      </section>
      <img alt='' src={background} className='section-foot' />
    </>
  );
}

function calculatePercentage(oldPriceStr, newPriceStr) {
  const oldPrice = parseInt(oldPriceStr);
  const newPrice = parseInt(newPriceStr);
  return Math.round(100 * ((oldPrice - newPrice) / ((oldPrice + newPrice) / 2)));
}
