import React, { useContext, useState, useEffect } from 'react';
import { auth, credential, firestore } from '../lib/Firebase';
import { useDocumentData } from 'react-firebase-hooks/firestore';

const AuthContext = React.createContext();

export function useAuth() {
	return useContext(AuthContext);
}

export function AuthProvider({ children }) {
	const [currentUser, setCurrentUser] = useState(null);
	const [loading, setLoading] = useState(true);

	const signup = (email, password) => auth.createUserWithEmailAndPassword(email, password);

	const login = (email, password) => auth.signInWithEmailAndPassword(email, password);

	const logout = () => auth.signOut();

	const resetPassword = email => auth.sendPasswordResetEmail(email);

	const updateEmail = email => currentUser.updateEmail(email);

	const updatePassword = password => currentUser.updatePassword(password);

	const reauthenticate = password => {
		currentUser.reauthenticateWithCredential(credential(currentUser.email, password));
	};

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(user => {
			setLoading(true);
			if (user) {
				firestore
					.collection('users')
					.doc(user.uid)
					.get()
					.then(userInfo => {
						user.data = userInfo.data();
						setCurrentUser(user, setLoading(false));
					});
			} else {
				setCurrentUser(null, setLoading(false));
			}
		});
		return unsubscribe;
	}, []);

	auth.onAuthStateChanged(user => {
		setCurrentUser(user);
	});

	// const [userData] = useDocumentData(firestore.collection('users').doc(currentUser?.uid));

	// useEffect(() => {
	// 	if (currentUser) {
	// 		currentUser.data = userData;
	// 		setCurrentUser(currentUser);
	// 	} // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	const value = {
		currentUser,
		signup,
		login,
		logout,
		resetPassword,
		updateEmail,
		updatePassword,
		reauthenticate,
	};
	return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
}
