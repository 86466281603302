import React, { createContext, useContext, useEffect, useState } from 'react';

import { firestore } from '../lib/Firebase';
import { useAuth } from './AuthContext';

const StatesContext = createContext();

export function useStates() {
	return useContext(StatesContext);
}

export function StatesProvider({ children }) {
	const { currentUser } = useAuth();

	const [chatDisabled, setChatDisabled] = useState(null);

	const [notifications, setNotifications] = useState([]);
	const [cart, setCart] = useState([]);
	const [tickets, setTickets] = useState([]);
	const [lc, setLc] = useState(currentUser?.data?.liveChat);
	const [popUp, setPopUp] = useState(null);

	function calcNotifications() {
		if (currentUser?.data?.role === 'client') {
			setNotifications(tickets);
		}
	}

	useEffect(() => {
		(async () => {
			const liveChat = firestore.collection('siteData').doc('LiveChat');
			if (chatDisabled !== null) await liveChat.update({ disabled: chatDisabled });
		})();
	}, [chatDisabled]);

	useEffect(() => {
		const userRef = firestore.collection('users').doc(currentUser?.uid);

		let cartUnsub = null;
		let ticketsUnsub = null;
		let lcUnsub = null;
		if (currentUser?.data?.role === 'client') {
			const cartRef = userRef.collection('cart');
			cartUnsub = cartRef.onSnapshot(snapshot => {
				if (snapshot.docs.length >= cart.length) {
					setCart(snapshot.docs);
				} else {
					setCart([]);
				}
			});
			lcUnsub = userRef.onSnapshot(snapshot => {
				setLc(snapshot.data().liveChat);
			});

			const ticketsRef = firestore.collection('tickets');
			ticketsUnsub = ticketsRef.onSnapshot(snapshot => {
				setTickets(
					snapshot.docs.filter(doc => {
						return (
							doc.data().clientID === currentUser?.uid &&
							doc.data().messages[doc.data().messages.length - 1].sender === 'support'
						);
					}),
					calcNotifications()
				);
			});
		}
		const liveChatRef = firestore.collection('siteData').doc('LiveChat');
		const liveChatUnsub = liveChatRef.onSnapshot(snapshot => {
			setChatDisabled(snapshot.data().disabled);
		});

		return () => {
			liveChatUnsub();
			if (currentUser?.data?.role === 'client') {
				cartUnsub();
				ticketsUnsub();
				lcUnsub();
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const value = { lc, notifications, cart, chatDisabled, setChatDisabled, setPopUp, popUp, tickets };
	return <StatesContext.Provider value={value}>{children}</StatesContext.Provider>;
}
