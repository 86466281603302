import React from 'react';
import cookie from 'json-cookie';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
export default function Cookie({ setShow }) {
	const { t } = useTranslation();
	const { i18n } = useTranslation();

	return (
		<div
			style={{
				direction: `${i18n.language === 'ar' ? 'rtl' : ''}`,
			}}
			className='cookie-box'>
			<p className='black left'>
				{t('cookie-message')}{' '}
				<Link to='/privacy-policy' className='orange Link bold'>
					{' '}
					&nbsp;
					{t('privacy')}
				</Link>
			</p>
			<button
				onClick={e => {
					e.preventDefault();
					setShow(false);
					cookie.set('cookie', 'true');
				}}
				style={{
					backgroundColor: '#E25E26',
					color: 'white',
				}}
				className='btn btn-sm mt-auto'>
				{t('cookie-accept')}
			</button>
		</div>
	);
}
