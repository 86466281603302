import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BRB from '../../../../assets/BRB.svg';

export default function Broken() {
  const { t } = useTranslation();
  return (
    <>
      <div className='chat-body'>
        <img className='brb' src={BRB} alt='BRB' />
        <div className='brb-buttons'>
          <Link to='/contact-us' className='brb-button'>
            {t('send-email')}
          </Link>
          <Link to='/submit-ticket' className='brb-button Link'>
            {t('submit-tickets')}
          </Link>
        </div>
      </div>
    </>
  );
}
