import React, { useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Editor } from 'react-bootstrap-editor';

import { useSiteData } from '../../../contexts/SiteDataContext';

export default function PostProject() {
	const { projectAdd } = useSiteData();
	const navigate = useNavigate();

	const titleRef = useRef();
	const contentRef = useRef();
	const ARtitleRef = useRef();
	const ARcontentRef = useRef();
	const TRtitleRef = useRef();
	const TRcontentRef = useRef();
	const linkRef = useRef();
	const typeRef = useRef();

	const [selectedImages, setSelectedImages] = useState(Array(5));
	const [content, setContent] = useState('');
	const [ARcontent, setARContent] = useState('');
	const [TRcontent, setTRContent] = useState('');

	function handleUpload(e, i) {
		const imgs = [...selectedImages];
		imgs[i] = e.target.files[0];
		setSelectedImages(imgs);
	}

	function handleRemoveImage(i) {
		const imgs = [...selectedImages];
		imgs[i] = null;
		setSelectedImages(imgs);
	}

	function handleSubmit(e) {
		e.preventDefault();

		projectAdd(
			{
				title: titleRef.current.value,
				content: content,
				ARtitle: ARtitleRef.current.value,
				ARcontent: ARcontent,
				TRtitle: TRtitleRef.current.value,
				TRcontent: TRcontent,
				type: typeRef.current.value,
				link: linkRef.current.value,
				images: selectedImages.map(image => image.name),
			},
			selectedImages
		).then(() => navigate('/cpanel/listprojects'));
	}

	return (
		<>
			<section className='blue-bg'>
				<div className='container'>
					<h1 className='center bolder blue'>Post Project</h1>
					<Form onSubmit={handleSubmit}>
						<div className='row'>
							<div className='col-lg-6 col-sm-12 center'>
								<h2 className='Bold black'>Title</h2>
								<div className='space-sm'></div>
								<input ref={titleRef} type='text' className='form-control' />
								<div className='space'></div>
							</div>
							<div className='col-lg-6 col-sm-12 center'>
								<h2 className='Bold black'>Title Arabic</h2>
								<div className='space-sm'></div>
								<input ref={ARtitleRef} type='text' className='form-control' />
								<div className='space'></div>
							</div>
							<div className='col-lg-6 col-sm-12 center'>
								<h2 className='Bold black'>Title Turkish</h2>
								<div className='space-sm'></div>
								<input ref={TRtitleRef} type='text' className='form-control' />
								<div className='space'></div>
							</div>
							<div className='col-lg-6 col-sm-12 center'>
								<h2 className='Bold black'>Content</h2>
								<div className='space-sm'></div>
								{/* <textarea ref={contentRef} className='form-control' rows='10' /> */}
								<Editor
									ref={contentRef}
									onChange={text => {
										setContent(text);
									}}
								/>
								<div className='space'></div>
							</div>
							<div className='col-lg-6 col-sm-12 center'>
								<h2 className='Bold black'>Content Arabic</h2>
								<div className='space-sm'></div>
								{/* <textarea ref={contentRef} className='form-control' rows='10' /> */}
								<Editor
									ref={ARcontentRef}
									onChange={text => {
										setARContent(text);
									}}
								/>
								<div className='space'></div>
							</div>
							<div className='col-lg-6 col-sm-12 center'>
								<h2 className='Bold black'>Content Turkish</h2>
								<div className='space-sm'></div>
								{/* <textarea ref={contentRef} className='form-control' rows='10' /> */}
								<Editor
									ref={TRcontentRef}
									onChange={text => {
										setTRContent(text);
									}}
								/>
								<div className='space'></div>
							</div>
							<div className='col-lg-6 col-sm-12 center'>
								<h2 className='Bold black'>Type</h2>
								<div className='space-sm'></div>
								<select ref={typeRef} className='form-field-select-three space-sm' id='TypeSelect' name='Type Select'>
									<option value='' disabled selected>
										Select your option
									</option>
									<option value='Mobile Applications'>Mobile Applications</option>
									<option value='Websites'>Websites</option>
									<option value='Motion Graphics'>Motion Graphics</option>
								</select>
							</div>
							<div className='col-lg-6 col-sm-12 center'>
								<h2 className='Bold black'>Link</h2>
								<div className='space-sm'></div>
								<input ref={linkRef} type='text' className='form-control' />
								<div className='space'></div>
							</div>
							<div className='col-lg-6 col-sm-12 '>
								<h2 className='Bold black'>Images</h2>
								<div className='space-sm'></div>
								{Array(5)
									.fill(0)
									.map((_, i) => (
										<div className='col-lg-3 col-sm-12'>
											<p>{i + 1}</p>
											{selectedImages[i] && (
												<>
													<img src={URL.createObjectURL(selectedImages[i])} style={{ width: '15%' }} alt='' />
													<button onClick={e => handleRemoveImage(i)}>Remove</button>
												</>
											)}
											{!selectedImages[i] && (
												<input
													onChange={e => {
														e.preventDefault();
														handleUpload(e, i);
													}}
													type='file'
													id='img'
													name='img'
													accept='image/*'
												/>
											)}
										</div>
									))}
							</div>
							<button type='submit' className='btn btn-primary'>
								Post
							</button>
						</div>
					</Form>
				</div>
			</section>
		</>
	);
}
