import React from 'react';
import { Link } from 'react-router-dom';

export default function ListPlanTypes() {
	return (
		<>
			<div className='space'></div>
			<h1 className='center bolder blue'>Plans</h1>
			<div className='container'>
				<div className='row'>
					<div className='col-md-6 center'>
						<h2 className='Bold black'>Home Page Plans</h2>
						<div className='space-sm'></div>
						<Link to='/cpanel/listplans/home-page' className='signup-button Link'>
							Enter
						</Link>
						<div className='space'></div>
					</div>
					<div className='col-md-6 center'>
						<h2 className='Bold black'>Shared Hosting</h2>
						<div className='space-sm'></div>
						<Link to='/cpanel/listplans/shared-hosting' className='signup-button Link'>
							Enter
						</Link>
						<div className='space'></div>
					</div>
					<div className='col-md-6 center'>
						<h2 className='Bold black'>Reseller Hosting</h2>
						<div className='space-sm'></div>
						<Link to='/cpanel/listplans/reseller-hosting' className='signup-button Link'>
							Enter
						</Link>
						<div className='space'></div>
					</div>
					<div className='col-md-6 center'>
						<h2 className='Bold black'>WordPress Hosting</h2>
						<div className='space-sm'></div>
						<Link to='/cpanel/listplans/wordpress-hosting' className='signup-button Link'>
							Enter
						</Link>
						<div className='space'></div>
					</div>
					<div className='col-md-6 center'>
						<h2 className='Bold black'>Email Hosting</h2>
						<div className='space-sm'></div>
						<Link to='/cpanel/listplans/email-hosting' className='signup-button Link'>
							Enter
						</Link>
						<div className='space'></div>
					</div>
					<div className='col-md-6 center'>
						<h2 className='Bold black'>VPS Hosting</h2>
						<div className='space-sm'></div>
						<Link to='/cpanel/listplans/vps-hosting' className='signup-button Link'>
							Enter
						</Link>
						<div className='space'></div>
					</div>
					<div className='col-md-6 center'>
						<h2 className='Bold black'>Dedicated Hosting</h2>
						<div className='space-sm'></div>
						<Link to='/cpanel/listplans/dedicated-hosting' className='signup-button Link'>
							Enter
						</Link>
						<div className='space'></div>
					</div>
				</div>
			</div>
		</>
	);
}
