import React, { useRef, useState, useEffect } from 'react';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { BellFill, Cart4 } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import cookie from 'json-cookie';

import SelectorLink from '../../../utils/SelectorLink';
import { useAuth } from '../../../contexts/AuthContext';
import { useStates } from '../../../contexts/StatesContext';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import Logo from '../../../assets/logo.svg';

import Signup from '../../popups/Signup';
import Login from '../../popups/Login';
import Cookie from '../../popups/Cookie';

const languages = {
	en: { nativeName: 'English' },
	ar: { nativeName: 'العربية' },
	tr: { nativeName: 'Türkçe' },
};

export default function BasicExample() {
	const location = useLocation();
	const { t } = useTranslation();
	const languageRef = useRef(null);
	const { currentUser } = useAuth();
	const { notifications, cart, popUp, setPopUp } = useStates();
	const [wait, setWait] = useState(true);
	const [expanded, setExpanded] = useState(false);
	const [show, setShow] = useState(true);
	const { i18n } = useTranslation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);

	useEffect(() => {
		cookie.get('cookie') ? setShow(false) : setShow(true);
		setTimeout(() => {
			setWait(false);
		}, 3000);
	}, []);

	function handleSignInClick(e) {
		e.preventDefault();
		setPopUp('login');
	}

	function handleSignUpClick(e) {
		e.preventDefault();
		setPopUp('signup');
	}
	return (
		<>
			{popUp === 'signup' && <Signup setter={setPopUp} />}
			{popUp === 'login' && <Login switchSignUp={handleSignUpClick} setter={setPopUp} />}
			<Nav className='miniNav'>
				<Container className='miniNav0Container'>
					<div className='miniNavContainer'>
						<div className='left'>
							<SelectorLink
								onClick={() => setExpanded(false)}
								to='/contact-us'
								className='miniNav-item nav-link d-none d-sm-non d-md-block'>
								{t('contact-us')}
							</SelectorLink>
							<SelectorLink
								onClick={() => setExpanded(false)}
								to='/about-us'
								className='miniNav-item nav-link d-none d-sm-non d-md-block'>
								{t('about-us')}
							</SelectorLink>
							{currentUser ? (
								<div className='user'>
									<Link onClick={() => setExpanded(false)} to='/profile' className='miniNav-item nav-link'>
										{currentUser?.data?.userName}
									</Link>

									{notifications?.length > 0 && (
										<div className='notification-bell'>
											<SelectorLink
												onClick={() => setExpanded(false)}
												to='/my-tickets'
												className='miniNav-item nav-link'>
												<BellFill />
											</SelectorLink>
											<span id='notiNum'>{notifications?.length}</span>
										</div>
									)}
								</div>
							) : (
								<button className='miniNav-item nav-link mb-1' onClick={handleSignInClick}>
									{t('sign-in')}
								</button>
							)}
						</div>
						<div className='right'>
							{currentUser?.data?.role === 'client' && (
								<SelectorLink onClick={() => setExpanded(false)} to='/my-cart' className='miniNav-item nav-link'>
									<Cart4 />
									{cart?.length > 0 && <span id='notiNum'>{cart?.length}</span>}
								</SelectorLink>
							)}
							<NavDropdown
								defaultValue={i18n.resolvedLanguage}
								// onChange={e => i18n.changeLanguage(e.target.value)}
								title={t('language')}
								id='nav-dropdown'
								className='miniNav-item'>
								{Object.keys(languages).map(key => (
									<NavDropdown.Item
										onClick={() => i18n.changeLanguage(key)}
										key={key}
										eventKey={key}
										disabled={i18n.resolvedLanguage === key}>
										{languages[key].nativeName}
									</NavDropdown.Item>
								))}
							</NavDropdown>
						</div>
					</div>
				</Container>
			</Nav>
			<MainNav expanded={expanded} setExpanded={setExpanded} />
			{!wait && <>{show && <Cookie setShow={setShow} />}</>}
		</>
	);
}

function MainNav({ expanded, setExpanded }) {
	const location = useLocation();
	const { t } = useTranslation();
	const languageRef = useRef(null);
	const { currentUser } = useAuth();
	const { notifications, cart, popUp, setPopUp } = useStates();
	const { i18n } = useTranslation();
	const mnRef = useRef();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);

	function handleSignInClick(e) {
		e.preventDefault();
		setPopUp('login');
	}

	function handleSignUpClick(e) {
		e.preventDefault();
		setPopUp('signup');
	}

	const [sticky, setSticky] = useState(false);
	// const [expanded, setExpanded] = useState(false);

	// console.log(mnRef.current.offsetTop);
	useEffect(() => {
		const stick = mnRef.current.offsetTop;
		const handleStick = () => {
			if (window.pageYOffset > stick) {
				setSticky(true);
			} else {
				setSticky(false);
			}
		};
		window.addEventListener('scroll', handleStick);
	}, []);

	return (
		<Navbar
			ref={mnRef}
			expanded={expanded}
			style={{
				direction: `${i18n.language === 'ar' ? 'rtl' : ''}`,
				textAlign: `${i18n.language === 'ar' ? 'right' : 'center'}`,
			}}
			bg='light'
			expand='lg'
			className={`mainNav ${sticky ? 'sticky' : ''}`}>
			<Container className='navbarContainer'>
				<SelectorLink onClick={() => setExpanded(false)} to='/' className='mainNav-items nav-link'>
					<img src={Logo} alt='logo' className='navbar-brand' />
				</SelectorLink>
				<Navbar.Toggle onClick={() => setExpanded(expanded ? false : true)} aria-controls='basic-navbar-nav' />
				<Navbar.Collapse id='basic-navbar-nav' className='justify-content-end'>
					<Nav className='navMenu'>
						<SelectorLink
							onClick={() => setExpanded(false)}
							to='/hosting'
							fuzz={['/hosting']}
							className='mainNav-items nav-link'>
							{t('hosting')}
						</SelectorLink>
						<SelectorLink onClick={() => setExpanded(false)} to='/domains' className='mainNav-items nav-link'>
							{t('domains')}
						</SelectorLink>
						<SelectorLink
							onClick={() => setExpanded(false)}
							to='/websites'
							fuzz={['/websites/']}
							className='mainNav-items nav-link'>
							{t('websites')}
						</SelectorLink>
						<SelectorLink
							onClick={() => setExpanded(false)}
							to='/applications'
							fuzz={['/applications/']}
							className='mainNav-items nav-link'>
							{t('applications')}
						</SelectorLink>
						<SelectorLink
							onClick={() => setExpanded(false)}
							to='/projects'
							fuzz={['/projects/page/']}
							className='mainNav-items nav-link'>
							{t('our-works')}
						</SelectorLink>
						<SelectorLink
							onClick={() => setExpanded(false)}
							to='/blogs'
							fuzz={['/blogs/page/']}
							className='mainNav-items nav-link'>
							{t('blogs')}
						</SelectorLink>
						{['support', 'admin'].includes(currentUser?.data?.role) && (
							<SelectorLink
								to='/support'
								fuzz={['/support/ticket-page', '/support/view-message']}
								overwrite={['/support/availible-tickets', '/support/active-tickets']}
								className='mainNav-items nav-link'>
								Support Panel
							</SelectorLink>
						)}
						{currentUser?.data?.role === 'admin' && (
							<SelectorLink
								onClick={() => setExpanded(false)}
								to='/cpanel'
								fuzz={['/cpanel/']}
								className='mainNav-items nav-link'>
								Cpanel
							</SelectorLink>
						)}
						{currentUser ? (
							<SelectorLink
								to='/profile'
								fuzz={['/ticket-page']}
								overwrite={['/change-password', '/my-tickets', '/submit-ticket']}
								className='mainNav-items nav-link'>
								{t('account')}
							</SelectorLink>
						) : (
							<p className='mainNav-items nav-link' onClick={() => setPopUp('login')}>
								{t('account')}
							</p>
						)}
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}
