import React, { useContext, createContext } from 'react';

import serverApi from '../lib/Server';
import { firestore } from '../lib/Firebase';

const ProductContext = createContext();

export function useProductDB() {
	return useContext(ProductContext);
}

export default function ProductDBProvider({ children }) {
	async function buyProduct(userId, type, price, days, details) {
		try {
			const products = firestore.collection('users').doc(userId).collection('products');
			await products.add({
				type,
				price,
				details,
				days,
				createdAt: new Date(),
				expiresOn: new Date().getTime() + days * 24 * 60 * 60 * 1000,
			});
		} catch (error) {
			throw new Error('Error while buying product');
		}
	}

	async function getProducts(userId) {
		try {
			const products = firestore.collection('users').doc(userId).collection('products');
			const snapshot = await products.get();
			const data = snapshot.docs.map(doc => doc.data());
			return data;
		} catch (error) {
			throw new Error('Error while getting products');
		}
	}

	async function extendProduct(userId, productId, days) {
		try {
			const products = firestore.collection('users').doc(userId).collection('products');
			const product = await products.doc(productId).get();
			const { expiresOn } = product.data();
			await products.doc(productId).update({
				expiresOn: expiresOn + days * 24 * 60 * 60 * 1000,
			});
		} catch (error) {
			throw new Error('Error while extending product');
		}
	}

	async function searchDomain(domain) {
		try {
			const response = await serverApi('get', `/checkdomain/${domain}`);
			return response;
		} catch (error) {
			console.log(error);
			throw new Error('Error while searching domain');
		}
	}

	async function searchFullDomain(domain, tlds) {
		try {
			return await Promise.all(
				tlds.map(async tld => {
					const response = await serverApi('get', `/checkdomain/${domain}.${tld}`);
					return response;
				})
			);
		} catch (error) {
			console.log(error);
			throw new Error('Error while searching domain');
		}
	}

	const value = {
		buyProduct,
		getProducts,
		extendProduct,
		searchDomain,
		searchFullDomain,
	};
	return <ProductContext.Provider value={value}>{children}</ProductContext.Provider>;
}
