import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
// import { Link } from 'react-router-dom';
import Blog from '../../../components/cards/Blog';
import { useSiteData } from '../../../contexts/SiteDataContext';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function BlogsSection() {
  const { t } = useTranslation();
  const { blogsGet } = useSiteData();

  const [blogs, setBlogs] = useState([]);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    window.screen.width <= 760 ? setIsMobile(true) : setIsMobile(false);
  }, []);

  function detectWindowSize() {
    window.innerWidth <= 760 ? setIsMobile(true) : setIsMobile(false);
  }

  window.onresize = detectWindowSize;

  useEffect(() => {
    blogsGet().then((res) => {
      setBlogs(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <section className='service-container blogs-section'>
        <h1 className='bold-ser black'>{t('blogs.title')}</h1>
        <h6 className='ser gray'>{t('blogs.desc')}</h6>
        <div className='container'>
          <div className='row d-flex'>
            {!isMobile &&
              blogs &&
              blogs.map(
                (blog, i) =>
                  i <= 7 && (
                    <div key={i} className='col-lg-3 col-md-4 col-s-12'>
                      <Blog
                        title={blog.title}
                        link={`/blogs/page/${blog._id}`}
                        img={blog.imageUrl}
                      />
                    </div>
                  )
              )}
            <Carousel>
              {isMobile &&
                blogs &&
                blogs.map(
                  (blog, i) =>
                    i <= 7 && (
                      <Carousel.Item key={i}>
                        <Blog
                          title={blog.title}
                          link={`/blogs/page/${blog._id}`}
                          img={blog.imageUrl}
                        />
                      </Carousel.Item>
                    )
                )}
            </Carousel>
          </div>
        </div>
        <Link to='/blogs'>
          <button className='more-button'>{t('more')}</button>
        </Link>
      </section>
    </>
  );
}
