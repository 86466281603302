import React, { useEffect, useState } from 'react';

import ChatBubble from './components/ChatBubble';
import Chat from './components/Chat';
import LoginSignup from './components/LoginSignup';
import NewChat from './components/NewChat';
import Broken from './components/Broken';
import Confirm from './components/Confirm';
import close from '../../../assets/Chat/close.svg';
import exit from '../../../assets/Chat/exit.svg';
import headbot from '../../../assets/Chat/headbox.svg';
import test from '../../../assets/Chat/test.jpg';
import { useAuth } from '../../../contexts/AuthContext';
import { useStates } from '../../../contexts/StatesContext';
import { useUserDB } from '../../../contexts/UserDBContext';
import { motion } from 'framer-motion';

import { t } from 'i18next';
export default function LiveChat() {
	const { currentUser } = useAuth();
	const { chatDisabled, lc } = useStates();
	const { getLiveChatRef, closeLiveChat, userInfoGet } = useUserDB();

	const [isOpen, setIsOpen] = useState(false);
	const [active, setActive] = useState(currentUser?.data?.liveChat ? true : false);
	const [confirm, setConfirm] = useState(false);
	const [supInfo, setSupInfo] = useState(null);
	const [color, setColor] = useState('orange');
	const [typing, setTyping] = useState(false);

	function handleOpen() {
		setIsOpen(true);
	}

	function handleClose(e) {
		e.preventDefault();
		setIsOpen(false);
	}

	function handleExit(e) {
		e.preventDefault();
		setConfirm(true);
		// setActive(false);
	}

	useEffect(() => {
		if (active) {
			// setSupInfo(null) && setColor('orange');
			const chatRef = getLiveChatRef(lc);
			const chatUnsub = chatRef.onSnapshot(snapshot => {
				if (snapshot.data().activity === 'closed') {
					setActive(false);
				} else {
					if (snapshot.data().supportID) {
						userInfoGet(snapshot.data().supportID).then(sup => {
							setSupInfo(sup);
							setColor('#13CA7E');
						});
						if (snapshot.data().typing) {
							// console.log('typing');
							setTyping(true);
						} else {
							setTyping(false);
						}
					}
				}
			});
			return chatUnsub;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lc, active]);

	return (
		(currentUser?.data?.role === 'client' || !currentUser) && (
			<>
				{!isOpen ? (
					<div onClick={handleOpen}>
						<ChatBubble />
					</div>
				) : (
					<>
						<div className='chat-box'>
							{chatDisabled ? (
								<>
									<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.4 }}>
										<div className='chat-head-disabled'>
											<button
												style={{
													border: 'none',
													outline: 'none',
													backgroundColor: 'transparent',
													color: '#fff',
													position: 'absolute',
													top: '0',
													right: '0',
												}}
												onClick={handleClose}
												className='bi bi-x-lg d-xs-block d-sm-none'
											/>
											<h5>Support is closed right now.</h5>
										</div>
										<Broken />
									</motion.div>
								</>
							) : !currentUser ? (
								<>
									<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.4 }}>
										<div className='chat-head-start'>
											<button
												style={{
													fontSize: '1.2rem',
													border: 'none',
													outline: 'none',
													backgroundColor: 'transparent',
													color: '#fff',
													position: 'absolute',
													top: '0',
													right: '0',
													marginTop: '.5rem',
													marginRight: '.4rem'
												}}
												onClick={handleClose}
												className='bi bi-x-lg d-xs-block d-sm-none'
											/>
											<h5>{t('chat-title')}</h5>
											<h6>{t('chat-desc')}</h6>
											<img height='25%' src={headbot} alt='headbot' />
										</div>
										<LoginSignup />
									</motion.div>
								</>
							) : confirm ? (
								<>
									<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.4 }}>
										<div className='chat-head-start'>
											<button
												style={{
													border: 'none',
													outline: 'none',
													backgroundColor: 'transparent',
													color: '#fff',
													position: 'absolute',
													top: '0',
													right: '0',
												}}
												onClick={handleClose}
												className='bi bi-x-lg d-xs-block d-sm-none'
											/>
											<h5>{t('chat-title')}</h5>
											<h6>{t('chat-desc')}</h6>
											<img height='25%' src={headbot} alt='headbot' />
										</div>
										<Confirm setConfirm={setConfirm} setIsOpen={setIsOpen} setActive={setActive} />
									</motion.div>
								</>
							) : active ? (
								<>
									<div className='chat-head'>
										<button
											style={{
												border: 'none',
												outline: 'none',
												backgroundColor: 'transparent',
												color: '#fff',
												position: 'absolute',
												top: '0',
												right: '0',
											}}
											onClick={handleClose}
											className='bi bi-x-lg d-xs-block d-sm-none'
										/>
										<div style={{ display: 'flex' }}>
											<img
												className='rounded-circle mb-auto mt-auto'
												height='65%'
												src={supInfo ? supInfo.imageUrl : headbot}
												alt='support img'
											/>
											<div style={{ paddingLeft: '1rem' }} className='mb-auto mt-auto'>
												<p style={{ margin: '0', fontSize: '1rem' }} className='white bold'>
													{supInfo?.firstName || 'Support'}
												</p>
												<p
													style={{
														marginBottom: '0',
														display: 'flex',
														fontSize: '.8rem',
														color: '#E4E4E4',
													}}>
													<i
														style={{
															color: color,
															fontSize: '.6rem',
															marginRight: '0.4rem',
															marginTop: 'auto',
															marginBottom: 'auto',
														}}
														class='bi bi-circle-fill'></i>
													{supInfo?.userName ? (typing ? 'typing...' : t('online')) : 'waiting..'}
												</p>
											</div>
										</div>
										<div className='chat-head-options mb-auto mt-auto'>
											{active && (
												<a className='white Link' onClick={handleExit} href='/'>
													{t('end-chat')}
												</a>
											)}
										</div>
									</div>
									<Chat supInfo={supInfo} typing={false} />
								</>
							) : (
								<>
									<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.4 }}>
										<div className='chat-head-start'>
											<button
												style={{
													border: 'none',
													outline: 'none',
													backgroundColor: 'transparent',
													color: '#fff',
													position: 'absolute',
													top: '0',
													right: '0',
												}}
												onClick={handleClose}
												className='bi bi-x-lg d-xs-block d-sm-none'
											/>
											<h5>{t('chat-title')}</h5>
											<h6>{t('chat-desc')}</h6>
											<img height='25%' src={headbot} alt='headbot' />
										</div>
										<NewChat activeChanger={setActive} />
									</motion.div>
								</>
							)}
						</div>
						<div onClick={handleClose}>
							<ChatBubble />
						</div>
					</>
				)}
			</>
		)
	);
}
