import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Alert } from 'react-bootstrap';

import { daysUntilToday } from '../../../utils/simpleFunctions.js';
import { useTicketDB } from '../../../contexts/TicketDBContext';
import { useAuth } from '../../../contexts/AuthContext.js';

import UserIcon from '../../../assets/user.png';

function TicketMessage({ isFirst, sender, subject, message, timestamp }) {
  return (
    <>
      {!isFirst && <hr className='line'></hr>}
      <div>
        <div className='user-info'>
          <img className='pfp' alt='profile-pic' src={UserIcon} />
          <p className='bold blue'>{sender}</p>
          <p className='semibold gray'>- {daysUntilToday(timestamp)} days ago</p>
        </div>
        <p className='semibold gray'>
          <strong>{subject}</strong>
        </p>
        <p className='semibold gray'>{message}</p>
      </div>
    </>
  );
}

export default function TicketPage() {
  const { ticketID } = useParams();
  const navigate = useNavigate();

  const { ticketGet, ticketAccept } = useTicketDB();
  const { currentUser } = useAuth();

  const [ticket, setTicket] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  async function handleAccept(e) {
    e.preventDefault();
    setError(null);
    setLoading(true);

    await ticketAccept(ticketID, currentUser.uid);
    setLoading(false);
    navigate(`/support/ticket-page/${ticketID}`);
  }

  function getTicket() {
    ticketGet(ticketID).then((ticket) => setTicket(ticket));
  }

  useEffect(() => {
    getTicket();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <>
      <div className='col-xl-8 col-lg-12 col-md-12 col-sm-12'>
        <div className='space'></div>
        <div className='space-sm'></div>
        <div className='d-table'>
          <div className='form-fields'>
            {ticket?.messages.map((message, index) => (
              <TicketMessage
                key={index}
                isFirst={index === 0}
                sender={message.sender === 'client' ? 'Client' : 'You'}
                subject={message.messageSubject}
                message={message.messageDescription}
                timestamp={message.createdAt}
              />
            ))}
            {error && <Alert variant='danger'>{error}</Alert>}
            <button disabled={loading} onClick={handleAccept} className='button-sm'>
              Accept
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
