import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { Form, Alert } from 'react-bootstrap';
import { Editor } from 'react-bootstrap-editor';

import { useAuth } from '../../../contexts/AuthContext';
import { useTicketDB } from '../../../contexts/TicketDBContext';
import { useUserDB } from '../../../contexts/UserDBContext';
import { useTranslation } from 'react-i18next';
export default function SubmitTicket() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const departmentSelectorRef = useRef();
  const issueTitleRef = useRef();
  const issueSubjectRef = useRef();
  const issueDescriptionRef = useRef();

  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { ticketCreate } = useTicketDB();
  const { userTicketAdd } = useUserDB();

  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState(null);

  async function handleSubmit(e) {
    e.preventDefault();

    if (departmentSelectorRef.current.value === 'none')
      return setError('Please select a department');
    if (issueTitleRef.current.value === '') return setError('Please enter a title');
    // if (issueSubjectRef.current.value === '') return setError('Please enter a subject');
    // if (issueDescriptionRef.crrent.value === '') return setError('Please enter a description');

    setLoading(true);
    setError(null);
    setMessage(null);

    try {
      const ticket = await ticketCreate({
        title: issueTitleRef.current.value,
        messages: [
          {
            messageSubject: ' ',
            messageDescription: content,
            sender: 'client',
            createdAt: new Date(),
          },
        ],
        clientID: currentUser.uid,
        department: departmentSelectorRef.current.value,
      });
      await userTicketAdd(ticket.id);
      setMessage('Ticket created successfully');
      navigate('/my-tickets');
    } catch (error) {
      setError(error.message);
    }
    setLoading(false);
  }

  return (
    <>
      <div className='col-xl-8 col-lg-12 col-md-12 col-sm-12'>
        <div className='space'></div>
        <div className='space-sm'></div>

        <div className='d-table'>
          {error && <Alert variant='danger'>{error}</Alert>}
          {message && <Alert variant='success'>{message}</Alert>}
          <div className='form-fields'>
            <Form onSubmit={handleSubmit}>
              <div>
                <p style={{ margin: '0' }} className='semibold gray'>
                  {t('department')}:
                </p>
                <select
                  ref={departmentSelectorRef}
                  className='form-field-long'
                  id='Department'
                  name='Department'
                >
                  <option value='none' selected disabled hidden>
                    {t('select-a-department')}
                  </option>
                  <option value='Web Hosting & Severs'>{t('web-hosting-servers')}</option>
                  <option value='Domain Names'>{t('domains')}</option>
                  <option value='Web Development'>{t('web-development')}</option>
                  <option value='App Development'>{t('app-development')}</option>
                </select>
              </div>
              <div>
                <p style={{ margin: '0' }} className='semibold gray'>
                  {t('issue-title')}:
                </p>
                <input ref={issueTitleRef} type='text' className='form-field-long' />
              </div>
              <div className='form-field-message'>
                <p style={{ margin: '0' }} className='semibold gray'>
                  {t('issue-information')}
                </p>
                <textarea
                  rows={5}
                  onChange={(e) => {
                    setContent(e.target.value);
                  }}
                />
              </div>

              <button disabled={loading} className='button-sm'>
                Create Ticket
              </button>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
