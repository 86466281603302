export const langs = [
	'English',
	'Spanish (Español)',
	'French (Français)',
	'German (Deutsch)',
	'Portuguese (Português)',
	'Arabic (العربيَّة)',
	'Turkish (Türkçe)',
	'Greek (ελληνικά)',
	'Persian (فارسی)',
	'Russian (Русский)',
	'Indian (हिन्दी)',
	'Italian (Italiano)',
	'Indonesian',
	'Kurdish (كوردی)',
	'Swedish',
	'Urdu (اُردُو)',
	'Armenian',
];
