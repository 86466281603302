import React, { useContext } from 'react';
import UserDBProvider from './UserDBContext';
import SupportDBProvider from './SupportDBContext';
import TicketDBProvider from './TicketDBContext';
import ProductDBProvider from './ProductDBContext';
import { LiveChatDBProvider } from './LiveChatDBContext';

const DBContext = React.createContext();

export function useDBContext() {
	return useContext(DBContext);
}

export function DBProvider({ children }) {
	const value = {};
	return (
		<>
			<DBContext.Provider value={value}>
				<UserDBProvider>
					<SupportDBProvider>
						<TicketDBProvider>
							<LiveChatDBProvider>
								<ProductDBProvider>{children}</ProductDBProvider>
							</LiveChatDBProvider>
						</TicketDBProvider>
					</SupportDBProvider>
				</UserDBProvider>
			</DBContext.Provider>
		</>
	);
}
