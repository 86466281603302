import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import DomainSection from './components/DomainSection';
import LanguageSlider from './components/LanguageSlider';
import ServiceContainer from './components/ServiceContainer';
import Pricing from './components/Pricing';
import ProjectsSection from './components/ProjectsSection';
import PlanSection from './components/PlanSection';
import BlogsSection from './components/BlogsSection';
import NewsLetterSection from './components/NewsLetterSection';
import AwardWinning from './components/AwardWinning';

export default function Home() {
  return (
    <>
      <DomainSection />
      {/* Add Arrows and js slider */}
      <LanguageSlider />
      <div className='space-sm'></div>
      <div id='service-container'>
        <ServiceContainer />
      </div>
      <Pricing />
      <ProjectsSection />
      <PlanSection />
      <BlogsSection />
      <NewsLetterSection />
      <AwardWinning />
    </>
  );
}
