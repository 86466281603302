import React from 'react';
import header from '../../assets/arrow.svg';
import { motion } from 'framer-motion';
export default function Header(props) {
  let arrow;
  if (props.arrow === true) {
    arrow = <img src={header} alt='header' className='headerimg-arrow' />;
  }
  return (
    <>
      {props.bg ? (
        <>
          <section style={{ width: '100%' }}>
            <img style={{ width: '100%' }} src={props.bg}></img>
          </section>
        </>
      ) : (
        <>
          <section>
            <section className='header-two'>
              <div className='arrow-container'>{arrow}</div>
            </section>

            <img src={props.img1} alt='' className='headerimg-left' />
            <img src={props.img2} alt='' className='headerimg-right' />

            <div className='header-container'>
              <div className='header-text-container'>
                <h1 className='header-title bolder white'>{props.title}</h1>
                <h5 className='header-desc white'>{props.desc}</h5>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
}
