import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import { useSiteData } from '../../../contexts/SiteDataContext';

export default function PostBlog() {
  const { _id } = useParams();
  const { premiumDomainUpdate, premiumDomainGet } = useSiteData();
  const navigate = useNavigate();

  const domainRef = useRef();
  const priceRef = useRef();
  const renewPriceRef = useRef();

  const [domain, setDomain] = useState('');

  async function handleSubmit(e) {
    e.preventDefault();
    await premiumDomainUpdate(
      _id,
      domainRef.current.value,
      priceRef.current.value,
      renewPriceRef.current.value
    );
    navigate('/cpanel/listdomains');
  }

  useEffect(() => {
    premiumDomainGet(_id).then((res) => setDomain(res));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (domain) {
      domainRef.current.value = domain.domain;
      priceRef.current.value = domain.price;
      renewPriceRef.current.value = domain.renewPrice;
    }
  }, [domain]);

  return (
    <>
      <section className='blue-bg'>
        <Link to='/cpanel' className='signup-button Link'>
          Return to cPanel
        </Link>
        <div className='space'></div>
        <div className='container'>
          <h1 className='center bolder blue'>Add Domain</h1>
          <Form onSubmit={handleSubmit}>
            <div className='row'>
              <div className='col-lg-6 col-sm-12 center'>
                <h2 className='Bold black'>Domain</h2>
                <div className='space-sm'></div>
                <input ref={domainRef} type='text' className='form-control' />
                <div className='space'></div>
              </div>
              <div className='col-lg-6 col-sm-12 center'>
                <h2 className='Bold black'>Price</h2>
                <div className='space-sm'></div>
                <input ref={priceRef} type='text' className='form-control' />
                <div className='space'></div>
              </div>
              <div className='col-lg-6 col-sm-12 center'>
                <h2 className='Bold black'>Renew Price</h2>
                <div className='space-sm'></div>
                <input ref={renewPriceRef} type='text' className='form-control' />
                <div className='space'></div>
              </div>
              <div className='col-12'>
                <button type='submit' className='btn btn-primary center'>
                  Update
                </button>
              </div>
            </div>
          </Form>
        </div>
      </section>
    </>
  );
}
