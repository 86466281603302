import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { useUserDB } from '../../../../contexts/UserDBContext';
import mouse from '../../../../assets/Chat/mouse.svg';

export default function NewChat({ activeChanger }) {
	const { t } = useTranslation();
	const { i18n } = useTranslation();
	const departmentRef = useRef();
	const messageRef = useRef();
	const { startLiveChat } = useUserDB();

	const [isDisabled, setIsDisabled] = useState(true);
	const [emoji, setEmoji] = useState(false);
	useState(() => {
		function handleKeyDown(e) {
			if (e.keyCode === 27) {
				setEmoji(false);
			}
		}
		window.addEventListener('keydown', handleKeyDown);
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, []);

	function enableChat() {
		if (departmentRef.current.value !== '0' && messageRef.current.value) setIsDisabled(false);
		else setIsDisabled(true);
	}

	async function handleSubmit(e) {
		e.preventDefault();
		try {
			await startLiveChat(departmentRef.current.value, messageRef.current.value);
			activeChanger(true);
		} catch (error) {
			console.log(error);
		}
	}

	return (
		<>
			<div className='chat-body-start'>
				<h5 className='black center'>{t('select-department')}</h5>
				<hr></hr>
				<select
					style={{
						direction: `${i18n.language === 'ar' ? 'rtl' : ''}`,
						textAlign: `${i18n.language === 'ar' ? 'right' : 'left'}`,
					}}
					ref={departmentRef}
					defaultValue='0'
					onChange={enableChat}
					className='bold blue'>
					<option value='0' disabled>
						{t('department')}: *
					</option>
					<option value='Hosting'>{t('hosting')}</option>
					<option value='Domains'>{t('domains')}</option>
					<option value='Websites'>{t('websites')}</option>
					<option value='Applications'>{t('applications')}</option>
				</select>
				<div className='center'>
					<img src={mouse} alt='support mouse' />
				</div>
				<form
					style={{
						direction: `${i18n.language === 'ar' ? 'rtl' : ''}`,
						textAlign: `${i18n.language === 'ar' ? 'right' : 'left'}`,
					}}
					onSubmit={handleSubmit}
					className='chat-message-container'>
					<input
						style={{
							direction: `${i18n.language === 'ar' ? 'rtl' : ''}`,
							textAlign: `${i18n.language === 'ar' ? 'right' : 'left'}`,
						}}
						ref={messageRef}
						onChange={enableChat}
						type='text'
						placeholder={t('your-message')}
						className='chat-first-message'
					/>
					{emoji && (
						<Picker
							data={data}
							previewPosition='none'
							theme='light'
							autoFocus
							onEmojiSelect={emojii => {
								messageRef.current.value += emojii.native;
								setEmoji(false);
								messageRef.current.focus();
							}}
						/>
					)}
					<button
						onClick={() => setEmoji(!emoji)}
						style={{
							float: `${i18n.language === 'ar' ? 'right' : ''}`,
							width: 'fit-content',
							padding: '0',
						}}
						type='button'
						className='bi bi-emoji-smile'></button>
					<button
						style={{
							float: `${i18n.language === 'ar' ? 'right' : ''}`,
							transform: `${i18n.language === 'ar' ? 'scaleX(-1)' : ''}`,
						}}
						disabled={isDisabled}>
						<i alt='Send' className='bi bi-chevron-double-right'></i>
					</button>
				</form>
			</div>
		</>
	);
}
