import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Alert } from 'react-bootstrap';
import { Editor } from 'react-bootstrap-editor';

import { daysUntilToday } from '../../../utils/simpleFunctions.js';
import { useTicketDB } from '../../../contexts/TicketDBContext';

import UserIcon from '../../../assets/user.png';

function TicketMessage({ isFirst, sender, subject, message, timestamp }) {
	return (
		<>
			{!isFirst && <hr className='line'></hr>}
			<div>
				<div className='user-info'>
					<img className='pfp' alt='profile-pic' src={UserIcon} />
					<p className='bold blue'>{sender}</p>
					<p className='semibold gray'>- {daysUntilToday(timestamp)} days ago</p>
				</div>
				<p className='semibold gray'>
					<strong>{subject}</strong>
				</p>
				<p dangerouslySetInnerHTML={{ __html: message }} className='semibold gray' />
			</div>
		</>
	);
}

export default function TicketPage() {
	const { ticketID } = useParams();
	const navigate = useNavigate();

	const subjectRef = useRef();
	const messageRef = useRef();

	const { ticketGet, ticketMessage, ticketClose } = useTicketDB();

	const [ticket, setTicket] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [content, setContent] = useState(null);
	const [state, setState] = useState(0);
	function forceUpdate() {
		setState(state + 1);
	}
	async function handleSubmit(e) {
		e.preventDefault();
		setError(null);
		setLoading(true);

		// if (subjectRef.current.value === '' || messageRef.current.value === '') {
		// 	setError('Please fill out all fields');
		// 	setLoading(false);
		// 	return;
		// }

		try {
			await ticketMessage({
				ticketID,
				messageSubject: ' ',
				messageDescription: content,
			});
			// messageRef.current.props.value = '';
			messageRef.current.box.current.innerHTML = '';

			setContent('');
			forceUpdate();
		} catch (error) {
			setError(error);
		}
		setLoading(false);
	}

	async function handleClose(e) {
		e.preventDefault();
		await ticketClose(ticketID);
		navigate('/support/active-tickets');
	}

	function getTicket() {
		ticketGet(ticketID).then(ticket => setTicket(ticket));
	}

	useEffect(() => {
		getTicket();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading]);

	return (
		<>
			<div className='col-xl-8 col-lg-12 col-md-12 col-sm-12'>
				<div className='space'></div>
				<div className='space-sm'></div>
				<div className='d-table'>
					<div className='form-fields'>
						{ticket?.messages.map((message, index) => (
							<TicketMessage
								key={index}
								isFirst={index === 0}
								sender={message.sender === 'client' ? 'Client' : 'You'}
								subject={message.messageSubject}
								message={message.messageDescription}
								timestamp={message.createdAt}
							/>
						))}
						{error && <Alert variant='danger'>{error}</Alert>}
						<Form onSubmit={handleSubmit}>
							<div className='form-field-message'>
								<div className='space'></div>
							</div>
							<Editor
								ref={messageRef}
								value={content}
								onChange={text => {
									setContent(text);
								}}
							/>
							<button disabled={loading} type='submit' className='button-sm'>
								Send
							</button>
							<button disabled={loading} onClick={handleClose} className='button-sm'>
								Close Ticket
							</button>
						</Form>
					</div>
				</div>
			</div>
		</>
	);
}
