import React from 'react';
import { Navigate } from 'react-router-dom';

import { useAuth } from '../contexts/AuthContext';

export default function PrivateRoute({ children, goto, roles }) {
	const { currentUser } = useAuth();

	return roles.includes(currentUser?.data?.role) ? children : <Navigate to={goto} />;
}
