import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { Editor } from 'react-bootstrap-editor';

import { useSiteData } from '../../../contexts/SiteDataContext';

export default function UpdateProject() {
	const { _id } = useParams();
	const { projectUpdate, projectGet } = useSiteData();
	const navigate = useNavigate();

	const titleRef = useRef();
	const contentRef = useRef();
	const ARtitleRef = useRef();
	const ARcontentRef = useRef();
	const TRtitleRef = useRef();
	const TRcontentRef = useRef();
	const linkRef = useRef();
	const typeRef = useRef();

	const [project, setProject] = useState(null);
	const [selectedImages, setSelectedImages] = useState(Array(5));
	const [content, setContent] = useState(null);
	const [ARcontent, setARContent] = useState(null);
	const [TRcontent, setTRContent] = useState(null);

	function handleUpload(e, i) {
		const imgs = [...selectedImages];
		imgs[i] = e.target.files[0];
		setSelectedImages(imgs);
	}

	function handleRemoveImage(i) {
		const imgs = [...selectedImages];
		imgs[i] = null;
		setSelectedImages(imgs);
	}

	function handleSubmit(e) {
		e.preventDefault();
		console.log(linkRef.current.value);
		projectUpdate(
			_id,
			{
				...(titleRef.current.value && { title: titleRef.current.value }),
				...(content && { content: content }),
				...(ARtitleRef.current.value && { title: ARtitleRef.current.value }),
				...(ARcontent && { content: ARcontent }),
				...(TRtitleRef.current.value && { title: TRtitleRef.current.value }),
				...(TRcontent && { content: TRcontent }),
				...(typeRef.current.value && { type: typeRef.current.value }),
				...(linkRef.current.value && { link: linkRef.current.value }),
			},
			selectedImages
		).then(() => navigate('/cpanel/listprojects'));
	}

	useEffect(() => {
		projectGet(_id).then(data => setProject(data));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (project) {
			if (project.title) titleRef.current.value = project.title;
			if (project.content) contentRef.current.box.current.innerHTML = project.content;
			if (project.ARtitle) ARtitleRef.current.value = project.ARtitle;
			if (project.ARcontent) ARcontentRef.current.box.current.innerHTML = project.ARcontent;
			if (project.TRtitle) TRtitleRef.current.value = project.TRtitle;
			if (project.TRcontent) TRcontentRef.current.box.current.innerHTML = project.TRcontent;
			if (project.type) typeRef.current.value = project.type;
			if (project.link) linkRef.current.value = project.link;
		}
	}, [project]);

	return (
		<>
			<section className='blue-bg'>
				<div className='container'>
					<h1 className='center bolder blue'>Post Project</h1>
					<Form onSubmit={handleSubmit}>
						<div className='row'>
							<div className='col-lg-6 col-sm-12 center'>
								<h2 className='Bold black'>Title</h2>
								<div className='space-sm'></div>
								<input ref={titleRef} type='text' className='form-control' />
								<div className='space'></div>
							</div>
							<div className='col-lg-6 col-sm-12 center'>
								<h2 className='Bold black'>Title Arabic</h2>
								<div className='space-sm'></div>
								<input ref={ARtitleRef} type='text' className='form-control' />
								<div className='space'></div>
							</div>
							<div className='col-lg-6 col-sm-12 center'>
								<h2 className='Bold black'>Title Turkish</h2>
								<div className='space-sm'></div>
								<input ref={TRtitleRef} type='text' className='form-control' />
								<div className='space'></div>
							</div>
							<div className='col-lg-6 col-sm-12 center'>
								<h2 className='Bold black'>Content</h2>
								<div className='space-sm'></div>
								{/* <textarea ref={contentRef} className='form-control' rows='10' /> */}
								<Editor
									ref={contentRef}
									onChange={text => {
										setContent(text);
									}}
								/>
								<div className='space'></div>
							</div>
							<div className='col-lg-6 col-sm-12 center'>
								<h2 className='Bold black'>Content Arabic</h2>
								<div className='space-sm'></div>
								{/* <textarea ref={contentRef} className='form-control' rows='10' /> */}
								<Editor
									ref={ARcontentRef}
									onChange={text => {
										setContent(text);
									}}
								/>
								<div className='space'></div>
							</div>
							<div className='col-lg-6 col-sm-12 center'>
								<h2 className='Bold black'>Content Turkish</h2>
								<div className='space-sm'></div>
								{/* <textarea ref={contentRef} className='form-control' rows='10' /> */}
								<Editor
									ref={TRcontentRef}
									onChange={text => {
										setContent(text);
									}}
								/>
								<div className='space'></div>
							</div>
							<div className='col-lg-6 col-sm-12 center'>
								<h2 className='Bold black'>Type</h2>
								<div className='space-sm'></div>
								<select ref={typeRef} className='form-field-select-three space-sm' id='TypeSelect' name='Type Select'>
									<option value='' disabled selected>
										Select your option
									</option>
									<option value='Mobile Applications'>Mobile Applications</option>
									<option value='Websites'>Websites</option>
									<option value='Motion Graphics'>Motion Graphics</option>
								</select>
							</div>
							<div className='col-lg-6 col-sm-12 center'>
								<h2 className='Bold black'>Link</h2>
								<div className='space-sm'></div>
								<input ref={linkRef} type='text' className='form-control' />
								<div className='space'></div>
							</div>
							<div className='col-lg-6 col-sm-12 '>
								<h2 className='Bold black'>Images</h2>
								<div className='space-sm'></div>
								{Array(5)
									.fill(0)
									.map((_, i) => (
										<div className='col-lg-3 col-xs-12'>
											<p>{i + 1}</p>
											{selectedImages[i] && (
												<>
													<img src={URL.createObjectURL(selectedImages[i])} style={{ width: '15%' }} alt='' />
													<button onClick={e => handleRemoveImage(i)}>Remove</button>
												</>
											)}
											{!selectedImages[i] && (
												<>
													<img src={project?.imageUrls[i]} style={{ width: '15%' }} alt='' />
													<input
														onChange={e => {
															e.preventDefault();
															handleUpload(e, i);
														}}
														type='file'
														id='img'
														name='img'
														accept='image/*'
													/>
												</>
											)}
										</div>
									))}
							</div>
							<button type='submit' className='btn btn-primary'>
								Post
							</button>
						</div>
					</Form>
				</div>
			</section>
		</>
	);
}
