import React, { useRef, useEffect, useState } from 'react';
import Carousel from './Carousel';

// import images from ../../../assets/ using img src as names
import Dart from '../../../assets/Dart.png';
import ASP from '../../../assets/ASP.png';
import Flutter from '../../../assets/Flutter.png';
import PHP from '../../../assets/Php.png';
import Python from '../../../assets/Python.png';
import Swift from '../../../assets/Swift.png';
import { motion } from 'framer-motion';

export default function LanguageSlider() {
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);

  useEffect(() => {
    window.innerWidth <= 760
      ? setIsMobile(true) && setIsTablet(false)
      : window.innerWidth <= 1000
      ? setIsTablet(true) && setIsMobile(false)
      : setIsMobile(false) && setIsTablet(false);
  }, []);

  function detectWindowSize() {
    window.innerWidth <= 760
      ? setIsMobile(true) && setIsTablet(false)
      : window.innerWidth <= 1000
      ? setIsTablet(true) && setIsMobile(false)
      : setIsMobile(false) && setIsTablet(false);
  }

  window.addEventListener('resize', detectWindowSize);
  return (
    <>
      {/* !!Bring search bar to top!! */}
      <section className='language-slider'>
        <Carousel show={isMobile ? 1 : isTablet ? 2 : 4}>
          <div className='single-item-container first'>
            <img alt='' src={Dart} />
          </div>
          <div className='single-item-container'>
            <img alt='' src={ASP} />
          </div>
          <div className='single-item-container'>
            <img alt='' src={Flutter} />
          </div>
          <div className='single-item-container'>
            <img alt='' src={PHP} />
          </div>
          <div className='single-item-container'>
            <img alt='' src={Python} />
          </div>
          <div className='single-item-container'>
            <img alt='' src={Swift} />
          </div>
        </Carousel>
      </section>
    </>
  );
}
