import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUserDB } from '../../../contexts/UserDBContext';
import { useTicketDB } from '../../../contexts/TicketDBContext';

function Ticket({ num, title, status, replies, department, activity, ticketID }) {
	return (
		<tr>
			<td>{num}</td>
			<td>
				<Link className='bold blue Link' to={`/ticket-page/${ticketID}`}>
					{title}
				</Link>
			</td>
			<td className={status !== 'closed' ? 'green' : 'red'}>{status !== 'closed' ? 'Active' : 'Closed'}</td>
			<td>{replies}</td>
			<td>{department}</td>
			<td>{activity}</td>
		</tr>
	);
}

export default function MyTickets() {
	const { t } = useTranslation();
	const { i18n } = useTranslation();
	const { userTicketsGet } = useUserDB();
	const { ticketGet } = useTicketDB();
	let count = 0;

	async function getTickets() {
		const ticketIDs = await userTicketsGet();
		return await Promise.all(
			ticketIDs.map(async ticketID => {
				const ticket = await ticketGet(ticketID);
				ticket._id = ticketID;
				return ticket;
			})
		);
	}

	const [tickets, setTickets] = useState([]);

	useEffect(() => {
		getTickets().then(tickets => setTickets(tickets));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className='col-xl-8 col-lg-12 col-md-12 col-sm-12'>
				<div className='space'></div>
				<h4 className='semibold gray'>{t('my-tickets')}</h4>
				<div className='space-sm'></div>
				<div className='options'>
					<Link to='/submit-ticket' className='form-field-select-four'>
						<p>{t('submit-tickets')}</p>
					</Link>
				</div>

				<div className='space-sm'></div>
				<div className='overflow'>
					<table className='table'>
						<thead>
							<tr>
								<th
									style={{
										border: `${i18n.language === 'ar' ? 'rgba(0, 0, 0, 0.37) solid 1px' : ''}`,
									}}>
									#
								</th>
								<th
									style={{
										border: `${i18n.language === 'ar' ? 'rgba(0, 0, 0, 0.37) solid 1px' : ''}`,
									}}>
									{t('title')}
								</th>
								<th
									style={{
										border: `${i18n.language === 'ar' ? 'rgba(0, 0, 0, 0.37) solid 1px' : ''}`,
									}}>
									{t('status')}
								</th>
								<th
									style={{
										border: `${i18n.language === 'ar' ? 'rgba(0, 0, 0, 0.37) solid 1px' : ''}`,
									}}>
									{t('replies')}
								</th>
								<th
									style={{
										border: `${i18n.language === 'ar' ? 'rgba(0, 0, 0, 0.37) solid 1px' : ''}`,
									}}>
									{t('department')}
								</th>
								<th
									style={{
										border: `${i18n.language === 'ar' ? 'rgba(0, 0, 0, 0.37) solid 1px' : ''}`,
									}}>
									{t('activity')}
								</th>
							</tr>
						</thead>
						<tbody>
							{tickets.map(ticket => {
								count++;
								return (
									<Ticket
										key={ticket._id}
										num={count}
										title={ticket.title}
										status={ticket.status}
										replies={ticket.messages.length}
										department={ticket.department}
										activity={
											ticket.updatedAt
												? Math.floor((Date.now() - ticket.updatedAt.toDate()) / (1000 * 3600 * 26)) + ' days ago'
												: 'No Activity'
										}
										ticketID={ticket._id}
									/>
								);
							})}
						</tbody>
					</table>
				</div>
			</div>
		</>
	);
}
