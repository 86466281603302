import React from 'react';
import { useLocation } from 'react-router-dom';

import PrivateRoute from '../../utils/PrivateRoute';

import SideMenu from './components/SideMenu';
import UpdateInfo from './components/UpdateInfo';
import ChangePassword from './components/ChangePassword';
import MyTickets from './components/MyTickets';
import MyItems from './components/MyItems';
import TicketPage from './components/TicketPage';
import ViewMessage from './components/ViewMessage';
import ActiveChats from './components/ActiveChats';
import ChatPage from './components/ChatPage';

export default function Support({ name }) {
	const location = useLocation().pathname;

	return (
		<>
			<PrivateRoute goto='/login' roles={['admin', 'support']}>
				<section className='header'>
					<h1 className='n-font head-title' style={{ color: 'white', fontWeight: 'bold' }}>
						{name}
					</h1>
				</section>

				<div className='space'></div>
				<div className='container'>
					<div className='row'>
						<SideMenu />
						<div className='col-xl-1'></div>
						{location === '/support/profile' ? (
							<UpdateInfo />
						) : location === '/support/change-password' ? (
							<ChangePassword />
						) : location === '/support/active-tickets' ? (
							<MyTickets />
						) : location.includes('/support/ticket-page/') ? (
							<TicketPage />
						) : location === '/support/availible-tickets' ? (
							<MyItems />
						) : location.includes('/support/view-message/') ? (
							<ViewMessage />
						) : location === '/support/activechats' ? (
							<ActiveChats />
						) : location.includes('/support/chatpage/') ? (
							<ChatPage />
						) : null}
					</div>
				</div>
				<div className='space'></div>
			</PrivateRoute>
		</>
	);
}
