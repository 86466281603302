import React, { useState, useEffect } from 'react';
import Header from '../../components/ui/Header';
import Blog from '../../components/cards/Blog';

import { useSiteData } from '../../contexts/SiteDataContext';
import { useTranslation } from 'react-i18next';

export default function Blogs() {
	const { t } = useTranslation();
	const { blogsGet } = useSiteData();

	const [blogs, setBlogs] = useState([]);
	const [state, setState] = useState(0);

	function forceRerender(e) {
		e.preventDefault();
		setState(state + 1);
	}

	useEffect(() => {
		blogsGet().then(res => {
			setBlogs(res);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<>
			<Header title={t('blogs')} desc={t('blogs.desc')} arrow={true} />
			<section className='service-container'>
				<h1 className='bold-ser black'>{t('blogs.title')}</h1>
				<h6 className='ser gray'>{t('blogs.desc')}</h6>

				<div className='container'>
					<div className='row'>
						{blogs &&
							blogs.map((blog, i) => (
								<div onClick={forceRerender} key={i} className='col-lg-3 col-md-4 col-s-12'>
									<Blog title={blog.title} link={`/blogs/page/${blog._id}`} img={blog.imageUrl} />
								</div>
							))}
					</div>
				</div>
			</section>
		</>
	);
}
