import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

import curvehead from '../../assets/curvehead.svg';

import { useStates } from '../../contexts/StatesContext';
import { useSiteData } from '../../contexts/SiteDataContext';
import { useUserDB } from '../../contexts/UserDBContext';
import { useAuth } from '../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';

function hostInCart(cart, plan, type, period) {
	return cart.find(item => item.data().name === `${type} - ${plan}` && item.data().period === period);
}

export default function PlanPage({ type }) {
	const periodRef = useRef();
	const { currentUser } = useAuth();
	const { hostingPlanGet } = useSiteData();
	const { userAddToCart } = useUserDB();
	const { index } = useParams();
	const { t } = useTranslation();
	const { i18n } = useTranslation();
	const { cart } = useStates();

	const [sel, setSel] = useState([]);
	const [selPrice, setSelPrice] = useState([]);
	const [hostingPlan, setHostingPlan] = useState(null);
	const [period, setPeriod] = useState('monthly');

	useEffect(() => {
		let nsel = [];
		for (let i = 0; i < hostingPlan?.valueFeatures?.length; i++) {
			nsel.push(hostingPlan?.valueFeatures[i]?.specs?.filter((v, j) => sel?.[i][j])?.[0]);
		}
		setSelPrice(nsel, console.log(selPrice));
	}, [hostingPlan, sel]);

	useEffect(() => {
		setSel(
			hostingPlan?.valueFeatures?.map(feature => {
				return feature?.specs?.map((spec, i) => {
					if (i === 0) return true;
					return false;
				});
			})
		);
	}, [hostingPlan]);

	function priceCalc() {
		const fprice = selPrice?.reduce(
			(prev, curr) => prev + parseInt(period === 'monthly' ? curr?.monthlyPrice : curr?.yearlyPrice),
			0
		);
		return fprice + parseFloat(period === 'monthly' ? hostingPlan?.monthlyPrice : hostingPlan?.yearlyPrice);
	}

	async function handleAddToCart(e) {
		e.preventDefault();

		await userAddToCart(currentUser.uid, {
			price: priceCalc(),
			period,
			type: 'Hosting',
			name: `${type} - ${hostingPlan.planName}`,
			data: {
				features: hostingPlan.features,
				specs: hostingPlan.specs,
			},
		});
	}

	async function handleChange(e) {
		setPeriod(e.target.value);
	}

	useEffect(() => {
		hostingPlanGet(type).then(data => {
			setHostingPlan(data?.[parseInt(index)]);
		});
		console.log(sel);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [period]);

	return (
		<>
			<section className='plan'>
				<h1 className='bold-ser black'>
					{type} - {hostingPlan?.planName}
				</h1>
				<div className='space-sm' />
				<img alt='' style={{ width: '100%' }} src={curvehead} />
				<div className='plan-container'>
					<div className='container'>
						<div className='plan-pricing'>
							<h5>{t('price')}</h5>
							<h5>${priceCalc()}</h5>
						</div>
						<div className='row'>
							<div className='col-12'>
								<div className='plan-table'>
									{hostingPlan &&
										Object.keys(
											i18n.language === 'ar'
												? hostingPlan?.ARspecs
												: i18n.language === 'tr'
												? hostingPlan?.TRspecs
												: hostingPlan?.specs
										)
											.sort()
											.map(x => (
												<div className='plan-item'>
													<div className='plan-item-item'>
														<p>{x}</p>
													</div>
													<div className='plan-item-item'>
														<p>
															{i18n.language === 'ar'
																? hostingPlan?.ARspecs[x]
																: i18n.language === 'tr'
																? hostingPlan?.TRspecs[x]
																: hostingPlan?.specs[x]}
														</p>
													</div>
												</div>
											))}
								</div>
							</div>
							<div className='space-between'>
								<div className='col-6 col-md-12'>
									<select
										onChange={handleChange}
										ref={periodRef}
										className='form-field-select-three space-sm'
										id='Sort'
										name='Sort By'
										defaultValue='monthly'>
										<option value='monthly'>Monthly</option>
										<option value='yearly'>Yearly</option>
									</select>
								</div>

								<div style={{ textAlign: 'right' }} className='col-6 col-md-12'>
									{!hostInCart(cart, hostingPlan?.planName, type, period) ? (
										<button onClick={handleAddToCart} className='button-sm space-sm'>
											Add to cart
										</button>
									) : (
										<button onClick={e => e.preventDefault()} className='button-sm space-sm'>
											<i class='bi bi-cart-check'></i>
										</button>
									)}
								</div>
							</div>
						</div>
					</div>
					<div className='container'>
						<div className='space-sm'></div>
						<div className='row'>
							{hostingPlan &&
								hostingPlan?.valueFeatures?.map((feature, j) => (
									<div className='col-lg-6 col-sm-12 text-left'>
										<div className='space-sm'></div>
										<h5>
											{i18n.language === 'ar' ? feature.ARname : i18n.language === 'tr' ? feature.TRname : feature.name}
										</h5>
										{feature?.specs?.map((spec, i) => (
											<form>
												<button
													onClick={e => {
														e.preventDefault();
														const nsel = [...sel];
														nsel[j].fill(false);
														nsel[j][i] = true;
														setSel(nsel);
													}}
													type='button'
													className='hosting-selection'>
													<div style={{ display: 'flex' }}>
														<input
															checked={sel?.[j]?.[i] || false}
															className='form-check-input'
															type='radio'
															name='hosting'
															value='hosting'
														/>
														<p>
															{i18n.language === 'ar' ? spec.ARname : i18n.language === 'tr' ? spec.TRname : spec.name}
														</p>
													</div>
													<div>
														<p>${period === 'monthly' ? spec.monthlyPrice : spec.yearlyPrice}</p>
													</div>
												</button>
											</form>
										))}
									</div>
								))}
						</div>
					</div>
				</div>
				<div className='space'></div>
			</section>
		</>
	);
}
