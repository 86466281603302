import { React, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

export default function PricingCard(props) {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const [isHovered, setHovered] = useState(false);
  const items = [];
  const perk = props.perk;
  for (let i = 0; i < perk.length; i++) {
    items.push(
      <div className='checks'>
        <i className='bi bi-check'></i> <h6>{perk[i]}</h6>
      </div>
    );
  }

  return (
    <>
      <motion.div
        initial={{ y: -30, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 1 }}
        className='price-card-contain card-spacer'
      >
        <div className='price-card'>
          <div className='price-head'>
            <h4 className='thin white'>{props.title}</h4>
            <h1 className='bold white'>
              ${props.price}
              <span>{props.per}</span>
            </h1>
            <p className='discount'>${props.oldPrice}</p>
            <div className='contain-off'>
              <div className='off-border'>
                <p className='off bold'>{props.discount}%</p>
              </div>
            </div>
            <p className='thin white'>{props.type}</p>
          </div>
          <div className='includes'>{items}</div>
          <Link
            style={{ display: 'inline-flex', direction: `${i18n.language === 'ar' ? 'rtl' : ''}` }}
            className='bold orange Link'
            to={props.link}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            {t('get-started')}{' '}
            <div
              style={{
                transform: `${i18n.language === 'ar' ? 'scaleX(-1)' : ''}`,
              }}
            >
              <motion.div
                animate={{ x: isHovered ? 5 : 0 }}
                initial={{ x: 0 }}
                transition={{
                  repeat: isHovered ? Infinity : 0,
                  repeatType: 'reverse',
                  duration: 0.3,
                }}
              >
                <i className='bi bi-arrow-right arrow'></i>
              </motion.div>
            </div>
          </Link>
        </div>
      </motion.div>
    </>
  );
}
