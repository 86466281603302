import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Header from '../../components/ui/Header';
import Project from '../../components/cards/Project';

import { useSiteData } from '../../contexts/SiteDataContext';
import { useTranslation } from 'react-i18next';

export default function ProjectPage() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { _id } = useParams();
  const { projectsGet, projectGet } = useSiteData();

  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState({});
  const [activeImage, setActiveImage] = useState(0);

  useEffect(() => {
    projectGet(_id).then((data) => setProject(data));
    projectsGet().then((data) => setProjects(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header title={project?.title} desc='' arrow={true} />
      <section className='post'>
        <div
          style={{
            direction: `${i18n.language === 'ar' ? 'rtl' : ''}`,
            textAlign: `${i18n.language === 'ar' ? 'right' : 'left'}`,
          }}
          className='container'
        >
          <div className='row'>
            <div className='col-lg-1' />
            <div className='col-lg-4 col-md-6 col-sm-12 pad-one'>
              <div
                className='ecommerce-gallery'
                data-mdb-zoom-effect='true'
                data-mdb-auto-height='true'
              >
                <div className='row py-3 shadow-5'>
                  <div className='col-12 mb-1'>
                    <div className='lightbox'>
                      {project && (
                        <img
                          src={project?.imageUrls?.[activeImage]}
                          alt='Gallery 1'
                          className='ecommerce-gallery-main-img active w-100'
                        />
                      )}
                    </div>
                  </div>
                  {project &&
                    project?.imageUrls?.map((img, i) => {
                      return (
                        i !== activeImage && (
                          <div className='col-3 mt-1'>
                            <img
                              src={img}
                              style={{ cursor: 'pointer' }}
                              onClick={() => setActiveImage(i)}
                              data-mdb-img='https://cdn.wallpapersafari.com/82/18/FXKaL4.jpg'
                              alt='Gallery 1'
                              className='active w-100'
                            />
                          </div>
                        )
                      );
                    })}
                </div>
              </div>
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12'>
              <h3 className='bold-ser black mt-4'>
                {i18n.language === 'ar'
                  ? project?.ARtitle
                  : i18n.language === 'tr'
                  ? project?.TRtitle
                  : project?.title}
              </h3>
              {/* <p className='gray'>{project?.content}</p> */}
              <p
                style={{
                  padding: `${i18n.language === 'ar' ? '0 1rem' : ''}`,
                }}
                className='gray'
                dangerouslySetInnerHTML={{
                  __html:
                    i18n.language === 'ar'
                      ? project?.ARcontent
                      : i18n.language === 'tr'
                      ? project?.TRcontent
                      : project?.content,
                }}
              />
              <div className='share-contain'>
                <div className='space-sm'></div>
                <a style={{ padding: '0.1rem 2rem' }} className='button-sm' href={project?.link}>
                  {t('visit')}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div style={{ paddingBottom: '4rem' }} className='space'></div>
      <section className='service-container'>
        <h1 className='bold-ser blue'>{t('similar-projects')}</h1>
        <div className='container'>
          <div className='row'>
            {projects &&
              projects
                .filter((proj) => proj.type === project?.type && proj._id !== project?._id)
                .slice(0, 4)
                .map((proj) => (
                  <div className='col-lg-3 col-md-4 col-s-12'>
                    <Project link={`/projects/page/${proj._id}`} img={proj.imageUrls[0]} />
                  </div>
                ))}
          </div>
        </div>
      </section>
    </>
  );
}
