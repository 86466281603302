import React, { useEffect, useRef, useState, yseEffect } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

import { storageRef } from '../../../../lib/Firebase';
import { useAuth } from '../../../../contexts/AuthContext';
import { useUserDB } from '../../../../contexts/UserDBContext';
import { Alert } from 'react-bootstrap';
import Clientpfp from '../../../Clientpfp';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { useStates } from '../../../../contexts/StatesContext';

function isImage(url) {
	return /\.(jpg|jpeg|png|webp|avif|gif|svg)/.test(url);
}

function SupportMessage({ message, time, pfp, file }) {
	const { i18n } = useTranslation();
	const hours = time?.getHours();
	const mins = time?.getMinutes();
	const thours = hours?.length < 2 ? '0' + hours : hours;
	const tmins = mins?.length < 2 ? '0' + mins : mins;
	const [supInfo, setSupInfo] = useState(null);
	
	const [selImage, setSelImg] = useState('');
	const [bigimg, setBigimg] = useState(false);
	return (
		<div className='col-12 mb-1'>

		<div onClick={(e) => {setBigimg(!bigimg)}} style={bigimg ? {display: 'block', position: 'absolute', width: '100%', top: '0', left: '0'} : {display: 'none'}}>
		<img src={selImage} style={{ margin: 'auto'}} />
		
		</div>
			<div className='client-message'>
				<motion.div initial={{ y: -10, opacity: 0 }} animate={{ y: 0, opacity: 1 }}>
					<img style={{ height: '3rem' }} className='rounded-circle' src={pfp} alt='support' />
				</motion.div>

				<motion.div
					className='support-text support-bg'
					style={{
						textAlign: `${i18n.language === 'ar' ? 'right' : 'left'}`,
					}}
					initial={{ y: 5, opacity: 0 }}
					animate={{ y: 0, opacity: 1 }}
					transition={{ duration: 0.5, delay: 0.5 }}>
					{file ? (
						isImage(message) ? (
							<img onClick={(e) => {setSelImg(message); setBigimg(!bigimg)}} style={{ width: '100%' }} src={message} alt='im' />
						) : (
							<a href={message}>
								<i class='bi bi-file-earmark'></i>
							</a>
						)
					) : (
						message
					)}
				</motion.div>
				<p style={{ margin: 'auto 0', marginLeft: '0.5rem', fontSize: '0.8rem' }}>{time ? thours + ':' + tmins : ''}</p>
			</div>
		</div>
	);
}

function ClientMessage({ message, time, file }) {
	const { i18n } = useTranslation();
	const hours = time?.getHours();
	const mins = time?.getMinutes();
	const thours = hours < 10 ? '0' + hours : hours;
	const tmins = mins < 10 ? '0' + mins : mins;
	
	const [selImage, setSelImg] = useState('');
	const [bigimg, setBigimg] = useState(false);
	return (
		<div className='col-12 mb-1'>

		<div onClick={(e) => {setBigimg(!bigimg)}} style={bigimg ? {backgroundColor: 'rgba(0, 0, 0, 0.6)',display: 'block', position: 'fixed',textAlign: 'center', width: '100vw',paddingTop:'5%',paddingBottom: '100%',top: '0', left: '0', margin: 'auto', zIndex: '3'} : {display: 'none'}}>
		
		<img src={selImage} style={{ margin: 'auto', width: '50vw', top: '0', left: '0'}} />
		</div>
			<div className='support'>
				<motion.div
					className='support-text client-bg'
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{ duration: 1 }}
					style={{
						textAlign: `${i18n.language === 'ar' ? 'right' : 'left'}`,
					}}>
					<motion.div
						initial={{ y: 5, opacity: 0 }}
						animate={{ y: 0, opacity: 1 }}
						transition={{ duration: 0.5, delay: 0.5 }}>
						{file ? (
							isImage(message) ? (
								<img onClick={(e) => {setSelImg(message); setBigimg(!bigimg)}} style={{ width: '100%' }} src={message} alt='im' />
							) : (
								<a href={message}>
									<i class='bi bi-file-earmark'></i>
								</a>
							)
						) : (
							message
						)}
					</motion.div>
				</motion.div>
				<p style={{ margin: 'auto 0', marginRight: '0.5rem', fontSize: '0.8rem' }}>
					{time ? thours + ':' + tmins : ''}
				</p>
			</div>
		</div>
	);
}

export default function Chat({ supInfo, typing }) {
	const { t } = useTranslation();
	const { i18n } = useTranslation();
	const dummy = useRef();
	const fileRef = useRef();
	const inputRef = useRef();
	const { currentUser } = useAuth();
	const { getLiveChatMessagesRef, sendLiveChatMessage } = useUserDB();
	const { lc } = useStates();

	// const [messages, setMessages, lc] = useState([]);
	const [error, setError] = useState(null);
	const [emoji, setEmoji] = useState(false);

	const messageRef = getLiveChatMessagesRef(lc);
	const query = messageRef?.orderBy('createdAt').limit(50);
	const [messages] = useCollectionData(query, { idField: 'id' });
	const [fileUp, setFileUp] = useState(false);

	async function handleFile(e) {
		setFileUp(e.target.files[0]);
		const file = fileRef.current.files[0];
		const filef = storageRef.child(`/livechat/${file.name}`);
		await filef.put(file);
		const url = await filef.getDownloadURL();
		await sendLiveChatMessage(lc, currentUser.uid, url, true);
		setFileUp(false);
		dummy.current.scrollIntoView({ behavior: 'smooth' });
	}

	function isEmpty(str) {
		return !str.trim().length;
	}


	async function handleSubmit(e = null) {
		e && e.preventDefault();
		emoji && setEmoji(false);
		
		if (isEmpty(inputRef.current.value)) {
			setError(t('chat.emptyMessage'));
		} else {
			setError(null);
			await sendLiveChatMessage(lc, currentUser.uid, inputRef.current.value);
			inputRef.current.value = '';
			dummy.current.scrollIntoView({ behavior: 'smooth' });
		}
	}

	useState(() => {
		function handleKeyDown(e) {
			if (e.keyCode === 27) {
				setEmoji(false);
			}
		}
		window.addEventListener('keydown', handleKeyDown);
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, []);

	const [state, setState] = useState(0);

	useEffect(() => {
		setState(state + 1);
		dummy.current.scrollIntoView({ behavior: 'smooth' });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [messages]);
	return (
		<>
			<div className='chat-body-messages'>
				{error && <Alert variant='danger'>{error}</Alert>}
				<div
					style={{
						direction: `${i18n.language === 'ar' ? 'rtl' : ''}`,
					}}
					className='chat-content'>
					<div className='chat-messages'>
						{messages &&
							messages?.map(message => {
								return message.sender === 'client' ? (
									<ClientMessage file={message.file} message={message.message} time={message?.createdAt?.toDate()} />
								) : (
									<SupportMessage
										file={message.file}
										message={message.message}
										time={message?.createdAt?.toDate()}
										pfp={supInfo?.imageUrl || null}
									/>
								);
							})}
						{typing && <SupportMessage message={<i>typing...</i>} pfp={supInfo?.imageUrl || null} />}
						<div ref={dummy} />
					</div>
				</div>

				<form
					style={{
						direction: `${i18n.language === 'ar' ? 'rtl' : ''}`,
						textAlign: `${i18n.language === 'ar' ? 'right' : 'left'}`,
					}}
					onSubmit={handleSubmit}
					className='chat-input'>
					<div className='chat-message-container'>
						
							<input
								style={{
									direction: `${i18n.language === 'ar' ? 'rtl' : ''}`,
									textAlign: `${i18n.language === 'ar' ? 'right' : 'left'}`,
								}}
								ref={inputRef}
								type='text'
								placeholder={t('your-message')}
								className='chat-first-message'
							/>
						
						
						{emoji && (
							<Picker
								data={data}
								previewPosition='none'
								theme='light'
								autoFocus
								onEmojiSelect={emojii => {
									inputRef.current.value += emojii.native;
									setEmoji(false);
									inputRef.current.focus();
								}}
							/>
						)}
						<div
							style={{
								width: '30%',
								marginTop: 'auto',
								marginBottom: 'auto',
								display: 'flex',
								justifyContent: 'space-between',
							}}>
							<button
								onClick={() => setEmoji(!emoji)}
								style={{
									float: `${i18n.language === 'ar' ? 'right' : ''}`,
									width: 'fit-content',
									padding: '0',
								}}
								type='button'
								className='bi bi-emoji-smile'></button>
							<input hidden id='fileUL' ref={fileRef} type='file' onChange={handleFile} />
							<label
								htmlFor='fileUL'
								style={{
									float: `${i18n.language === 'ar' ? 'right' : ''}`,
									cursor: 'pointer',
									width: 'fit-content',
									padding: '0',
								}}
								className='bi bi-paperclip'></label>
							<button
								style={{
									float: `${i18n.language === 'ar' ? 'right' : ''}`,
									transform: `${i18n.language === 'ar' ? 'rotate(180deg)' : ''}`,
									width: 'fit-content',
									padding: '0',
								}}
								type='submit'
								className='bi bi-chevron-double-right'></button>
						</div>
					</div>
				</form>
			</div>
		</>
	);
}
