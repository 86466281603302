import React, { useContext } from 'react';
import { useAuth } from './AuthContext';
import firebase, { firestore, storageRef } from '../lib/Firebase';
import sendEmail from '../utils/sendMail';

const UserDBContext = React.createContext();

export function useUserDB() {
	return useContext(UserDBContext);
}

export default function UserDBProvider({ children }) {
	async function uploadImage(file, path) {
		const fileRef = storageRef.child(path);
		await fileRef.put(file);
		const url = await fileRef.getDownloadURL();
		return url;
	}

	const { currentUser, signup, updateEmail, reauthenticate } = useAuth();

	async function userCreate({ email, password, userName, firstName, lastName, role }) {
		try {
			const user = await signup(email, password);
			const userRef = firestore.collection('users').doc(user.user.uid);

			await userRef.set({
				userName,
				firstName,
				lastName,
				email,
				role,
				notifications: [],
				tickets: [],
				products: [],
				savedCards: [],
				cart: [],
				createdAt: new Date(),
			});
		} catch (error) {
			throw new Error('Signup failed');
		}
	}

	async function userInfoUpdate({ email, password, userName, firstName, lastName, organization, phoneNumber, file }) {
		if (email) {
			try {
				await reauthenticate(password);
				await updateEmail(email);
			} catch (error) {
				throw new Error('Error updating your email');
			}
		}

		try {
			const imageUrl = file ? await uploadImage(file, `BlogImages/${file.name}`) : null;
			const userRef = firestore.collection('users').doc(currentUser.uid + '/');
			await userRef.set(
				{
					...(userName && { userName }),
					...(firstName && { firstName }),
					...(lastName && { lastName }),
					// TODO: verify email and phoneNumber
					...(email && { email }),
					...(phoneNumber && { phoneNumber }),
					...(organization && { organization }),
					...(imageUrl && { imageUrl }),
					updatedAt: new Date(),
				},
				{ merge: true }
			);
		} catch (error) {
			throw new Error('Error updating your information');
		}

		return currentUser;
	}

	async function userNotify({ title, message }) {
		try {
			const userRef = firestore.collection('users').doc(currentUser.uid + '/');
			const user = await userRef.get();
			await userRef.set(
				{
					notifications: [...user.data().notifications, { title, message, viewed: false, createdAt: new Date() }],
				},
				{ merge: true }
			);
			// forceRerender();
		} catch (error) {
			throw new Error('Error adding user notification');
		}
	}

	async function userViewNotification({ notificationIndex }) {
		try {
			const userRef = firestore.collection('users').doc(currentUser.uid + '/');
			const user = await userRef.get();
			const notifications = user.data().notifications;
			notifications[notificationIndex].viewed = true;
			await userRef.set({ notifications }, { merge: true });
			// forceRerender();
		} catch (error) {
			throw new Error('Error viewing user notification');
		}
	}

	async function userInfoGet(userID) {
		try {
			const userRef = firestore.collection('users').doc(userID + '/');
			const user = await userRef.get();
			return user.data();
		} catch (error) {
			throw new Error('Error getting user information');
		}
	}

	async function userSaveCard({
		owner,
		cardNumber,
		expiryDate,
		cvv,
		firstName,
		lastName,
		phoneNumber,
		billingAddress,
		billingCity,
		billingState,
		billingZip,
		billingCountry,
	}) {
		try {
			const userRef = firestore.collection('users').doc(currentUser.uid + '/');
			const user = await userRef.get();
			const savedCards = user.data().savedCards;
			const newCard = {
				owner,
				cardNumber,
				expiryDate,
				cvv,
				firstName,
				lastName,
				phoneNumber,
				billingAddress,
				billingCity,
				billingState,
				billingZip,
				billingCountry,
			};
			savedCards.push(newCard);
			await userRef.set({ savedCards }, { merge: true });
		} catch (error) {
			throw new Error('Error saving card information');
		}
	}

	async function userRemoveCard({ cardIndex }) {
		try {
			const userRef = firestore.collection('users').doc(currentUser.uid + '/');
			const user = await userRef.get();
			const savedCards = user.data().savedCards;
			savedCards.splice(cardIndex, 1);
			await userRef.set({ savedCards }, { merge: true });
		} catch (error) {
			throw new Error('Error removing card information');
		}
	}

	async function userGetCards() {
		try {
			const userRef = firestore.collection('users').doc(currentUser.uid + '/');
			const user = await userRef.get();
			return user.data().savedCards;
		} catch (error) {
			throw new Error('Error getting card information');
		}
	}

	async function userTicketAdd(ticketId) {
		try {
			const userRef = firestore.collection('users').doc(currentUser.uid + '/');
			const user = await userRef.get();
			const tickets = user.data().tickets;
			tickets.push(ticketId);
			await userRef.set({ tickets }, { merge: true });
			await sendEmail(currentUser.uid, 'Ticket Added', 'You have successfully started a ticket');
		} catch (error) {
			throw new Error('Error adding ticket to user');
		}
	}

	async function userTicketRemove({ ticketId }) {
		try {
			const userRef = firestore.collection('users').doc(currentUser.uid + '/');
			const user = await userRef.get();
			const tickets = user.data().tickets;
			tickets.splice(tickets.indexOf(ticketId), 1);
			await userRef.set({ tickets }, { merge: true });
		} catch (error) {
			throw new Error('Error removing ticket from user');
		}
	}

	async function userTicketsGet() {
		try {
			const userRef = firestore.collection('users').doc(currentUser.uid + '/');
			const user = await userRef.get();
			return user.data().tickets;
		} catch (error) {
			throw new Error('Error getting ticket information');
		}
	}

	async function userAddToCart(userID, productInfo) {
		try {
			const product = {
				...productInfo,
			};
			await firestore.collection('users').doc(userID).collection('cart').doc().set(product, { merge: true });
		} catch (error) {
			console.log(error);
			throw new Error('Error adding to cart');
		}
	}

	async function userRemoveFromCart(productID) {
		try {
			await firestore.collection('users').doc(currentUser?.uid).collection('cart').doc(productID).delete();
		} catch (error) {
			throw new Error('Error removing from cart');
		}
	}

	async function userGetCart() {
		try {
			const cart = await firestore.collection('users').doc(currentUser?.uid).collection('cart').get();
			return cart.docs.map(doc => {
				return { id: doc.id, ...doc.data() };
			});
		} catch (error) {
			throw new Error('Error getting cart');
		}
	}

	async function userPurchase() {
		try {
			const cart = await firestore.collection('users').doc(currentUser?.uid).collection('cart').get();
			const cartItems = cart.docs.map(doc => {
				return doc.data();
			});
			const productRef = firestore.collection('users').doc(currentUser.uid).collection('products');
			for (const item of cartItems) {
				await productRef.doc().set({ purchasedAt: new Date(), ...item });
			}
			for (const item of cart.docs) {
				await firestore.collection('users').doc(currentUser.uid).collection('cart').doc(item.id).delete();
			}
		} catch (error) {
			console.log(error);
			throw new Error('Error purchasing');
		}
	}

	async function userEmptyCart(userID) {
		try {
			await firestore
				.collection('users')
				.doc(userID)
				.collection('cart')
				.get()
				.then(async cart => {
					cart.docs.forEach(async doc => {
						await firestore.collection('users').doc(userID).collection('cart').doc(doc.id).delete();
					});
				});
		} catch (error) {
			throw new Error('Error emptying cart');
		}
	}

	async function userGetProducts() {
		try {
			const products = await firestore.collection('users').doc(currentUser?.uid).collection('products').get();
			return products.docs.map(doc => {
				return { id: doc.id, ...doc.data() };
			});
		} catch (error) {
			throw new Error('Error getting products');
		}
	}

	async function startLiveChat(department, initialMessage) {
		try {
			const chatRef = firestore.collection('liveChat').doc();
			await chatRef.set(
				{
					clientID: currentUser.uid,
					clientName: currentUser.data.userName,
					activity: 'waiting',
					department,
					createdAt: new Date(),
				},
				{ merge: true }
			);
			await chatRef.collection('messages').doc().set({
				message: initialMessage,
				sender: 'client',
				viewed: false,
				createdAt: firebase.firestore.FieldValue.serverTimestamp(),
			});

			const chat = await chatRef.get();
			const userRef = firestore.collection('users').doc(currentUser.uid);
			await userRef.update({ liveChat: chat.id }, { merge: true });
			return chatRef.collection('messages');
		} catch (error) {
			console.log(error);
			throw new Error('Error starting live chat');
		}
	}

	async function sendLiveChatMessage(chatID, userID, message, file = false) {
		try {
			const chatRef = firestore.collection('liveChat').doc(chatID);
			const chat = await chatRef.get();
			await chatRef
				.collection('messages')
				.doc()
				.set({
					message,
					file,
					sender: userID === chat.data().clientID ? 'client' : 'support',
					viewed: false,
					createdAt: firebase.firestore.FieldValue.serverTimestamp(),
				});
		} catch (error) {
			throw new Error('Error sending live chat message');
		}
	}

	async function getLiveChats() {
		try {
			const chats = await firestore.collection('liveChat').get();
			return chats.docs
				.filter(doc => doc.activity !== 'closed')
				.map(doc => {
					return { id: doc.id, ...doc.data() };
				});
		} catch (error) {
			throw new Error('Error getting live chats');
		}
	}

	function getLiveChatMessagesRef(chatID) {
		if (!chatID) return null;
		try {
			const chatRef = firestore.collection('liveChat').doc(chatID).collection('messages');
			return chatRef;
		} catch (error) {
			throw new Error('Error getting live chat');
		}
	}

	function getLiveChatRef(chatID) {
		try {
			const chatRef = firestore.collection('liveChat').doc(chatID);
			return chatRef;
		} catch (error) {
			throw new Error('Error getting live chat');
		}
	}

	async function closeLiveChat(chatID) {
		try {
			const chatRef = firestore.collection('liveChat').doc(chatID);
			const userRef = firestore.collection('users').doc(currentUser.uid);
			await userRef.update({ liveChat: null });
			await chatRef.update({ activity: 'closed' }, { merge: true });
		} catch (error) {
			throw new Error('Error closing live chat' + error);
		}
	}

	async function connectToLiveChat(supportID, chatID) {
		try {
			const chatRef = firestore.collection('liveChat').doc(chatID);
			await chatRef.update({
				supportID: supportID,
				activity: 'connected',
			});
		} catch (error) {
			throw new Error('Error connecting to live chat');
		}
	}

	const value = {
		// userCart,
		// userNotifications,
		userCreate,
		userInfoUpdate,
		userNotify,
		userViewNotification,
		userInfoGet,
		userSaveCard,
		userRemoveCard,
		userGetCards,
		userTicketAdd,
		userTicketRemove,
		userTicketsGet,
		userAddToCart,
		userRemoveFromCart,
		userGetCart,
		userPurchase,
		userEmptyCart,
		userGetProducts,
		startLiveChat,
		sendLiveChatMessage,
		getLiveChats,
		getLiveChatMessagesRef,
		getLiveChatRef,
		closeLiveChat,
		connectToLiveChat,
	};

	return <UserDBContext.Provider value={value}>{children}</UserDBContext.Provider>;
}
