import React, { useRef, useState } from 'react';
import { Form, Alert } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { useStates } from '../../contexts/StatesContext';

export default function Signup({ setter, switchSignUp }) {
	const { t } = useTranslation();
	const emailRef = useRef();
	const passwordRef = useRef();
	const { login } = useAuth();
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const { setPopUp } = useStates();

	async function handleSubmit(e) {
		e.preventDefault();

		try {
			setError(null);
			setLoading(true);
			await login(emailRef.current.value, passwordRef.current.value);
			setter(null);
			setPopUp(false);
		} catch (error) {
			setError('Log in failed. Please try again.');
		}
		setLoading(false);
	}

	return (
		<>
			<section className='popup'>
				<div onClick={() => setter(null)} className='bg' />
				<div className='signup-form' style={{ zIndex: 10 }}>
					<h4 className='form-font center'>{t('sign-in')}</h4>
					{error && <Alert variant='danger'>{error}</Alert>}
					<Form onSubmit={handleSubmit}>
						<div className='form-fields'>
							<Form.Group id='email'>
								<input className='form-field' type='email' ref={emailRef} placeholder={t('email')} />
							</Form.Group>
							<Form.Group id='password'>
								<input className='form-field' type='password' ref={passwordRef} placeholder={t('password')} />
							</Form.Group>
						</div>

						<div className='form-elements'>
							<div className='form-font'>
								<input type='checkbox' id='check' className='form-check-input' /> {t('remember-me')}
							</div>
							<Link onClick={() => setter(null)} className='form-link' to='/reset-password'>
								<i>{t('forgot-password')}</i>
							</Link>
						</div>
						<div className='form-elements'>
							<button disabled={loading} variant='primary' type='submit' className='button signin-button'>
								{t('sign-in')}
							</button>
							<button onClick={switchSignUp} className='button signup-button'>
								{t('sign-up')}
							</button>
						</div>
					</Form>
				</div>
			</section>
		</>
	);
}
