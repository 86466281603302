import React from 'react';
import { useStates } from '../../../../contexts/StatesContext';
import { useTranslation } from 'react-i18next';
export default function LoginSignup() {
  const { t } = useTranslation();
  const { popUp, setPopUp } = useStates();

  function handleSignInClick(e) {
    e.preventDefault();
    setPopUp('login');
  }

  function handleSignUpClick(e) {
    e.preventDefault();
    setPopUp('signup');
  }
  return (
    <>
      <div className='chat-body-start'>
        <h5 className='black center'>{t('seems-like')}</h5>
        <hr></hr>
        <div
          style={{
            paddingTop: '25%',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <h6 className='bold gray'>{t('please-sign-in')}</h6>
          <div style={{ display: 'inline-flex', justifyContent: 'space-between' }}>
            <button
              style={{ marginTop: '1rem', margin: '.5rem .5rem', padding: '0.5em 2em' }}
              onClick={handleSignInClick}
              className='signin-button'
            >
              {t('sign-in')}
            </button>
            <button
              onClick={handleSignUpClick}
              style={{ marginTop: '1rem', margin: '.5rem .5rem', padding: '0.5em 2em' }}
              className='signup-button'
            >
              {t('sign-up')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
