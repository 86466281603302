import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { motion } from 'framer-motion';
import curvefoot from '../../../assets/curvefootsmaller.svg';
import AwardWinningMouse from '../../../assets/awardwinningmouse.svg';
import { useTranslation } from 'react-i18next';

export default function AwardWinning() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  return (
    <>
      <section
        style={{
          direction: `${i18n.language === 'ar' ? 'rtl' : ''}`,
          textAlign: `${i18n.language === 'ar' ? 'right' : 'left'}`,
        }}
        className='awardwinning'
      >
        <div className='container'>
          <div className='row'>
            {/* <div className='space-sm' /> */}
            <motion.div
              initial='hidden'
              whileInView='visible'
              repeat='0'
              viewport={{ once: true, amount: 0.5 }}
              transition={{ repeat: 0, duration: 0.5 }}
              variants={{
                visible: { scale: 1 },
                hidden: { scale: 0 },
              }}
              className='col-md-6 col-sm-12'
            >
              <img width='100%' alt='' src={AwardWinningMouse} />
            </motion.div>
            <div className='col-md-6 col-sm-12'>
              <h1 className='bold black'>{t('awardwinning.title')}</h1>
              <p className='ser'>{t('awardwinning.desc')}</p>
              <section style={{ paddingBottom: '1rem', display: 'block' }}>
                <section>
                  <h6 className='bolder'>{t('award.mobile')}</h6>
                  <h6 className='bolder'>%95</h6>
                </section>
                <motion.div
                  initial={{ width: '0%' }}
                  whileInView={{ width: '95%' }}
                  transition={{ duration: 2 }}
                  className='per95'
                  viewport={{ once: true, amount: 0.5 }}
                ></motion.div>
              </section>
              <section style={{ paddingBottom: '1rem', display: 'block' }}>
                <section>
                  <h6 className='bolder'>{t('award.web')}</h6>
                  <h6 className='bolder'>%92</h6>
                </section>
                <motion.div
                  initial={{ width: '0%' }}
                  whileInView={{ width: '92%' }}
                  transition={{ delay: 0.2, duration: 2 }}
                  className='per92'
                  viewport={{ once: true, amount: 0.5 }}
                ></motion.div>
              </section>
              <section style={{ paddingBottom: '1rem', display: 'block' }}>
                <section>
                  <h6 className='bolder'>{t('award.ui')}</h6>
                  <h6 className='bolder'>%90</h6>
                </section>
                <motion.div
                  initial={{ width: '0%' }}
                  whileInView={{ width: '90%' }}
                  transition={{ delay: 0.4, duration: 2 }}
                  className='per90'
                  viewport={{ once: true, amount: 0.5 }}
                ></motion.div>
              </section>
            </div>
          </div>
        </div>
      </section>
      <img
        alt=''
        style={{ marginBottom: '30%', position: 'absolute' }}
        className='z'
        src={curvefoot}
      />
      <div className='curvefooot'></div>
    </>
  );
}
