import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import Cart from './Cart';

const PUBLIC_KEY = 'pk_test_51LRGPjAZ0oGAnUlco77wBoBxUgxUPABPcY8X4HS2mc3sg5VZJZb0rxGyparNRB0FUkO1dgGfmIxPZDTwUKFU6Jdd00Cvwis4iU';

const stripeTestPromise = loadStripe(PUBLIC_KEY);

export default function StripeContainer() {
	return (
		<Elements stripe={stripeTestPromise}>
			<Cart />
		</Elements>
	);
}
