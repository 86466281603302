import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useSiteData } from '../../../contexts/SiteDataContext';

export default function ListBlogs() {
	const { blogsGet, blogDelete } = useSiteData();

	const [blogs, setBlogs] = useState([]);

	function handleDelete(id) {
		blogDelete(id).then(getBlogs());
	}

	function getBlogs() {
		blogsGet().then(data => setBlogs(data));
	}

	useEffect(() => {
		getBlogs();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<>
			<section className='blue-bg'>
				<Link to='/cpanel' className='signup-button Link'>
					Return to cPanel
				</Link>
				<div className='space'></div>
				<div className='container'>
					<h1 className='center bolder blue'>Blogs</h1>
					<div className='row'>
						<Link to='/cpanel/blogs/' className='signup-button Link'>
							Create new blog
						</Link>
						<div className='col-md-12'>
							<table className='table center'>
								<thead>
									<tr>
										<th>
											<h5 className='bold'>Blog Title</h5>
										</th>
										<th>View</th>
										<th>Edit</th>
										<th>
											<button className='button-sm'>Delete</button>
										</th>
									</tr>
								</thead>
								<thead>
									{blogs &&
										blogs.map((blog, i) => (
											<tr>
												<th>
													<h5 className='bold'>{blog.title}</h5>
												</th>
												<th>
													<Link to={`/blogs/page/${blog.title}`} className='Link blue'>
														View
													</Link>
												</th>
												<th>
													<Link to={`/cpanel/updateblog/${blog._id}`} className='Link blue'>
														Edit
													</Link>
												</th>
												<th>
													<button
														onClick={e => {
															e.preventDefault();
															handleDelete(blog._id);
														}}
														className='button-sm'>
														Delete
													</button>
												</th>
											</tr>
										))}
								</thead>
							</table>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
