import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { Editor } from 'react-bootstrap-editor';

import { useSiteData } from '../../../contexts/SiteDataContext';

export default function UpdateBlog() {
	const { _id } = useParams();
	const { blogUpdate, blogGet } = useSiteData();
	const navigate = useNavigate();

	const titleRef = useRef();
	const contentRef = useRef();
	const ARtitleRef = useRef();
	const ARcontentRef = useRef();
	const TRtitleRef = useRef();
	const TRcontentRef = useRef();

	const [blog, setBlog] = useState(null);
	const [selectedImage, setSelectedImage] = useState(null);
	const [content, setContent] = useState(null);
	const [ARcontent, setARContent] = useState(null);
	const [TRcontent, setTRContent] = useState(null);
	const [img, setImg] = useState(null);

	function handleUpload(e) {
		setSelectedImage(e.target.files[0]);
	}

	function handleSubmit(e) {
		e.preventDefault();

		blogUpdate(
			_id,
			{
				...(titleRef.current.value && { title: titleRef.current.value }),
				...(content && { content: content }),
				...(ARtitleRef.current.value && { ARtitle: ARtitleRef.current.value }),
				...(ARcontent && { ARcontent: ARcontent }),
				...(TRtitleRef.current.value && { TRtitle: TRtitleRef.current.value }),
				...(TRcontent && { TRcontent: TRcontent }),
				...(selectedImage && { imageName: selectedImage.name }),
			},
			...(selectedImage && [selectedImage])
		).then(() => navigate('/cpanel/listblogs'));
	}

	useEffect(() => {
		blogGet(_id).then(data => setBlog(data));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (blog) {
			if (blog.title) titleRef.current.value = blog.title;
			if (blog.content) contentRef.current.box.current.innerHTML = blog.content;
			if (blog.ARtitle) ARtitleRef.current.value = blog.ARtitle;
			if (blog.ARcontent) ARcontentRef.current.box.current.innerHTML = blog.ARcontent;
			if (blog.TRtitle) TRtitleRef.current.value = blog.TRtitle;
			if (blog.TRcontent) TRcontentRef.current.box.current.innerHTML = blog.TRcontent;
			setImg(blog?.imageUrl);
		}
	}, [blog]);

	return (
		<>
			<section className='blue-bg'>
				<Link to='/cpanel' className='signup-button Link'>
					Return to cPanel
				</Link>
				<div className='space'></div>
				<div className='container'>
					<h1 className='center bolder blue'>Update Blog</h1>
					<Form onSubmit={handleSubmit}>
						<div className='row'>
							<div className='col-lg-6 col-sm-12 center'>
								<h2 className='Bold black'>Title</h2>
								<div className='space-sm'></div>
								<input ref={titleRef} type='text' className='form-control' />
								<div className='space'></div>
							</div>
							<div className='col-lg-6 col-sm-12 center'>
								<h2 className='Bold black'>Title Arabic</h2>
								<div className='space-sm'></div>
								<input ref={ARtitleRef} type='text' className='form-control' />
								<div className='space'></div>
							</div>
							<div className='col-lg-6 col-sm-12 center'>
								<h2 className='Bold black'>Title Turkish</h2>
								<div className='space-sm'></div>
								<input ref={TRtitleRef} type='text' className='form-control' />
								<div className='space'></div>
							</div>
							<div className='col-lg-6 col-sm-12 center'>
								<h2 className='Bold black'>Content</h2>
								<div className='space-sm'></div>
								{/* <textarea ref={contentRef} className='form-control' rows='10' /> */}
								<Editor
									ref={contentRef}
									onChange={text => {
										setContent(text);
									}}
								/>
								<div className='space'></div>
							</div>
							<div className='col-lg-6 col-sm-12 center'>
								<h2 className='Bold black'>Content Arabic</h2>
								<div className='space-sm'></div>
								{/* <textarea ref={contentRef} className='form-control' rows='10' /> */}
								<Editor
									ref={ARcontentRef}
									onChange={text => {
										setARContent(text);
									}}
								/>
								<div className='space'></div>
							</div>
							<div className='col-lg-6 col-sm-12 center'>
								<h2 className='Bold black'>Content Turkish</h2>
								<div className='space-sm'></div>
								{/* <textarea ref={contentRef} className='form-control' rows='10' /> */}
								<Editor
									ref={TRcontentRef}
									onChange={text => {
										setTRContent(text);
									}}
								/>
								<div className='space'></div>
							</div>
							<div className='col-lg-6 col-sm-12 center'>
								<h2 className='Bold black'>Image</h2>
								<div className='space-sm'></div>
								{selectedImage ? (
									<>
										<img src={URL.createObjectURL(selectedImage)} style={{ height: '50%', width: '30%' }} alt='' />
										<button onClick={() => setSelectedImage(null)}>Remove</button>
									</>
								) : img ? (
									<>
										<img src={img} style={{ height: '50%', width: '30%' }} alt='' />
										<button onClick={() => setImg(null)}>Remove</button>
									</>
								) : (
									<input onChange={handleUpload} type='file' id='img' name='img' accept='image/*' />
								)}

								<div className='space'></div>
							</div>
							<button type='submit' className='btn btn-primary'>
								Update
							</button>
						</div>
					</Form>
				</div>
			</section>
		</>
	);
}
