import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useSiteData } from '../../../contexts/SiteDataContext';
export default function ListProjects() {
	const { projectsGet, projectDelete } = useSiteData();

	const [projects, setProjects] = useState([]);

	function handleDelete(id) {
		projectDelete(id).then(getProjects());
	}

	function getProjects() {
		projectsGet().then(data => setProjects(data));
	}

	useEffect(() => {
		getProjects();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<>
			<section className='blue-bg'>
				<Link to='/cpanel' className='signup-button Link'>
					Return to cPanel
				</Link>
				<div className='space'></div>
				<div className='container'>
					<h1 className='center bolder blue'>Projects</h1>
					<div className='row'>
						<Link to='/cpanel/createproject' className='signup-button Link'>
							Create new project
						</Link>
						<div className='col-md-12'>
							<table className='table center'>
								<thead>
									<tr>
										<th>
											<h5 className='bold'>Project Title</h5>
										</th>
										<th>View</th>
										<th>Edit</th>
										<th>
											<button className='button-sm'>Delete</button>
										</th>
									</tr>
								</thead>
								<thead>
									{projects &&
										projects.map((project, i) => (
											<tr>
												<th>
													<h5 className='bold'>{project.title}</h5>
												</th>
												<th>
													<Link to={`/projects/page/${project.title}`} className='Link blue'>
														View
													</Link>
												</th>
												<th>
													<Link to={`/cpanel/updateproject/${project._id}`} className='Link blue'>
														Edit
													</Link>
												</th>
												<th>
													<button
														onClick={e => {
															e.preventDefault();
															handleDelete(project._id);
														}}
														className='button-sm'>
														Delete
													</button>
												</th>
											</tr>
										))}
								</thead>
							</table>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
