import React, { useContext } from 'react';
import { firestore } from '../lib/Firebase';
import { useAuth } from './AuthContext';
import { useSupportDB } from './SupportDBContext';
import { useUserDB } from './UserDBContext';
import sendEmail from '../utils/sendMail';

const TicketDBContext = React.createContext();

export function useTicketDB() {
	return useContext(TicketDBContext);
}

function TicketIdGen() {
	var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
	let firstId = '';
	for (let i = 0; i < 3; i++) {
		let rand = characters.charAt(Math.floor(Math.random() * characters.length));
		firstId += rand.toString();
	}
	const secondId = Math.floor(Math.floor(Math.random() * 999));
	const thirdId = Math.floor(Math.floor(Math.random() * 99999));
	return firstId + '-' + secondId + '-' + thirdId;
}

export default function TicketDBProvider({ children }) {
	const { currentUser } = useAuth();
	const { supportUserNotify, supportUserGet } = useSupportDB();
	const { userNotify, userInfoGet } = useUserDB();

	async function ticketCreate({ title, messages, clientID, department }) {
		try {
			const ticketRef = firestore.collection('tickets');
			const ticket = await ticketRef.add({
				title,
				messages,
				clientID,
				department,
				status: true,
				createdAt: new Date(),
				ticketCustomID: TicketIdGen(),
			});
			return ticket;
		} catch (error) {
			throw new Error('Error creating ticket');
		}
	}

	async function ticketAccept(ticketID, supportID) {
		try {
			const ticketRef = firestore.collection('tickets').doc(ticketID);
			await ticketRef.update(
				{
					supportID,
					updatedAt: new Date(),
				},
				{ merge: true }
			);
		} catch (error) {
			throw new Error('Error accepting ticket');
		}
	}

	async function ticketMessage({ ticketID, messageSubject, messageDescription }) {
		try {
			const ticketRef = firestore.collection('tickets').doc(ticketID);
			const ticket = await ticketRef.get();
			if (currentUser.uid !== ticket.data().clientID && currentUser.uid !== ticket.data().supportID) {
				console.log('You are not authorized to send messages to this ticket');
				throw new Error('You are not authorized to send messages to this ticket');
			}

			if (ticket.data().status === 'closed') return console.log('This ticket is closed');

			await ticketRef.set(
				{
					messages: [
						...ticket.data().messages,
						{
							messageSubject,
							messageDescription,
							sender: currentUser.uid === ticket.data().clientID ? 'client' : 'support',
							createdAt: new Date(),
						},
					],
					updatedAt: new Date(),
				},
				{ merge: true }
			);
			try {
				if (currentUser.uid === ticket.data().clientID) {
					supportUserNotify({
						title: 'A ticket has been updated',
						message: `'${ticket.data().title}', has been updated.`,
					});
				} else if (currentUser.uid === ticket.data().supportID) {
					userNotify({
						title: 'Your ticket has been updated',
						message: `Your ticket, '${ticket.data().title}', has been updated.`,
					});
					sendEmail(ticket.data().clientID, 'Your ticket has been updated', {
						title: 'Your ticket has been updated',
						text: `Your ticket, <b>'${ticket.data().title}'<b>, has been updated.`,
						buttonText: 'View ticket',
						buttonLink: `/ticket-page/${ticketID}`,
					});
				}
			} catch (error) {
				throw new Error(error.message);
			}
		} catch (error) {
			throw new Error('Error sending message', error.message);
		}
	}

	async function ticketGet(ticketID) {
		try {
			const ticketRef = firestore.collection('tickets').doc(ticketID);
			const ticket = await ticketRef.get();
			const ticketData = ticket.data();
			ticketData.clientInfo = await userInfoGet(ticketData.clientID);
			if (ticket.supportID) ticketData.supportInfo = await supportUserGet(ticketData.supportID);
			return ticketData;
		} catch (error) {
			throw new Error('Error getting ticket');
		}
	}

	// TODO: Autoclosse
	async function ticketClose(ticketID) {
		try {
			const ticketRef = firestore.collection('tickets').doc(ticketID);
			await ticketRef.update(
				{
					status: 'closed',
					updatedAt: new Date(),
				},
				{ merge: true }
			);
		} catch (error) {
			throw new Error('Error closing ticket');
		}
	}

	async function ticketsGet() {
		try {
			const ticketsRef = firestore.collection('tickets');
			const tickets = await ticketsRef.get();
			return tickets.docs.map(ticket => {
				const ticketData = ticket.data();
				ticketData.id = ticket.id;
				return ticketData;
			});
		} catch (error) {
			throw new Error('Error getting tickets');
		}
	}

	const value = {
		ticketCreate,
		ticketAccept,
		ticketMessage,
		ticketClose,
		ticketGet,
		ticketsGet,
	};
	return <TicketDBContext.Provider value={value}>{children}</TicketDBContext.Provider>;
}
