import React, { useEffect, useRef } from 'react';
import header1 from '../assets/aboutheader1.svg';
import header2 from '../assets/aboutheader2.svg';
import background from '../assets/sinebg.svg';
import yearbadge from '../assets/yearbadge.svg';
import happybadge from '../assets/happy.svg';
import projectbadge from '../assets/projectdone.svg';
import contract from '../assets/contract.svg';
import Header from '../components/ui/Header';
import { motion, animate } from 'framer-motion';
import { useTranslation } from 'react-i18next';
function Counter({ from, to }) {
  const nodeRef = useRef();

  useEffect(() => {
    const node = nodeRef.current;

    const controls = animate(from, to, {
      duration: 1,
      onUpdate(value) {
        node.textContent = value.toFixed(0);
      },
    });

    return () => controls.stop();
  }, [from, to]);

  return <h4 className='quick bold black' ref={nodeRef} />;
}

export default function Index() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  return (
    <>
      <section className='about-us-container'>
        <Header
          img1={header1}
          img2={header2}
          title={t('about.header.title')}
          desc={t('about.header.desc')}
        />
        <h1 style={{ marginTop: '1rem' }} className='bolder black'>
          {t('about.title')}
        </h1>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-1' />
            <div
              style={{
                direction: `${i18n.language === 'ar' ? 'rtl' : ''}`,
                textAlign: `${i18n.language === 'ar' ? 'right' : 'left'}`,
              }}
              className='col-lg-6 col-md-6 col-sm-12 '
            >
              <h4 style={{ maxHeight: 'max-content' }} className='bold-ser black'>
                <br />
                {t('about.1.title')}
              </h4>
              <p className='gray'>{t('about.1.desc1')}</p>
              <p className='gray'>{t('about.1.desc2')}</p>
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12 pad-one'>
              <img alt='' className='about-us-img ' />
            </div>
          </div>
        </div>
        <img alt='' src={background} className='section-head' />
        <div className='about-section-two'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-1' />
              <div className='col-lg-4 col-md-6 col-sm-12 pad-one'>
                <img alt='' className='about-us-img' />
              </div>
              <div
                style={{
                  direction: `${i18n.language === 'ar' ? 'rtl' : ''}`,
                  textAlign: `${i18n.language === 'ar' ? 'right' : 'left'}`,
                }}
                className='col-lg-6 col-md-6 col-sm-12 '
              >
                <h4 className='bold-ser black'>{t('about.2.title')}</h4>
                <p className='gray'>{t('about.2.desc1')}</p>
                <p className='gray'>{t('about.2.desc2')}</p>
                <p className='gray'>{t('about.2.desc3')}</p>
                <p className='gray'>{t('about.2.desc4')}</p>
                <p className='gray'>{t('about.2.desc5')}</p>
              </div>
              <div
                style={{
                  direction: `${i18n.language === 'ar' ? 'rtl' : ''}`,
                  textAlign: `${i18n.language === 'ar' ? 'right' : 'left'}`,
                }}
                className='col-12'
              >
                <h4 className='bold-ser black'>{t('about.3.title')}</h4>
                <p className='gray'>{t('about.3.desc1')}</p>
              </div>
              {/* <div className='col-12'> */}
              <div className='container'>
                <div className='row'>
                  <div className='col-xl-3 col-lg-6 col-md-6 col-sm-12 line-border'>
                    <div className='stats-container'>
                      <img alt='' className='stats-img' src={yearbadge} />
                      <div className='stats-text'>
                        <h4 className='quick bold black'>
                          <div className='flex'>
                            <Counter from={0} to={14} /> +
                          </div>
                        </h4>
                        <p className='gray'>{t('about.years')}</p>
                      </div>
                    </div>
                  </div>
                  <div className='col-xl-3 col-lg-6 col-md-6 col-sm-12 line-border'>
                    <div className='stats-container'>
                      <img alt='' className='stats-img' src={happybadge} />
                      <div className='stats-text'>
                        <h4 className='quick bold black'>
                          <div className='flex'>
                            <Counter from={0} to={138} /> +
                          </div>
                        </h4>
                        <p className='gray'>{t('about.clients')}</p>
                      </div>
                    </div>
                  </div>
                  <div className='col-xl-3 col-lg-6 col-md-6 col-sm-12 line-border'>
                    <div className='stats-container'>
                      <img alt='' className='stats-img' src={projectbadge} />
                      <div className='stats-text'>
                        <h4 className='quick bold black'>
                          <div className='flex'>
                            <Counter from={0} to={280} /> +
                          </div>
                        </h4>
                        <p className='gray'>{t('about.projects')}</p>
                      </div>
                    </div>
                  </div>
                  <div className='col-xl-3 col-lg-6 col-md-6 col-sm-12 line-border'>
                    <div className='stats-container'>
                      <img alt='' className='stats-img' src={contract} />
                      <div className='stats-text'>
                        <h4 className='quick bold black'>
                          <div className='flex'>
                            <Counter from={0} to={100} /> +
                          </div>
                        </h4>
                        <p className='gray'>{t('about.contracts')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='space-sm' />
        {/* </div> */}
        <div
          style={{
            direction: `${i18n.language === 'ar' ? 'rtl' : ''}`,
            textAlign: `${i18n.language === 'ar' ? 'right' : 'left'}`,
          }}
          className='container'
        >
          <div className='space' />
          <div className='row'>
            <div className='col-12 '>
              <h4 className='bold-ser black'>{t('about.4.title')}</h4>
              <p className='gray'>{t('about.4.desc1')}</p>
            </div>
            <div className='col-12 '>
              <h4 className='bold-ser black'>
                <br />
                {t('about.5.title')}
              </h4>
              <p className='gray'>{t('about.5.desc1')}</p>
            </div>
            <div className='col-12 '>
              <h4 className='bold-ser black'>
                <br />
                {t('about.6.title')}
              </h4>
              <p className='gray'>{t('about.6.desc1')}</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
