import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTicketDB } from '../../../contexts/TicketDBContext';
import { useAuth } from '../../../contexts/AuthContext';

export default function MyItems() {
	const { ticketsGet, ticketAccept } = useTicketDB();
	const { currentUser } = useAuth();
	const [tickets, setTickets] = useState([]);

	function handleAccept(ticketID) {
		ticketAccept(ticketID, currentUser.uid).then(getTickets());
	}

	function getTickets() {
		ticketsGet()
			.then(tickets => setTickets(tickets))
			.catch(err => console.log(err));
	}

	useEffect(() => {
		getTickets();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className='col-xl-8 col-lg-12 col-md-12 col-sm-12'>
				<div className='space'></div>
				<h4 className='semibold gray'>My Tickets</h4>
				<div className='space-sm'></div>
				<div className='options'>
					<select className='form-field-select' id='Sort' name='Sort By'>
						<option value=''>Sort By</option>
						<option value='new'>From newest to oldest</option>
						<option value='old'>From oldest to newest</option>
						<option value='high'>Price : High to Low</option>
						<option value='low'>Price : Low to High</option>
					</select>
					<select className='form-field-select' id='Filter' name='Filter'>
						<option value=''>Filter</option>
						<option value='Expiration'>Expiration</option>
						<option value=''>Category</option>
						<option value=''>Category</option>
						<option value=''>Category</option>
					</select>
				</div>
				<div className='space-sm'></div>
				<table className='table'>
					<tr>
						<th>Title</th>
						<th>Department</th>
						<th>Created</th>
						<th>Message</th>
						<th></th>
					</tr>
					{tickets.map(ticket => {
						return ticket.supportID ? null : (
							<tr>
								<td>{ticket.title}</td>
								<td>{ticket.department}</td>
								<td>{Math.floor((Date.now() - ticket.createdAt?.toDate()) / (1000 * 3600 * 24))} days ago</td>
								<td>
									<Link to={`/support/view-message/${ticket.id}`}>View message</Link>
								</td>
								<td>
									<button
										onClick={e => {
											e.preventDefault();
											handleAccept(ticket.id);
										}}>
										Accept
									</button>
								</td>
							</tr>
						);
					})}
				</table>
			</div>
		</>
	);
}
