import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { useSiteData } from '../../../contexts/SiteDataContext';

export default function PostBlog() {
	const { premiumDomainAdd } = useSiteData();
	const navigate = useNavigate();

	const domainRef = useRef();
	const priceRef = useRef();
	const renewPriceRef = useRef();

	async function handleSubmit(e) {
		e.preventDefault();
		await premiumDomainAdd(domainRef.current.value, priceRef.current.value, renewPriceRef.current.value);
		navigate('/cpanel/listdomains');
	}

	return (
		<>
			<section className='blue-bg'>
				<Link to='/cpanel' className='signup-button Link'>
					Return to cPanel
				</Link>
				<div className='space'></div>
				<div className='container'>
					<h1 className='center bolder blue'>Add Domain</h1>
					<Form onSubmit={handleSubmit}>
						<div className='row'>
							<div className='col-lg-6 col-sm-12 center'>
								<h2 className='Bold black'>Domain</h2>
								<div className='space-sm'></div>
								<input ref={domainRef} type='text' className='form-control' />
								<div className='space'></div>
							</div>
							<div className='col-lg-6 col-sm-12 center'>
								<h2 className='Bold black'>Price</h2>
								<div className='space-sm'></div>
								<input ref={priceRef} type='text' className='form-control' />
								<div className='space'></div>
							</div>
							<div className='col-lg-6 col-sm-12 center'>
								<h2 className='Bold black'>Renew Price</h2>
								<div className='space-sm'></div>
								<input ref={renewPriceRef} type='text' className='form-control' />
								<div className='space'></div>
							</div>
							<button type='submit' className='btn btn-primary'>
								Post
							</button>
						</div>
					</Form>
				</div>
			</section>
		</>
	);
}
