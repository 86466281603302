import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

import './App.css';
import { AuthProvider } from './contexts/AuthContext';
import { StatesProvider } from './contexts/StatesContext';
import { DBProvider } from './contexts/DBContext';
import { SiteDataProvider } from './contexts/SiteDataContext';

import PrivateRoute from './utils/PrivateRoute';
import Test from './pages/Test';
import Home from './pages/Home';
import ResetPassword from './pages/ResetPassword';
import Profile from './pages/Profile';
import Error404 from './pages/Errors/Error404';

import SupportLogin from './pages/Support/pages/SupportLogin';
import Support from './pages/Support/';

import Navbar from './components/ui/Navbar';
import Footer from './components/ui/Footer';
import AboutUs from './pages/AboutUs';
import WebsiteDesign from './pages/WebsiteDesign';
import AppDesign from './pages/AppDesign';
import Hosting from './pages/Hosting/Hosting';
import Blogs from './pages/Blogs/Blogs';
import Projects from './pages/Projects/Projects';
import AppOffer from './pages/AppOffer';
import SiteOffer from './pages/SiteOffer';
import PlanPage from './pages/Hosting/PlanPage';
import HostingPrices from './pages/Hosting/HostingPrices';
import Domains from './pages/Domains';
import BlogPage from './pages/Blogs/BlogPage';
import ProjectPage from './pages/Projects/ProjectPage';
import LiveChat from './components/ui/LiveChat';
import CPanelHome from './pages/cPanel/Panel/CPanelHome';
import PostBlog from './pages/cPanel/Panel/PostBlog';
import ListBlogs from './pages/cPanel/Panel/ListBlogs';
import ListProjects from './pages/cPanel/Panel/ListProjects';
import ListPlanTypes from './pages/cPanel/Panel/ListPlanTypes';
import ListPlans from './pages/cPanel/Panel/ListPlans';
import UpdateBlog from './pages/cPanel/Panel/UpdateBlog';
import PostProject from './pages/cPanel/Panel/PostProject';
import UpdateProject from './pages/cPanel/Panel/UpdateProject';
import PostPlan from './pages/cPanel/Panel/PostPlan';
import PostPlanAdd from './pages/cPanel/Panel/PostPlanAdd';
import ListDomains from './pages/cPanel/Panel/ListDomains';
import PostDomain from './pages/cPanel/Panel/PostDomain';
import UpdateDomain from './pages/cPanel/Panel/UpdateDomain';
import Privacypolicy from './pages/Privacypolicy';

import PaymentWraper from './pages/PaymentWraper';
import Cookie from './components/popups/Cookie';
import Cart from './pages/Cart';
import Contact from './pages/Contact';
import { t } from 'i18next';
function App() {
	return (
		<>
			<AuthProvider>
				<StatesProvider>
					<SiteDataProvider>
						<DBProvider>
							<Router>
								<Navbar />
								<Routes>
									<Route path='/' element={<Home />} />
									<Route path='/blogs/page/:_id' element={<BlogPage />} />
									<Route path='/test' element={<Test />} />
									<Route path='/reset-password' element={<ResetPassword />} />
									<Route path='/about-us' element={<AboutUs />} />
									<Route path='/websites' element={<WebsiteDesign />} />
									<Route path='/applications' element={<AppDesign />} />
									<Route path='/blogs' element={<Blogs />} />
									<Route path='/applications/offer' element={<AppOffer />} />
									<Route path='websites/offer' element={<SiteOffer />} />
									<Route path='/domains' element={<Domains />} />
									<Route path='/hosting' element={<Hosting />} />
									<Route path='/privacy-policy' element={<Privacypolicy />} />
									<Route path='/projects/page/:_id' element={<ProjectPage />} />
									<Route path='/projects' element={<Projects />} />
									<Route path='/contact-us' element={<Contact />} />

									<Route path='/hosting/home-page/plan/:index' element={<PlanPage type='Home Page' />} />
									<Route path='/hosting/vps-hosting/plan/:index' element={<PlanPage type='VPS Hosting' />} />
									<Route path='/hosting/shared-hosting' element={<HostingPrices type='Shared Hosting' />} />
									<Route path='/hosting/shared-hosting/plan/:index' element={<PlanPage type='Shared Hosting' />} />
									<Route path='/hosting/reseller-hosting' element={<HostingPrices type='Reseller Hosting' />} />
									<Route path='/hosting/reseller-hosting/plan/:index' element={<PlanPage type='Reseller Hosting' />} />
									<Route path='/hosting/wordpress-hosting' element={<HostingPrices type='Wordpress Hosting' />} />
									<Route
										path='/hosting/wordpress-hosting/plan/:index'
										element={<PlanPage type='Wordpress Hosting' />}
									/>
									<Route path='/hosting/email-hosting' element={<HostingPrices type='Email Hosting' />} />
									<Route path='/hosting/email-hosting/plan/:index' element={<PlanPage type='Email Hosting' />} />
									<Route path='/hosting/vps-hosting' element={<HostingPrices type='Vps Hosting' />} />
									<Route path='/hosting/vps-hosting/plan/:index' element={<PlanPage type='Vps Hosting' />} />
									<Route path='/hosting/dedicated-hosting' element={<HostingPrices type='Dedicated Hosting' />} />
									<Route
										path='/hosting/dedicated-hosting/plan/:index'
										element={<PlanPage type='Dedicated Hosting' />}
									/>

									<Route path='/profile' element={<Profile name='profile' />} />
									<Route path='/change-password' element={<Profile name='change-password' />} />
									<Route path='/my-tickets' element={<Profile name='tickets' mouse='true' />} />
									<Route path='/submit-ticket' element={<Profile name='submit-ticket' mouse='true' />} />
									<Route path='/ticket-page' element={<Profile name='my-ticket' mouse='true' />} />
									<Route path='/ticket-page/:ticketID' element={<Profile name='my-ticket' mouse='true' />} />
									<Route path='/my-items' element={<Profile name='my-items' />} />
									<Route path='/my-cart' element={<PaymentWraper />} />

									<Route path='/support/login' element={<SupportLogin />} />
									<Route path='/support' element={<Support name='Support' />} />
									<Route path='/support/change-password' element={<Support name='Change Password' />} />
									<Route path='/support/active-tickets' element={<Support name='Tickets' />} />
									<Route path='/support/availible-tickets' element={<Support name='Availible Tickets' />} />
									<Route path='/support/view-message/:ticketID' element={<Support name='View Message' />} />
									<Route path='/support/ticket-page/:ticketID' element={<Support name='My Ticket' />} />
									<Route path='/support/activechats' element={<Support name='Active Chats' />} />
									<Route path='/support/chatpage/:chatID' element={<Support name='Chat' />} />

									<Route
										path='/cpanel'
										element={
											<PrivateRoute goto='/' roles={['admin']}>
												<CPanelHome />
											</PrivateRoute>
										}
									/>
									<Route
										path='/cpanel/blogs'
										element={
											<PrivateRoute goto='/' roles={['admin']}>
												<PostBlog />
											</PrivateRoute>
										}
									/>
									<Route
										path='/cpanel/listblogs'
										element={
											<PrivateRoute goto='/' roles={['admin']}>
												<ListBlogs />
											</PrivateRoute>
										}
									/>
									<Route
										path='/cpanel/updateblog/:_id'
										element={
											<PrivateRoute goto='/' roles={['admin']}>
												<UpdateBlog />
											</PrivateRoute>
										}
									/>
									<Route
										path='/cpanel/createproject'
										element={
											<PrivateRoute goto='/' roles={['admin']}>
												<PostProject />
											</PrivateRoute>
										}
									/>
									<Route
										path='/cpanel/listprojects'
										element={
											<PrivateRoute goto='/' roles={['admin']}>
												<ListProjects />
											</PrivateRoute>
										}
									/>
									<Route
										path='/cpanel/updateproject/:_id'
										element={
											<PrivateRoute goto='/' roles={['admin']}>
												<UpdateProject />
											</PrivateRoute>
										}
									/>
									<Route
										path='/cpanel/postplanupdate/:type/:index'
										element={
											<PrivateRoute goto='/' roles={['admin']}>
												<PostPlan />
											</PrivateRoute>
										}
									/>
									<Route
										path='/cpanel/listplans/:type'
										element={
											<PrivateRoute goto='/' roles={['admin']}>
												<ListPlans />
											</PrivateRoute>
										}
									/>
									<Route
										path='/cpanel/listplantypes'
										element={
											<PrivateRoute goto='/' roles={['admin']}>
												<ListPlanTypes />
											</PrivateRoute>
										}
									/>
									<Route
										path='/cpanel/addplan/:type'
										element={
											<PrivateRoute goto='/' roles={['admin']}>
												<PostPlanAdd />
											</PrivateRoute>
										}
									/>
									<Route
										path='/cpanel/listdomains'
										element={
											<PrivateRoute goto='/' roles={['admin']}>
												<ListDomains />
											</PrivateRoute>
										}
									/>
									<Route
										path='/cpanel/postdomain'
										element={
											<PrivateRoute goto='/' roles={['admin']}>
												<PostDomain />
											</PrivateRoute>
										}
									/>
									<Route
										path='/cpanel/updatedomain/:_id'
										element={
											<PrivateRoute goto='/' roles={['admin']}>
												<UpdateDomain />
											</PrivateRoute>
										}
									/>

									<Route path='*' element={<Error404 />} />
								</Routes>
								<LiveChat />
								<Footer />
							</Router>
						</DBProvider>
					</SiteDataProvider>
				</StatesProvider>
			</AuthProvider>
		</>
	);
}

export default App;
