import React, { useContext } from 'react';
import { firestore } from '../lib/Firebase';
import { useAuth } from './AuthContext';

const SupportDBContext = React.createContext();

export function useSupportDB() {
	return useContext(SupportDBContext);
}

export default function SupportDBProvider({ children }) {
	const { signup } = useAuth();

	async function supportUserCreate({ email, password, userName, firstName, lastName }) {
		try {
			const user = await signup(email, password);
			const userRef = firestore.collection('supportUsers').doc(user.user.uid);
			await userRef.set({
				userName,
				firstName,
				lastName,
				email,
				notifications: [],
				tickets: [],
				createdAt: new Date(),
			});
		} catch (error) {
			throw new Error('Support signup failed');
		}
	}

	async function supportUserGet(userID) {
		try {
			const userRef = firestore.collection('supportUsers').doc(userID + '/');
			const user = await userRef.get();
			return user.data();
		} catch (error) {
			throw new Error('Support user get failed');
		}
	}

	async function supportUserNotify({ title, message, userId }) {
		try {
			const userRef = firestore.collection('supportUsers').doc(userId + '/');
			const user = await userRef.get();
			await userRef.set(
				{ notifications: [...user.data().notifications, { title, message, viewed: false, createdAt: new Date() }] },
				{ merge: true }
			);
		} catch (error) {
			throw new Error('Support user notify failed');
		}
	}

	const value = {
		supportUserCreate,
		supportUserGet,
		supportUserNotify,
	};

	return <SupportDBContext.Provider value={value}>{children}</SupportDBContext.Provider>;
}
