import React from 'react';
import Header from '../components/ui/Header';
import { useTranslation } from 'react-i18next';
export default function Contact() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  return (
    <>
      <Header title={t('contact.header.title')} desc={t('contact.header.desc')} arrow={true} />
      <section
        style={{
          direction: `${i18n.language === 'ar' ? 'rtl' : ''}`,
        }}
      >
        <div className='container'>
          <div className='row'>
            {/* <div className='col-lg-8 col-sm-12'>
              <iframe
                style={{ borderRadius: '1rem', border: '0.01rem solid #707070' }}
                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6020.541655971179!2d28.638204659946393!3d41.01933043289628!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b55f0e439aa0bf%3A0xd7e78df60f5d4c68!2zQXRhdMO8cmssIEVrxZ9pb8SfbHUgU2l0ZXNpLCAzNDUyMiBFc2VueXVydC_EsHN0YW5idWw!5e0!3m2!1sen!2str!4v1659366686217!5m2!1sen!2str'
                width='100%'
                height='450'
                allowfullscreen=''
                referrerpolicy='no-referrer-when-downgrade'
              ></iframe>
            </div>
            <div className='col-lg-4 col-sm-12'>
              <h4 className='bold black'>Contact information:</h4>
              <p className='black'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
              </p>
              <p className='bold black'>+905387334686</p>
              <p className='bold black'>contact@whynottech.com </p>
            </div> */}
            <div className='col-lg-12'>
              <div style={{ padding: '0 10%' }}>
                <div className='space'></div>
                <h2 className='bold black center'>{t('contact.title')}</h2>
                <p className='black center'>{t('contact.desc')}</p>
                <form>
                  <div style={{ paddingBottom: '1rem' }} className='row'>
                    <div className='col-md-6 col-xs-12'>
                      <label className='boldless-ser black'>{t('firstname')}:</label>
                      <input type='text' name='first_name' className='cart-input' />
                    </div>
                    <div className='col-md-6 col-xs-12'>
                      <label className='boldless-ser black'>{t('lastname')}:</label>
                      <input type='text' name='last_name' className='cart-input' />
                    </div>
                  </div>
                  <div style={{ paddingBottom: '1rem' }} className='row'>
                    <div className='col-md-6 col-xs-12'>
                      <label className='boldless-ser black'>{t('email')}:</label>
                      <input type='text' name='first_name' className='cart-input' />
                    </div>
                    <div className='col-md-6 col-xs-12'>
                      <label className='boldless-ser black'>{t('phone')}:</label>
                      <input type='text' name='last_name' className='cart-input' />
                    </div>
                  </div>
                  <div style={{ paddingBottom: '1rem' }} className='form-group'>
                    <div className='col-md-12'>
                      <label className='boldless-ser black'>Message:</label>
                    </div>
                    <div className='col-md-12'>
                      <textarea type='text' name='message' className='message-textarea' />
                    </div>
                  </div>
                  <div className='col-md-12 center'>
                    <button className='signin-button'>{t('send')}</button>
                  </div>
                </form>
                <div className='space-sm'></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
