import axios from 'axios';
import qs from 'qs';

async function serverApi(method, endpoint, params) {
	const data = qs.stringify(params);

	return axios({
		method,
		url: 'https://test.whynot-tech.com/api' + endpoint,
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
		data,
	})
		.then(response => response.data)
		.catch(error => error);
}

export default serverApi;
