import { React, useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

export default function Service(props) {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const [isHovered, setHovered] = useState(false);
  return (
    <>
      <motion.div
        whileHover={{ scale: 1.1 }}
        onHoverStart={(e) => {}}
        onHoverEnd={(e) => {}}
        transition={{ duration: 0.2 }}
        className='card-container'
      >
        <motion.div
          initial={{ y: -30, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 1 }}
          className='service-box'
        >
          <img alt='' src={props.img} />
          <h5 className='bold-ser'>{props.Title}</h5>
          <p
            style={{
              direction: `${i18n.language === 'ar' ? 'rtl' : ''}`,
            }}
            className='ser gray'
          >
            {props.desc}
          </p>
          <Link
            style={{ display: 'inline-flex', direction: `${i18n.language === 'ar' ? 'rtl' : ''}` }}
            className='bold orange Link'
            to={props.link}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            {t('read-more')}{' '}
            <div
              style={{
                transform: `${i18n.language === 'ar' ? 'scaleX(-1)' : ''}`,
              }}
            >
              <motion.div
                animate={{ x: isHovered ? 5 : 0 }}
                initial={{ x: 0 }}
                transition={{
                  repeat: isHovered ? Infinity : 0,
                  repeatType: 'reverse',
                  duration: 0.3,
                }}
              >
                <i className='bi bi-arrow-right arrow'></i>
              </motion.div>
            </div>
          </Link>
        </motion.div>
      </motion.div>
    </>
  );
}
