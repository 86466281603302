import React, { useEffect, useState } from 'react';
import Header from '../../components/ui/Header';
import Project from '../../components/cards/Project';
import background from '../../assets/sinebg.svg';

import { useSiteData } from '../../contexts/SiteDataContext';
import { useTranslation } from 'react-i18next';

export default function Projects() {
	const { t } = useTranslation();
	const { projectsGet } = useSiteData();

	const [projects, setProjects] = useState([]);
	const [view, setView] = useState('All');

	useEffect(() => {
		projectsGet().then(data => setProjects(data));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Header title={t('projects')} desc={t('projects.desc')} arrow={true} />
			<section className='pricing'>
				<h1 className='bold-ser black'>{t('projects.title')}</h1>
				<h6 className='ser gray'>{t('projects.desc')}</h6>
				<div className='plan-selection-container'>
					<div className='plan-selection'>
						<div className='left-b'>
							<button onClick={() => setView('All')} className={`bold ${view === 'All' ? 'active-b' : ''}`}>
								{t('all')}
							</button>
						</div>
						<div className='buttons-center'>
							<button
								onClick={() => setView('Mobile Applications')}
								className={`bold ${view === 'Mobile Applications' ? 'active-b' : ''}`}>
								{t('mobile-applications')}
							</button>
						</div>
						<div className='buttons-center'>
							<button onClick={() => setView('Websites')} className={`bold ${view === 'Websites' ? 'active-b' : ''}`}>
								{t('websites')}
							</button>
						</div>
						<div className='right-b'>
							<button
								onClick={() => setView('Motion Graphics')}
								className={`bold ${view === 'Motion Graphics' ? 'active-b' : ''}`}>
								{t('motion-graphics')}
							</button>
						</div>
					</div>
				</div>

				<div className='container'>
					<div className='row'>
						{projects &&
							projects
								.filter(project => view === 'All' || project.type === view)
								.map(project => (
									<div className='col-lg-3 col-md-4 col-s-12'>
										<Project link={`/projects/page/${project._id}`} img={project.imageUrls[0]} />
									</div>
								))}
					</div>
				</div>
			</section>
			<img alt='' src={background} className='section-foot' />
			<div className='space'></div>
		</>
	);
}
