import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useSiteData } from '../../../contexts/SiteDataContext';

export default function ListBlogs() {
	const { premiumDomainsGet, premiumDomainDelete } = useSiteData();

	const [domains, setDomains] = useState([]);

	function handleDelete(id) {
		premiumDomainDelete(id).then(getDomains());
	}

	function getDomains() {
		premiumDomainsGet().then(data => setDomains(data));
	}

	useEffect(() => {
		getDomains();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<>
			<section className='blue-bg'>
				<Link to='/cpanel' className='signup-button Link'>
					Return to cPanel
				</Link>
				<div className='space'></div>
				<div className='container'>
					<h1 className='center bolder blue'>Domains</h1>
					<div className='row'>
						<Link to='/cpanel/postdomain/' className='signup-button Link'>
							Create new domain
						</Link>
						<div className='col-md-12'>
							<table className='table center'>
								<thead>
									<tr>
										<th>
											<h5 className='bold'>Domain name</h5>
										</th>
										<th>View</th>
										<th>Edit</th>
										<th>
											<button className='button-sm'>Delete</button>
										</th>
									</tr>
								</thead>
								<thead>
									{domains &&
										domains.map((domain, i) => (
											<tr>
												<th>
													<h5 className='bold'>{domain.domain}</h5>
												</th>
												<th>
													<Link to={`/domains`} className='Link blue'>
														View
													</Link>
												</th>
												<th>
													<Link to={`/cpanel/updatedomain/${domain._id}`} className='Link blue'>
														Edit
													</Link>
												</th>
												<th>
													<button
														onClick={e => {
															e.preventDefault();
															handleDelete(domain._id);
														}}
														className='button-sm'>
														Delete
													</button>
												</th>
											</tr>
										))}
								</thead>
							</table>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
