import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Project from '../../../components/cards/Project';

import { Link } from 'react-router-dom';
import { useSiteData } from '../../../contexts/SiteDataContext';
import { useTranslation } from 'react-i18next';
export default function ProjectsSection(props) {
  const { t } = useTranslation();
  const { projectsGet } = useSiteData();

  const [projects, setProjects] = useState([]);
  const [view, setView] = useState('All');
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    window.screen.width <= 760 ? setIsMobile(true) : setIsMobile(false);
  }, []);

  function detectWindowSize() {
    window.innerWidth <= 760 ? setIsMobile(true) : setIsMobile(false);
  }

  window.onresize = detectWindowSize;

  useEffect(() => {
    projectsGet().then((data) => setProjects(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <section style={{ backgroundColor: props.bg }} className='service-container'>
        <h1 className='bold-ser black'>{t('projects.title')}</h1>
        <h6 className='ser gray'>{t('projects.desc')}</h6>
        <div className='plan-selection-container'>
          <div className='plan-selection'>
            <div className='left-b'>
              <button
                onClick={() => setView('All')}
                className={`bold ${view === 'All' ? 'active-b' : ''}`}
              >
                {t('all')}
              </button>
            </div>
            <div className='buttons-center'>
              <button
                onClick={() => setView('Mobile Applications')}
                className={`bold ${view === 'Mobile Applications' ? 'active-b' : ''}`}
              >
                {t('mobile-applications')}
              </button>
            </div>
            <div className='buttons-center'>
              <button
                onClick={() => setView('Websites')}
                className={`bold ${view === 'Websites' ? 'active-b' : ''}`}
              >
                {t('websites')}
              </button>
            </div>
            <div className='right-b'>
              <button
                onClick={() => setView('Motion Graphics')}
                className={`bold ${view === 'Motion Graphics' ? 'active-b' : ''}`}
              >
                {t('motion-graphics')}
              </button>
            </div>
          </div>
        </div>

        <div className='container'>
          <div className='row'>
            {!isMobile &&
              projects &&
              projects
                .filter((project) => view === 'All' || project.type === view)
                .map(
                  (project, i) =>
                    i <= 8 && (
                      <div className='col-lg-4 col-md-4 col-s-12'>
                        <Project
                          link={`/projects/page/${project._id}`}
                          img={project.imageUrls[0]}
                        />
                      </div>
                    )
                )}
            <Carousel>
              {isMobile &&
                projects &&
                projects
                  .filter((project) => view === 'All' || project.type === view)
                  .map(
                    (project, i) =>
                      i <= 8 && (
                        <Carousel.Item>
                          <Project
                            link={`/projects/page/${project._id}`}
                            img={project.imageUrls[0]}
                          />
                        </Carousel.Item>
                      )
                  )}
            </Carousel>
          </div>
        </div>
        <Link to='/projects'>
          <button className='more-button'>{t('more')}</button>
        </Link>
      </section>
    </>
  );
}
