import { t } from 'i18next';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useUserDB } from '../../../contexts/UserDBContext';

export default function MyItems() {
	const { userGetProducts } = useUserDB();

	const [products, setProducts] = useState([]);

	useEffect(() => {
		userGetProducts().then(products => setProducts(products));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className='col-xl-8 col-lg-12 col-md-12 col-sm-12'>
				<div className='space'></div>
				<h4 className='semibold gray'>{t('my-items')}</h4>
				<div className='space-sm'></div>
				<div className='options'>
					<select className='form-field-select' id='Sort' name='Sort By'>
						<option value=''>{t('sort-by')}</option>
						<option value='new'>{t('newest-to-oldest')}</option>
						<option value='old'>{t('oldest-to-newest')}</option>
						<option value='high'>{t('price-low-to-high')}</option>
						<option value='low'>{t('price-high-to-low')}</option>
					</select>
					<select className='form-field-select' id='Filter' name='Filter'>
						<option value=''>{t('filter')}</option>
						<option value='Expiration'>{t('expiration')}</option>
						<option value='domain'>{t('domains')}</option>
						<option value='website'>{t('websites')}</option>
						<option value='application'>{t('applications')}</option>
					</select>
				</div>
				<div className='space-sm'></div>
				<table className='table'>
					<thead>
						<tr>
							<th>{t('item-type')}</th>
							<th>{t('expiration')}</th>
							<th>{t('price')}</th>
							<th></th>
						</tr>
					</thead>
					{products &&
						products.map(product => (
							<thead>
								<tr>
									<td className='bold black'>{product.name}</td>
									<td>
										{product.period === 'yearly'
											? new Date(new Date().setFullYear(product.purchasedAt.toDate().getFullYear() + 1))
													.toISOString()
													.substring(0, 10)
											: new Date(new Date().setMonth(product.purchasedAt.toDate().getMonth() + 1))
													.toISOString()
													.substring(0, 10)}
									</td>
									<td>${product.price}</td>
									<td></td>
								</tr>
							</thead>
						))}
				</table>
			</div>
		</>
	);
}
