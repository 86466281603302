import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function SelectorLink({ fuzz, overwrite, to, className, ...rest }) {
	const location = useLocation();
	const otherPath = overwrite && overwrite.includes(location.pathname);

	let fuzzPath = false;
	fuzz?.forEach(path => {
		if (location.pathname.includes(path)) fuzzPath = true;
	});

	const activity = fuzzPath || otherPath || location.pathname === to ? 'activeLink' : '';

	return <Link to={to} className={className + ' ' + activity} {...rest} />;
}
