import React, { useRef, useState } from 'react';
import { Form, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import PasswordStrengthBar from 'react-password-strength-bar';
import Reaptcha from 'reaptcha';

import { firestore } from '../../lib/Firebase';
import { useAuth } from '../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { useStates } from '../../contexts/StatesContext';

export default function Signup({ setter }) {
	const { t } = useTranslation();
	const emailRef = useRef();
	const passwordRef = useRef();
	const confirmPasswordRef = useRef();
	const firstnameRef = useRef();
	const usernameRef = useRef();
	const lastnameRef = useRef();
	const tcCheckBox = useRef();

	const { signup } = useAuth();
	const { setPopUp } = useStates();

	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const [password, setPassword] = useState('');
	const [varified, setVarified] = useState(false);

	function handleVerify(token) {
		setVarified(true);
	}

	async function handleSubmit(e) {
		e.preventDefault();
		if (!validateForm()) return setLoading(false);

		setError(null);
		setLoading(true);
		try {
			const user = await signup(emailRef.current.value, passwordRef.current.value);
			const userRef = firestore.collection('users').doc(user.user.uid);

			await userRef.set({
				userName: usernameRef.current.value,
				firstName: firstnameRef.current.value,
				lastName: lastnameRef.current.value,
				email: emailRef.current.value,
				role: 'client',
				notifications: [],
				tickets: [],
				products: [],
				savedCards: [],
				cart: [],
				createdAt: new Date(),
			});
			setPopUp(false);
		} catch (error) {
			setError('Sign up failed. Please try again.');
		}
		setLoading(false);
	}

	function validateForm() {
		if (passwordRef.current.value !== confirmPasswordRef.current.value) {
			setError('Passwords do not match');
			return false;
		}
		if (passwordRef.current.value.length < 6) {
			setError('Password must be at least 6 characters');
			return false;
		}
		if (!emailRef.current.value) {
			setError('Email is required');
			return false;
		}
		if (!usernameRef.current.value) {
			setError('Username is required');
			return false;
		}
		if (!firstnameRef.current.value) {
			setError('First name is required');
			return false;
		}
		if (!lastnameRef.current.value) {
			setError('Last name is required');
			return false;
		}
		if (!passwordRef.current.value) {
			setError('Password is required');
			return false;
		}
		if (!tcCheckBox.current.checked) {
			setError('You must agree to the terms and conditions');
			return false;
		}
		if (!varified) {
			setError('You must verify that you are not a robot');
			return false;
		}
		return true;
	}

	return (
		<>
			<section className='popup'>
				<div onClick={() => setter(null)} className='bg' />
				<div className='signup-form'>
					<h4 className='form-font center'>{t('create-new')}</h4>
					{error && <Alert variant='danger'>{error}</Alert>}
					<Form onSubmit={handleSubmit}>
						<div className='form-fields'>
							<Form.Group id='email'>
								<input className='form-field' ref={usernameRef} placeholder={t('username')} />
							</Form.Group>
							<Form.Group id='password'>
								<input
									className='form-field'
									type='password'
									ref={passwordRef}
									onChange={e => setPassword(e.target.value)}
									placeholder={t('password')}
								/>
								<PasswordStrengthBar password={password} />
							</Form.Group>
							<Form.Group id='password'>
								<input
									className='form-field'
									type='password'
									ref={confirmPasswordRef}
									placeholder={t('confirm-password')}
								/>
							</Form.Group>
							<Form.Group id='email'>
								<input className='form-field' ref={firstnameRef} placeholder={t('firstname')} />
							</Form.Group>
							<Form.Group id='email'>
								<input className='form-field' ref={lastnameRef} placeholder={t('lastname')} />
							</Form.Group>
							<Form.Group id='email'>
								<input className='form-field' type='email' ref={emailRef} placeholder={t('email')} />
							</Form.Group>
						</div>

						<Reaptcha sitekey='6Lc8S3UhAAAAAIxquadTO1nAR_OuETF192Tyxo7y' onVerify={handleVerify} />

						<div className='form-elements'>
							<div className='form-font'>
								<input ref={tcCheckBox} type='checkbox' id='check' className='form-check-input'></input> {t('tos')}
								<a href='/terms-and-conditions' style={{ color: 'orange' }}>
									{t('terms')}
								</a>
							</div>
						</div>

						<div className='form-elements'>
							<button disabled={loading} variant='primary' type='submit' className='button signin-button'>
								{t('create-account')}
							</button>
						</div>
					</Form>
				</div>
			</section>
		</>
	);
}
