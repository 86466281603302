import React, { useState, useRef } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { Alert } from 'react-bootstrap';

export default function ChangePassword() {
	const oldPassswordRef = useRef();
	const newPasswordRef = useRef();
	const confirmPasswordRef = useRef();

	const { updatePassword, reauthenticate } = useAuth();
	const [error, setError] = useState(null);
	const [message, setMessage] = useState(null);
	const [loading, setLoading] = useState(false);

	async function handleSubmit(e) {
		e.preventDefault();
		if (newPasswordRef.current.value !== confirmPasswordRef.current.value) return setError('Passwords do not match');

		if (!oldPassswordRef.current.value) return setError('Please enter your old password');

		setLoading(true);

		try {
			await reauthenticate(oldPassswordRef.current.value);
			await updatePassword(newPasswordRef.current.value);
			setError(null);
			setMessage('Password updated successfully');
			setLoading(false);
		} catch (error) {
			setError(error.message);
			setLoading(false);
		}
		setLoading(false);
	}

	return (
		<>
			<div className='col-xl-8 col-lg-12 col-md-12 col-sm-12'>
				<div className='space'></div>
				<div className='change-pass'>
					<h4 className='form-font center'>Change password</h4>
					{message && <Alert variant='success'>{message}</Alert>}
					{error && <Alert variant='danger'>{error}</Alert>}
					<form onSubmit={handleSubmit}>
						<input ref={oldPassswordRef} type='password' className='form-field' placeholder='OLD PASSWORD' />
						<input ref={newPasswordRef} type='password' className='form-field' placeholder='NEW PASSWORD' />
						<input ref={confirmPasswordRef} type='password' className='form-field' placeholder='CONFIRM NEW PASSWORD' />
						<div className='form-elements'>
							<button disabled={loading} variant='primary' type='submit' className='button signin-button'>
								Reset Password
							</button>
						</div>
					</form>
				</div>
				<div className='space'></div>
			</div>
		</>
	);
}
