import { React, useState } from 'react';
import { motion } from 'framer-motion';

export default function ChatBubble({ notificationNum }) {
  const [isHovered, setHovered] = useState(false);
  return (
    <>
      <div className='chat-icon'>
        <motion.button
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          animate={{ x: isHovered ? [-5, 5] : 0, rotate: isHovered ? [-5, 5] : 0 }}
          transition={{
            repeat: isHovered ? Infinity : 0,
            repeatType: 'reverse',
            type: 'spring',
            duration: 0.3,
          }}
          initial={{ x: 0, rotate: 0 }}
        >
          <svg xmlns='http://www.w3.org/2000/svg' width='87' height='81' viewBox='0 0 87 81'>
            <circle
              id='Ellipse_935'
              data-name='Ellipse 935'
              cx='39.5'
              cy='39.5'
              r='39.5'
              transform='translate(0 2)'
              fill='#408bd4'
            />

            <path
              id='Path_6208'
              data-name='Path 6208'
              d='M10.46,0H29.1c5.777,0,10.465,3.938,10.465,9.715l-.005,8.542a5.705,5.705,0,0,1-5.705,5.705H5.705A5.705,5.705,0,0,1,0,18.257L.011,9.57C.011,3.793,4.683,0,10.46,0Z'
              transform='translate(20.293 19.154)'
              fill='#fff'
            />
            <path
              id='Rectangle_1335'
              data-name='Rectangle 1335'
              d='M11.59,0h6.6a11.59,11.59,0,0,1,11.59,11.59v0a6.954,6.954,0,0,1-6.954,6.954H6.954A6.954,6.954,0,0,1,0,11.59v0A11.59,11.59,0,0,1,11.59,0Z'
              transform='translate(55.064 63.846) rotate(180)'
              fill='#fff'
            />
            <path
              id='Path_6209'
              data-name='Path 6209'
              d='M0,0,.589.041A2.853,2.853,0,0,1,3.441,2.894V6.507A2.853,2.853,0,0,1,.589,9.36H.018Z'
              transform='translate(20.311 37.834) rotate(180)'
              fill='#edf4ff'
            />
            <path
              id='Rectangle_1336'
              data-name='Rectangle 1336'
              d='M0,0H0A1.124,1.124,0,0,1,1.124,1.124v.562A1.124,1.124,0,0,1,0,2.81H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z'
              transform='translate(16.852 34.625) rotate(180)'
              fill='#bdc2ca'
            />
            <rect
              id='Rectangle_1337'
              data-name='Rectangle 1337'
              width='25.896'
              height='10.594'
              rx='3'
              transform='translate(27.076 25.639)'
              fill='#408bd4'
            />
            <motion.ellipse
              animate={{ cy: isHovered ? '2' : '2.81', ry: isHovered ? '1' : '2.81' }}
              initial={{ cy: '2.81', ry: '2.81' }}
              transition={{
                repeat: isHovered ? Infinity : 0,
                repeatType: 'reverse',
                ease: 'easeInOut',
                duration: 0.3,
              }}
              id='Ellipse_936'
              data-name='Ellipse 936'
              cx='2.81'
              cy='2.81'
              ry='2.81'
              rx='2.81'
              transform='translate(30.9 28.443)'
              fill='#fff'
            />
            <motion.ellipse
              animate={{ cy: isHovered ? '2' : '2.81', ry: isHovered ? '1' : '2.81' }}
              initial={{ cy: '2.81', ry: '2.81' }}
              transition={{
                repeat: isHovered ? Infinity : 0,
                repeatType: 'reverse',
                ease: 'easeInOut',
                duration: 0.3,
              }}
              id='Ellipse_937'
              data-name='Ellipse 937'
              cx='2.81'
              cy='2.81'
              rx='2.81'
              ry='2.81'
              transform='translate(43.824 28.443)'
              fill='#fff'
            />

            <path
              id='Path_6210'
              data-name='Path 6210'
              d='M-.071,0H.5A2.853,2.853,0,0,1,3.352,2.853V6.466A2.853,2.853,0,0,1,.5,9.319l-.587.025Z'
              transform='translate(59.92 28.475)'
              fill='#edf4ff'
            />
            <path
              id='Rectangle_1338'
              data-name='Rectangle 1338'
              d='M0,0H0A1.124,1.124,0,0,1,1.124,1.124v.562A1.124,1.124,0,0,1,0,2.81H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z'
              transform='translate(63.289 31.816)'
              fill='#bdc2ca'
            />
            {notificationNum > 0 && (
              <>
                <g
                  id='Ellipse_938'
                  data-name='Ellipse 938'
                  transform='translate(60 2)'
                  fill='#ff1313'
                  stroke='#f2f7ff'
                  stroke-width='2'
                >
                  <circle cx='12.5' cy='12.5' r='12.5' stroke='none' />
                  <circle cx='12.5' cy='12.5' r='13.5' fill='none' />
                </g>
                <text
                  id='_1'
                  data-name='1'
                  transform='translate(72 21)'
                  fill='#fff'
                  font-size='16'
                  font-family='SegoeUI-Bold, Segoe UI'
                  font-weight='700'
                >
                  <tspan x='-4.602' y='0'>
                    {notificationNum}
                  </tspan>
                </text>
              </>
            )}
          </svg>
        </motion.button>
      </div>
    </>
  );
}
