import React from 'react';
import { Link } from 'react-router-dom';

import { useStates } from '../../../contexts/StatesContext';

export default function CPanelHome() {
	const { chatDisabled, setChatDisabled } = useStates();
	return (
		<>
			<div className='space'></div>
			<h1 className='center bolder blue'>cPanel</h1>
			<div className='container'>
				<div className='row'>
					<div className='col-md-6 center'>
						<h2 className='Bold black'>Blogs</h2>
						<div className='space-sm'></div>
						<Link to='/cpanel/listblogs' className='signup-button Link'>
							Enter
						</Link>
						<div className='space'></div>
					</div>
					<div className='col-md-6 center'>
						<h2 className='Bold black'>Projects</h2>
						<div className='space-sm'></div>
						<Link to='/cpanel/listprojects/' className='signup-button Link'>
							Enter
						</Link>
						<div className='space'></div>
					</div>
					<div className='col-md-6 center'>
						<h2 className='Bold black'>Hosting plans</h2>
						<div className='space-sm'></div>
						<Link to='/cpanel/listplantypes/' className='signup-button Link'>
							Enter
						</Link>
						<div className='space'></div>
					</div>
					<div className='col-md-6 center'>
						<h2 className='Bold black'>Premium domains</h2>
						<div className='space-sm'></div>
						<Link to='/cpanel/listdomains/' className='signup-button Link'>
							Enter
						</Link>
						<div className='space'></div>
					</div>
					<div className='col-md-6 center'>
						<h2 className='Bold black'>Toggle Live Chat</h2>
						<div className='space-sm'></div>
						<button onClick={() => setChatDisabled(!chatDisabled)} className='signup-button Link'>
							{chatDisabled ? 'Enable Chat' : 'Disable Chat'}
						</button>
						<div className='space'></div>
					</div>
				</div>
			</div>
		</>
	);
}
