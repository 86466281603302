import React, { useState, useRef, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { motion } from 'framer-motion';
import SearchMouse from '../../../assets/search.svg';
import { useTranslation } from 'react-i18next';
import Home from '../../../assets/NewArt/Home.svg';
import { useProductDB } from '../../../contexts/ProductDBContext';
import { useUserDB } from '../../../contexts/UserDBContext';
import { useAuth } from '../../../contexts/AuthContext';
import Mobile from '../../../assets/NewArt/MobileBackground.svg';
export default function DomainSection() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const discoutPercent = 1.5;
  const searchRef = useRef(null);
  const { searchFullDomain } = useProductDB();
  const { userAddToCart } = useUserDB();
  const { currentUser } = useAuth();

  const [searchResults, setSearchResults] = useState([]);
  const [available, setAvailable] = useState(false);
  const [nextAvailable, setNextAvailable] = useState(null);
  const [mainCarted, setMainCarted] = useState(false);
  const defaultTlds = ['com', 'net', 'org', 'biz', 'info', 'io'];

  function handleSearch(e) {
    e.preventDefault();

    const dparts = searchRef.current.value.split('.');
    const dname = dparts[0];
    const searchTld = dparts[1];

    if (defaultTlds.includes(searchTld)) {
      defaultTlds.splice(defaultTlds.indexOf(searchTld), 1);
    }

    defaultTlds.unshift(searchTld);
    searchFullDomain(dname, defaultTlds).then((res) => {
      console.log(res);
      if (res.length > 1 && !res[0].success && res.filter((item) => item.success).length > 0)
        res[0].available = 'kinda';
      setSearchResults(res);
    });
  }

  useEffect(() => {
    if (searchResults?.[0]?.available === 'true') {
      setAvailable(true);
    } else {
      setAvailable(false);
      const nAv = searchResults.find((res) => res.available === 'true');
      nAv && setNextAvailable(nAv);
    }
  }, [searchResults]);

  return (
    <>
      <img
        src={Home}
        className='d-none d-md-block'
        style={{
          zIndex: '-1',
          width: '100%',
          position: 'absolute',
          left: '0',
        }}
      />
      <img
        src={Mobile}
        className='d-block d-md-none'
        style={{ zIndex: '-1', width: '100%', position: 'absolute', left: '0' }}
      />
      <section className='domain-search'>
        <div className='container'>
          <div className='row'>
            <form
              style={{ marginTop: 'auto', marginBottom: 'auto' }}
              onSubmit={handleSearch}
              className='col-xl-6 col-lg-12 col-md-12 col-sm-12'
            >
              <div className='dom-space' />
              <h1
                style={{
                  direction: `${i18n.language === 'ar' ? 'rtl' : ''}`,
                  textAlign: `${i18n.language === 'ar' ? 'right' : 'left'}`,
                  paddingRight: `${i18n.language === 'ar' ? '5vw' : '0'}`,
                }}
              >
                {t('start')}
              </h1>

              <motion.div
                style={{
                  direction: `${i18n.language === 'ar' ? 'rtl' : ''}`,
                  textAlign: `${i18n.language === 'ar' ? 'right' : 'left'}`,
                }}
                animate={{ width: '35rem' }}
                initial={{ width: '0' }}
                transition={{ delay: 0.2, duration: 1 }}
                className='search-bar'
              >
                <motion.input
                  animate={{
                    opacity: 1,
                  }}
                  initial={{ opacity: 0 }}
                  transition={{ delay: 1, duration: 0 }}
                  style={{
                    direction: `${i18n.language === 'ar' ? 'rtl' : ''}`,
                  }}
                  type='text'
                  placeholder={t('findyourdomain')}
                  ref={searchRef}
                ></motion.input>
                <button
                  style={{
                    transform: `${i18n.language === 'ar' ? 'scaleX(-1)' : ''}`,
                  }}
                  type='submit'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='16'
                    fill='currentColor'
                    className='bi bi-search'
                    viewBox='0 0 16 16'
                  >
                    <path d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'></path>
                  </svg>
                </button>
              </motion.div>
            </form>
            <motion.div
              style={{ padding: '2%' }}
              animate={{ x: 0 }}
              initial={{ x: 1000 }}
              transition={{ delay: 0.2, duration: 1 }}
              className='col-xl-6 col-lg-12 col-md-12 col-sm-12 d-block d-md-none'
            >
              <img className='domain-home' alt='' width='80%' src={SearchMouse} />
            </motion.div>
          </div>

          {searchResults?.[0] && (
            <div className='domain-result'>
              <div className='container'>
                <div className='row'>
                  <div style={{ textAlign: 'left' }} className='col-lg-6 col-sm-12'>
                    <p style={{ margin: 0 }} className='gray thin'>
                      {searchResults?.[0]?.domain ||
                        searchResults?.filter((d) => d.available === 'true')?.[0]?.domain}{' '}
                      is
                      {available ? '' : searchResults?.[0]?.available === 'kinda' ? '' : 'not'}{' '}
                      available
                    </p>
                    <h4 className='bolder black'>
                      {available ? searchResults?.[0]?.domain : nextAvailable?.domain}
                    </h4>
                  </div>
                  <div style={{ textAlign: 'right' }} className='col-lg-4 col-sm-12'>
                    <div style={{ display: 'inline-block', textAlign: 'center' }}>
                      <p style={{ margin: 0 }} className='red bold'>
                        ON SALE
                      </p>
                      <div style={{ display: 'flex' }}>
                        <h5
                          style={{
                            textDecoration: 'line-through',
                            marginRight: '0.6rem',
                          }}
                          className='thin gray'
                        >
                          $
                          {(
                            parseFloat(
                              available
                                ? searchResults?.[0]?.registerPrice
                                : nextAvailable?.registerPrice
                            ) * discoutPercent
                          ).toFixed(2)}
                        </h5>
                        <h4 style={{ margin: 0 }} className='bolder black'>
                          $
                          {available
                            ? searchResults?.[0]?.registerPrice
                            : nextAvailable?.registerPrice}
                        </h4>
                      </div>
                      <p style={{ margin: 0, fontSize: '.8rem' }} className='thin gray'>
                        Renewal: $
                        {available ? searchResults?.[0]?.renewPrice : nextAvailable?.renewPrice}
                      </p>
                    </div>
                  </div>
                  <div className='col-lg-2 col-sm-12'>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        if (!mainCarted) {
                          userAddToCart(currentUser.uid, {
                            price: parseFloat(
                              available
                                ? searchResults?.[0]?.registerPrice
                                : nextAvailable?.registerPrice
                            ),
                            type: 'Domain',
                            name: available ? searchResults?.[0]?.domain : nextAvailable?.domain,
                            period: 'yearly',
                          });
                          setMainCarted(true);
                        }
                      }}
                      className='addcart-button'
                    >
                      {console.log(currentUser)}
                      {!mainCarted ? (
                        <>{t('add-to-cart')}</>
                      ) : (
                        <>
                          {/* Added to cart */}
                          <div
                            style={{
                              transform: `${i18n.language === 'ar' ? 'scaleX(-1)' : ''}`,
                            }}
                          >
                            {/* <i class='bi bi-check-lg'></i> */}
                            <i class='bi bi-cart-check'></i>
                          </div>
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}
