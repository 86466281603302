import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useTranslation } from 'react-i18next';
export default function PlanSection() {
  const { t } = useTranslation();
  return (
    <>
      <section className='pricing'>
        <h1 className='bold-ser black'>{t('howdoes.title')}</h1>
        <h6 style={{ padding: '1rem 1rem' }} className='ser gray'>
          {t('howdoes.desc')}
        </h6>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='1605.26'
          height='fit-content'
          viewBox='0 0 1605.26 390.04'
        >
          <g id='Group_5865' data-name='Group 5865' transform='translate(-162.37 -4638.885)'>
            <path
              id='Path_306'
              data-name='Path 306'
              d='M-3462.663,5035.449s205.522-120.2,315.954-90.043c26.393,7.207,41.075,10.461,68.019,20.6,85.872,32.3,167.179,77.447,167.179,77.447s90.9,62.56,330.651-61.918c81.756-42.447,151.374-59.752,207.964-61.419,81.941-3.518,169.507,46.341,169.507,46.341s113.822,68.657,291.833,63.492'
              transform='translate(3628.701 -205.216)'
              fill='none'
              stroke='#92a0c9'
              strokeWidth='2'
              strokeDasharray='6'
            />
            <circle
              className='bigcircle'
              id='Ellipse_3'
              data-name='Ellipse 3'
              cx='83.739'
              cy='83.739'
              r='83.739'
              transform='translate(1484.278 4714.007)'
              fill='#3346d4'
            />
            <circle
              className='bigcircle'
              id='Ellipse_9'
              data-name='Ellipse 9'
              cx='83.739'
              cy='83.739'
              r='83.739'
              transform='translate(679.677 4714.007)'
              fill='#3346d4'
            />
            <circle
              className='bigcircle'
              id='Ellipse_6'
              data-name='Ellipse 6'
              cx='83.739'
              cy='83.739'
              r='83.739'
              transform='translate(1082.909 4639.205)'
              fill='#e25e26'
            />
            <circle
              className='bigcircle'
              id='Ellipse_7'
              data-name='Ellipse 7'
              cx='83.739'
              cy='83.739'
              r='83.739'
              transform='translate(278.251 4639.205)'
              fill='#e25e26'
            />
            <circle
              className='smallcircle'
              id='Ellipse_4'
              data-name='Ellipse 4'
              cx='27.913'
              cy='27.913'
              r='27.913'
              transform='translate(1595.93 4713.683)'
              fill='#09114a'
            />
            <circle
              className='smallcircle'
              id='Ellipse_10'
              data-name='Ellipse 10'
              cx='27.913'
              cy='27.913'
              r='27.913'
              transform='translate(791.245 4713.246)'
              fill='#09114a'
            />
            <circle
              className='smallcircle'
              id='Ellipse_5'
              data-name='Ellipse 5'
              cx='27.913'
              cy='27.913'
              r='27.913'
              transform='translate(1194.407 4638.885)'
              fill='#09114a'
            />
            <circle
              className='smallcircle'
              id='Ellipse_8'
              data-name='Ellipse 8'
              cx='27.913'
              cy='27.913'
              r='27.913'
              transform='translate(392.103 4638.885)'
              fill='#09114a'
            />
            <circle
              id='Ellipse_11'
              data-name='Ellipse 11'
              cx='5.498'
              cy='5.498'
              r='5.498'
              transform='translate(162.37 4824.898)'
              fill='#666a80'
            />
            <text
              id='Research_Project'
              data-name='Research Project'
              transform='translate(356.808 4876.697)'
              fill='#4a4a4a'
              fontSize='33'
              fontFamily='Nunito-ExtraBold, Nunito'
              fontWeight='800'
            >
              <tspan x='-130.053' y='0'>
                {t('howdoes.step1.title')}
              </tspan>
            </text>
            <text
              id='Find_Ideas'
              data-name='Find Ideas'
              transform='translate(756.259 4952.344)'
              fill='#4a4a4a'
              fontSize='33'
              fontFamily='Nunito-ExtraBold, Nunito'
              fontWeight='800'
            >
              <tspan x='-79.216' y='0'>
                {t('howdoes.step2.title')}
              </tspan>
            </text>
            <text
              id='Start_Optimize'
              data-name='Start Optimize'
              transform='translate(1158.146 4877.547)'
              fill='#4a4a4a'
              fontSize='33'
              fontFamily='Nunito-ExtraBold, Nunito'
              fontWeight='800'
            >
              <tspan x='-114.23' y='0'>
                {t('howdoes.step3.title')}
              </tspan>
            </text>
            <text
              id='Reach_Target'
              data-name='Reach Target'
              transform='translate(1561.057 4949.794)'
              fill='#4a4a4a'
              fontSize='33'
              fontFamily='Nunito-ExtraBold, Nunito'
              fontWeight='800'
            >
              <tspan x='-101.541' y='0'>
                {t('howdoes.step4.title')}
              </tspan>
            </text>
            <text
              id='There_are_many_variations_of_passages_of_Lorem_Ipsum_available'
              data-name='There are many variations of
passages of Lorem Ipsum available'
              transform='translate(358.05 4920.277)'
              fill='#9a9a9a'
              fontSize='21'
              fontFamily='SegoeUI, Segoe UI'
            >
              {t('howdoes.step1.desc')}
            </text>
            <text
              id='There_are_many_variations_of_passages_of_Lorem_Ipsum_available-2'
              data-name='There are many variations of
passages of Lorem Ipsum available'
              transform='translate(755.937 4995.925)'
              fill='#9a9a9a'
              fontSize='21'
              fontFamily='SegoeUI, Segoe UI'
            >
              {t('howdoes.step2.desc')}
            </text>
            <text
              id='There_are_many_variations_of_passages_of_Lorem_Ipsum_available-3'
              data-name='There are many variations of
passages of Lorem Ipsum available'
              transform='translate(1159.393 4921.127)'
              fill='#9a9a9a'
              fontSize='21'
              fontFamily='SegoeUI, Segoe UI'
            >
              {t('howdoes.step3.desc')}
            </text>
            <text
              id='There_are_many_variations_of_passages_of_Lorem_Ipsum_available-4'
              data-name='There are many variations of
passages of Lorem Ipsum available'
              transform='translate(1560.693 4993.375)'
              fill='#9a9a9a'
              fontSize='21'
              fontFamily='SegoeUI, Segoe UI'
            >
              {t('howdoes.step4.desc')}
            </text>
            <path
              id='Path_307'
              data-name='Path 307'
              d='M-1618.351,5064.957l-7.6-15.441,37.875.016Z'
              transform='translate(3355.624 -225.031)'
              fill='#93b4fb'
            />
            <path
              id='Path_310'
              data-name='Path 310'
              d='M-1624.04,5052.005h-7.411l3.938-3.652,3.8.43Z'
              transform='translate(3356.414 -224.876)'
              fill='#93b4fb'
            />
            <path
              id='Path_308'
              data-name='Path 308'
              d='M-1631.283,5051.234l2.25-8.385,40.276,5.721h-38.172Z'
              transform='translate(3356.388 -224.036)'
              fill='#7c97f3'
            />
            <path
              id='Path_309'
              data-name='Path 309'
              d='M-1589.492,5046.444l-46.014-17.674,5.865,12.055Z'
              transform='translate(3357.039 -221.897)'
              fill='#93b4fb'
            />
            <text
              id='_1'
              data-name='1'
              transform='translate(420.165 4677.876)'
              fill='#fff'
              fontSize='28'
              fontFamily='SegoeUI, Segoe UI'
            >
              <tspan x='-7.547' y='0'>
                1
              </tspan>
            </text>
            <text
              id='_2'
              data-name='2'
              transform='translate(819.261 4750.227)'
              fill='#fff'
              fontSize='28'
              fontFamily='SegoeUI, Segoe UI'
            >
              <tspan x='-7.547' y='0'>
                2
              </tspan>
            </text>
            <text
              id='_3'
              data-name='3'
              transform='translate(1223.383 4676.871)'
              fill='#fff'
              fontSize='28'
              fontFamily='SegoeUI, Segoe UI'
            >
              <tspan x='-7.547' y='0'>
                3
              </tspan>
            </text>
            <text
              id='_4'
              data-name='4'
              transform='translate(1623 4751.231)'
              fill='#fff'
              fontSize='28'
              fontFamily='SegoeUI, Segoe UI'
            >
              <tspan x='-7.547' y='0'>
                4
              </tspan>
            </text>
          </g>
        </svg>
      </section>
    </>
  );
}
