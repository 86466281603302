import React, { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';

export default function Clientpfp() {
  const { currentUser } = useAuth();
  const [letter, setLetter] = useState('');
  useEffect(() => {
    setLetter(currentUser?.data?.firstName.charAt(0).toUpperCase());
  }, [currentUser]);
  return <h4 className='client-pfp'>{letter}</h4>;
}
