import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Editor } from 'react-bootstrap-editor';

import { daysUntilToday } from '../../../utils/simpleFunctions.js';
import { useTicketDB } from '../../../contexts/TicketDBContext';
import Clientpfp from '../../../components/Clientpfp';
import Supportpfp from '../../../assets/Chat/supportpf.svg';
import { t } from 'i18next';
import { async } from 'emoji-mart';

function TicketMessage({ isFirst, sender, subject, message, timestamp }) {
  const { t } = useTranslation();
  return (
    <>
      {!isFirst && <hr className='line'></hr>}
      <div>
        <div className='user-info'>
          {sender === 'You' ? <Clientpfp /> : <img src={Supportpfp} alt='support' />}
          <p className='bold blue'>{sender}</p>
          <p className='semibold gray'>
            - {daysUntilToday(timestamp)} {t('days-ago')}
          </p>
        </div>
        <p className='semibold gray'>
          <strong>{subject}</strong>
        </p>
        <p dangerouslySetInnerHTML={{ __html: message }} className='semibold gray' />
      </div>
    </>
  );
}

export default function TicketPage() {
  const { t } = useTranslation();
  const { ticketID } = useParams();

  const subjectRef = useRef();
  const messageRef = useRef();

  const { ticketGet, ticketMessage, ticketClose } = useTicketDB();

  const [ticket, setTicket] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState(null);
  const [status, setStatus] = useState(false);

  async function handleClose(e) {
    e.preventDefault();
    setLoading(true);
    try {
      ticketClose(ticketID);
      setStatus(false);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setError(null);
    setLoading(true);

    // if (subjectRef.current.value === '' || messageRef.current.value === '') {
    // 	setError('Please fill out all fields');
    // 	setLoading(false);
    // 	return;
    // }

    try {
      await ticketMessage({
        ticketID,
        messageSubject: ' ',
        messageDescription: content,
      });
      messageRef.current.box.current.innerHTML = '';
      setContent('');
      // setContent('');
      // console.log('MSG', messageRef.current.state.data);
      // subjectRef.current.value = '';
      messageRef.current.state.setData('');
    } catch (error) {
      // setError(error);
    }
    setLoading(false);
  }

  function getTicket() {
    ticketGet(ticketID).then((ticket) =>
      setTicket(ticket, setStatus(ticket.status !== 'closed' ? true : false))
    );
  }

  useEffect(() => {
    getTicket();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <>
      <div className='col-xl-8 col-lg-12 col-md-12 col-sm-12'>
        <div className='space'></div>
        <div className='space-sm'></div>
        <div className='d-table'>
          <div className='form-fields'>
            <div style={{ width: '60rem' }} className='overflow-y-scroll'>
              {ticket?.messages.map((message, index) => (
                <TicketMessage
                  key={index}
                  isFirst={index === 0}
                  sender={message.sender === 'client' ? 'You' : 'Support team'}
                  subject={message.messageSubject}
                  message={message.messageDescription}
                  timestamp={message.createdAt}
                />
              ))}
            </div>
            {error && <Alert variant='danger'>{error}</Alert>}
            <Form onSubmit={handleSubmit}>
              <div className='form-field-message'>
                <div className='space'></div>
                {status && (
                  <textarea
                    rows={5}
                    ref={messageRef}
                    value={content}
                    onChange={(e) => {
                      setContent(e.target.value);
                    }}
                  />
                )}
              </div>

              {status && (
                <>
                  <button disabled={loading} type='submit' className='button-sm'>
                    {t('send')}
                  </button>
                  <button
                    style={{ marginLeft: '3rem' }}
                    onClick={handleClose}
                    disabled={loading}
                    type='button'
                    className='button-sm'
                  >
                    {/* {t('send')} */}
                    Close Ticket
                  </button>
                </>
              )}
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
