import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Alert } from 'react-bootstrap';

import { useAuth } from '../../../contexts/AuthContext';
import { useStates } from '../../../contexts/StatesContext';

import SelectorLink from '../../../utils/SelectorLink';

export default function SideMenu() {
	const { t } = useTranslation();
	const { i18n } = useTranslation();
	const { logout, currentUser } = useAuth();
	const { tickets } = useStates();
	const navigate = useNavigate();

	const [error, setError] = useState(null);
	const [numTickets, setNumTickets] = useState(0);

	useEffect(() => {
		setNumTickets(tickets.filter(t => t.data().status !== 'closed').length);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tickets]);

	async function handleLogout() {
		setError(null);
		try {
			await logout();
			navigate('/');
		} catch (error) {
			setError('Can not logout');
		}
	}
	return (
		<>
			<h2 className='bold black'>
				<b>{t('sidemenu.account')}</b>
			</h2>
			{error && <Alert variant='danger'>{error}</Alert>}
			<hr className='line'></hr>
			<div
				style={{
					direction: `${i18n.language === 'ar' ? 'rtl' : ''}`,
					textAlign: `${i18n.language === 'ar' ? 'right' : 'left'}`,
				}}
				className='settings-card'>
				<h5 className='bold black'>{t('sidemenu.settings')}</h5>
				<SelectorLink
					style={{
						direction: `${i18n.language === 'ar' ? 'rtl' : ''}`,
						textAlign: `${i18n.language === 'ar' ? 'right' : 'left'}`,
						float: `${i18n.language === 'ar' ? 'right' : 'left'}`,
					}}
					to='/profile'
					className='account-sb-link'>
					{t('sidemenu.profile')}
				</SelectorLink>
				<SelectorLink
					style={{
						direction: `${i18n.language === 'ar' ? 'rtl' : ''}`,
						textAlign: `${i18n.language === 'ar' ? 'right' : 'left'}`,
						float: `${i18n.language === 'ar' ? 'right' : 'left'}`,
					}}
					to='/change-password'
					className='account-sb-link'>
					{t('sidemenu.change-password')}
				</SelectorLink>
				{currentUser?.data?.role === 'client' && (
					<>
						<div className='grid'>
							<SelectorLink
								style={{
									direction: `${i18n.language === 'ar' ? 'rtl' : ''}`,
									textAlign: `${i18n.language === 'ar' ? 'right' : 'left'}`,
									float: `${i18n.language === 'ar' ? 'right' : 'left'}`,
								}}
								to='#'
								className='account-sb-link'>
								{t('sidemenu.billing-settings')}
							</SelectorLink>
							<SelectorLink
								style={{
									direction: `${i18n.language === 'ar' ? 'rtl' : ''}`,
									textAlign: `${i18n.language === 'ar' ? 'right' : 'left'}`,
									float: `${i18n.language === 'ar' ? 'right' : 'left'}`,
								}}
								to='/my-tickets'
								fuzz={['/ticket-page']}
								overwrite={['/submit-ticket', '/ticket-page']}
								className='account-sb-link'>
								{t('sidemenu.tickets')}
								{numTickets > 0 && <span className='alert'>{numTickets}</span>}
							</SelectorLink>
							<SelectorLink
								style={{
									direction: `${i18n.language === 'ar' ? 'rtl' : ''}`,
									textAlign: `${i18n.language === 'ar' ? 'right' : 'left'}`,
									float: `${i18n.language === 'ar' ? 'right' : 'left'}`,
								}}
								to='/my-items'
								className='account-sb-link'>
								{t('sidemenu.items')}
							</SelectorLink>
						</div>
					</>
				)}
				<SelectorLink
					style={{
						direction: `${i18n.language === 'ar' ? 'rtl' : ''}`,
						textAlign: `${i18n.language === 'ar' ? 'right' : 'left'}`,
						float: `${i18n.language === 'ar' ? 'right' : 'left'}`,
					}}
					to='/'
					className='bold red'
					onClick={handleLogout}>
					{t('sidemenu.logout')}
				</SelectorLink>
			</div>
		</>
	);
}
