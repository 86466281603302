import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { storageRef } from '../../../lib/Firebase';
import { useLiveChatDB } from '../../../contexts/LiveChatDBContext';

import supportpf from '../../../assets/Chat/supportpf.svg';
import Clientpfp from '../../../components/Clientpfp';
import { MoonStars } from 'react-bootstrap-icons';

function isImage(url) {
	return /\.(jpg|jpeg|png|webp|avif|gif|svg)/.test(url);
}

function SupportMessage({ message, file }) {
	return (
		<div className='support'>
			<img src={supportpf} alt='support' />
			<div className='support-text'>
				{file ? (
					isImage(message) ? (
						<img style={{ width: '100%' }} src={message} alt='im' />
					) : (
						<a href={message}>
							<i class='bi bi-file-earmark'></i>
						</a>
					)
				) : (
					message
				)}
			</div>
		</div>
	);
}

function ClientMessage({ message, file }) {
	return (
		<div className='client-message'>
			<Clientpfp />
			<div className='support-text'>
				{file ? (
					isImage(message) ? (
						<img style={{ width: '100%' }} src={message} alt='im' />
					) : (
						<a href={message}>
							<i class='bi bi-file-earmark'></i>
						</a>
					)
				) : (
					message
				)}
			</div>
		</div>
	);
}

export default function ChatPage() {
	const dummy = useRef();
	const fileRef = useRef();
	const inputRef = useRef();
	const transferRef = useRef();
	const { chatID } = useParams();

	const { getLiveChatRef, sendLiveChatMessage, transferLiveChat, closeLiveChat, disableMessages, liveChatSetTyping } =
		useLiveChatDB();

	const liveChatRef = getLiveChatRef(chatID);
	const messagesRef = liveChatRef.collection('messages').orderBy('createdAt');

	const [typing, setTyping] = useState(false);
	const [chatInfo, setChatInfo] = useState({});
	const [messages, setMessages] = useState([]);
	const [fileUp, setFileUp] = useState(false);

	async function handleSubmit(e) {
		e.preventDefault();
		if (fileUp) {
			const file = fileRef.current.files[0];
			const filef = storageRef.child(`/livechat/${file.name}`);
			await filef.put(file);
			const url = await filef.getDownloadURL();
			await sendLiveChatMessage(chatID, 'support', url, true);
			setFileUp(false);
		} else {
			await sendLiveChatMessage(chatID, 'support', inputRef.current.value);
			inputRef.current.value = '';
			dummy.current.scrollIntoView({ behavior: 'smooth' });
			liveChatSetTyping(chatID, false);
			setTyping(false);
		}
	}

	async function handleTransfer(e) {
		e.preventDefault();
		await transferLiveChat(chatID, transferRef.current.value);
	}

	async function handleEnd(e) {
		await sendLiveChatMessage(chatID, 'support', 'Disconnected.');
		await disableMessages(chatID);
		e.preventDefault();
	}

	function handleTyping(e) {
		// 13 is enter key
		if (e.keyCode !== 13) {
			liveChatSetTyping(chatID, true);
			setTyping(true);
		}
	}

	useEffect(() => {
		if (typing) {
			setTimeout(() => {
				liveChatSetTyping(chatID, false);
				setTyping(false);
			}, 3000);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [typing]);

	useEffect(() => {
		document.addEventListener('keydown', handleTyping);
		const unsubLc = liveChatRef.onSnapshot(snapshot => {
			setChatInfo(snapshot.data());
		});
		const unsubm = messagesRef.onSnapshot(snapshot => {
			const messages = snapshot.docs.map(doc => {
				return {
					...doc.data(),
					id: doc.id,
				};
			});
			setMessages(messages);
			return () => {
				document.removeEventListener('keydown', handleTyping);
				unsubLc();
				unsubm();
			};
		}); // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className='chat-content'>
				<div className='chat-messages'>
					{messages &&
						messages?.map(message => {
							return message.sender === 'client' ? (
								<ClientMessage file={message.file} message={message.message} />
							) : (
								<SupportMessage file={message.file} message={message.message} />
							);
						})}
					<div ref={dummy} />
				</div>
			</div>
			<select
				ref={transferRef}
				defaultValue=''
				className='form-field-select-three space-sm'
				id='TypeSelect'
				name='Type Select'>
				<option value='' disabled>
					Transfer Chat to
				</option>
				<option value='Hosting'>Hosting</option>
				<option value='Domains'>Domains</option>
				<option value='Applications'>Mobile Applications</option>
				<option value='Websites'>Websites</option>
			</select>
			<button onClick={handleTransfer} className='chat-send-btn'>
				Transfer
			</button>
			<button onClick={handleEnd} className='chat-send-btn'>
				End Chat
			</button>
			<form onSubmit={handleSubmit} className='chat-input'>
				<input ref={inputRef} type='text' placeholder='Type your message here...' />
				<button type='submit' className='chat-send-btn'>
					<i alt='Send' className='bi bi-chevron-double-right'></i>
				</button>
			</form>
		</>
	);
}
