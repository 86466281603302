import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useAuth } from '../../../contexts/AuthContext';
import { useLiveChatDB } from '../../../contexts/LiveChatDBContext';

function Chat({ title, activity, chatID, action }) {
  const { currentUser } = useAuth();
  const { acceptChat, closeLiveChat } = useLiveChatDB();
  return (
    <tr>
      <td>
        <Link className='bold blue Link' to={`/support/chatpage/${chatID}`}>
          {title}
        </Link>
      </td>
      <td>{activity}</td>
      <td>
        {action === 'accept' ? (
          <button className='btn btn-success' onClick={() => acceptChat(chatID, currentUser.uid)}>
            Start
          </button>
        ) : (
          <button className='btn btn-danger' onClick={() => closeLiveChat(chatID)}>
            Close
          </button>
        )}
      </td>
    </tr>
  );
}

export default function ActiveChats() {
  const { currentUser } = useAuth();
  const { getLiveChatsRef } = useLiveChatDB();
  const liveChatsRef = getLiveChatsRef();

  const query = liveChatsRef.where('activity', '!=', 'closed');
  // .orderBy('createdAt', 'desc')

  const [liveChats, setLiveChats] = useState([]);

  useEffect(() => {
    const chatUnsub = query.onSnapshot((snapshot) => {
      const liveChats = snapshot.docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });
      setLiveChats(liveChats);
    });
    return chatUnsub;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className='col-xl-8 col-lg-12 col-md-12 col-sm-12'>
        <div className='space'></div>
        <h4 className='semibold gray'>Active Chats</h4>
        <div className='space-sm'></div>
        <div className='options'></div>

        <div className='space-sm'></div>
        <table className='table'>
          <thead>
            <tr>
              <th>User</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {liveChats
              ?.filter((chat) => {
                if (
                  chat.department === 'Hosting' &&
                  (!chat.supportID || chat.supportID === currentUser.uid)
                )
                  return true;
                return false;
              })
              .map((chat) => {
                return (
                  <Chat
                    key={chat.id}
                    title={chat.clientName}
                    activity={chat.activity}
                    department={chat.department}
                    chatID={chat.id}
                    action={chat.activity !== 'active' ? 'accept' : 'close'}
                  />
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
}
