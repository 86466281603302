import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useSiteData } from '../../contexts/SiteDataContext';

import PricingCard from '../../components/cards/PricingCard';

import curvefoot from '../../assets/curvefoot.svg';
import curvehead from '../../assets/curvehead.svg';
import Dedicated1 from '../../assets/NewArt/Deticated.svg';
import Dedicated2 from '../../assets/Hosting/Dedicated2.svg';
import Email1 from '../../assets/NewArt/Hosting_Mail.svg';
import Email2 from '../../assets/Hosting/Email2.svg';
import Shared1 from '../../assets/NewArt/SharedHosting.svg';
import Shared2 from '../../assets/Hosting/Shared2.svg';
import VPS1 from '../../assets/NewArt/VPS.svg';
import VPS2 from '../../assets/Hosting/Vps2.svg';
import Wordpress1 from '../../assets/NewArt/Wordpress.svg';
import Wordpress2 from '../../assets/Hosting/Shared2.svg';
import Reseller1 from '../../assets/NewArt/Reseller.svg';
import Reseller2 from '../../assets/Hosting/Shared2.svg';
import Header from '../../components/ui/Header';

import { useTranslation } from 'react-i18next';

export default function HostingPrices({ type }) {
  const { i18n } = useTranslation();
  const location = useLocation();
  const { hostingPlanGet } = useSiteData();
  const [prices, setPrices] = useState([]);
  const [period, setPeriod] = useState('monthly');
  useEffect(() => {
    hostingPlanGet(type).then((data) => {
      setPrices(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header
        bg={
          type === 'Shared Hosting'
            ? Shared1
            : type === 'Reseller Hosting'
            ? Reseller1
            : type === 'Vps Hosting'
            ? VPS1
            : type === 'Wordpress Hosting'
            ? Wordpress1
            : type === 'Email Hosting'
            ? Email1
            : type === 'Dedicated Hosting'
            ? Dedicated1
            : null
        }
      />
      <img alt='' style={{ width: '100%' }} src={curvehead} />

      <section style={{ backgroundColor: '#F5F7FC' }}>
        <div className='space-sm'></div>
        <div className='plan-selection-container'>
          <div className='plan-selection'>
            <div className='left-b'>
              <button
                onClick={() => setPeriod('monthly')}
                className={`bold ${period === 'monthly' && 'active-b'}`}
              >
                Monthly
              </button>
            </div>
            <div className='right-b'>
              <button
                onClick={() => setPeriod('yearly')}
                id='year'
                className={`bold ${period === 'yearly' && 'active-b'}`}
              >
                Yearly
              </button>
            </div>
          </div>
        </div>

        <div className='container'>
          <div className='row'>
            {prices &&
              prices.map((info, i) => {
                const discount =
                  period === 'monthly'
                    ? Math.round(
                        100 *
                          Math.abs(
                            (info.monthlyPriceOld - info.monthlyPrice) /
                              ((info.monthlyPriceOld + info.monthlyPrice) / 2)
                          )
                      )
                    : Math.round(
                        100 *
                          Math.abs(
                            (info.yearlyPriceOld - info.yearlyPrice) /
                              ((info.yearlyPriceOld + info.yearlyPrice) / 2)
                          )
                      );
                return (
                  <div className='col-md-6 col-lg-3 col-s-12 cards-nth'>
                    <PricingCard
                      title={
                        i18n.language === 'ar'
                          ? info?.ARplanName
                          : i18n.language === 'tr'
                          ? info?.TRplanName
                          : info?.planName
                      }
                      price={period === 'monthly' ? info.monthlyPrice : info.yearlyPrice}
                      per={period === 'monthly' ? '/mo' : '/yr'}
                      oldPrice={period === 'monthly' ? info.monthlyPriceOld : info.yearlyPriceOld}
                      discount={discount}
                      type={type}
                      link={`/hosting/${location.pathname.split('/')[2]}/plan/${i}`}
                      perk={
                        i18n.language === 'ar'
                          ? info?.ARfeatures
                          : i18n.language === 'tr'
                          ? info?.TRfeatures
                          : info?.features
                      }
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </section>
      <img alt='' className='z' src={curvefoot} />
      <div className='curvefoot'></div>
      <div className='center'>
        <h1 className='bolder black'>Why servers and hosts Why Not Tech</h1>
        <h6 className='gray'>
          The Why Not Tech team offers the best levels of technical services to get the best
          resultsfor our valued customers
        </h6>
      </div>
      <div className='curvefoot'></div>
    </>
  );
}
