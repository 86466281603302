import React from 'react';
import head1 from '../../assets/Hosting/header1.svg';
import head2 from '../../assets/Hosting/header2.svg';
import Service from '../../components/cards/Service';
import curvehead from '../../assets/curvehead.svg';
import img1 from '../../assets/Hosting/image1.svg';
import img2 from '../../assets/Hosting/image2.svg';
import img3 from '../../assets/Hosting/image3.svg';
import img4 from '../../assets/Hosting/image4.svg';
import img5 from '../../assets/Hosting/image5.svg';
import img6 from '../../assets/Hosting/image6.svg';
import curvefoot from '../../assets/curvefoot.svg';
import Header from '../../components/ui/Header';
import { useTranslation } from 'react-i18next';
import HostingBG from '../../assets/NewArt/Hosting.svg';
export default function Hosting() {
  const { t } = useTranslation();
  return (
    <>
      <Header bg={HostingBG} />
      <div className='space-sm'></div>
      <h1 style={{ margin: 0 }} className='bold-ser black center'>
        {t('hosting.choose-plan')}
      </h1>
      <div className='space-sm'></div>
      <img alt='' className='curvehead' src={curvehead} />
      <section className='service-container' style={{ backgroundColor: '#F5F7FC' }}>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4 col-md-6 col-sm-12'>
              <Service
                img={img1}
                Title={t('hosting.shared')}
                desc={t('hosting.shared.desc')}
                link='/hosting/shared-hosting'
              />
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12'>
              <Service
                img={img2}
                Title={t('hosting.reseller')}
                desc={t('hosting.reseller.desc')}
                link='/hosting/reseller-hosting'
              />
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12'>
              <Service
                img={img3}
                Title={t('hosting.wordpress')}
                desc={t('hosting.wordpress.desc')}
                link='/hosting/wordpress-hosting'
              />
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12'>
              <Service
                img={img4}
                Title={t('hosting.email')}
                desc={t('hosting.email.desc')}
                link='/hosting/email-hosting'
              />
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12'>
              <Service
                img={img5}
                Title={t('hosting.vps')}
                desc={t('hosting.vps.desc')}
                link='/hosting/vps-hosting'
              />
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12'>
              <Service
                img={img6}
                Title={t('hosting.dedicated')}
                desc={t('hosting.dedicated.desc')}
                link='/hosting/dedicated-hosting'
              />
            </div>
          </div>
        </div>
      </section>
      <img alt='' className='z' src={curvefoot} />
      <div className='curvefoot'></div>
      <div className='center'>
        <h1 className='bolder black'>{t('hosting.1.title')}</h1>
        <h5 style={{ padding: '0 5%' }} className='gray'>
          {t('hosting.1.desc')}
        </h5>
      </div>
      <div className='curvefoot'></div>
      <div className='curvefoot'></div>
    </>
  );
}
