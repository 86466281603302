import React, { createContext, useContext } from 'react';
import firebase, { firestore } from '../lib/Firebase';

const LiveChatDBContext = createContext();

export function useLiveChatDB() {
	return useContext(LiveChatDBContext);
}

export function LiveChatDBProvider({ children }) {
	function getLiveChatsRef() {
		const chatsRef = firestore.collection('liveChat');
		return chatsRef;
	}

	function getLiveChatRef(chatID) {
		const chatRef = getLiveChatsRef().doc(chatID);
		return chatRef;
	}

	async function closeLiveChat(chatID) {
		const liveChatRef = firestore.collection('liveChat').doc(chatID);
		await liveChatRef.update({
			activity: 'closed',
		});
		const clientID = (await liveChatRef.get()).data().clientID;
		const clientRef = firestore.collection('users').doc(clientID);
		await clientRef.update({
			liveChat: null,
		});
	}

	async function acceptChat(chatID, supportID) {
		const liveChatRef = firestore.collection('liveChat').doc(chatID);
		await liveChatRef.update({
			activity: 'active',
			supportID: supportID,
		});
	}

	async function sendLiveChatMessage(chatID, sender, message, file = false) {
		const liveChatRef = firestore.collection('liveChat').doc(chatID).collection('messages').doc();
		await liveChatRef.set({
			message: message,
			sender: sender,
			createdAt: firebase.firestore.FieldValue.serverTimestamp(),
		});
	}

	async function transferLiveChat(chatID, newDepartment) {
		const liveChatRef = firestore.collection('liveChat').doc(chatID);
		await liveChatRef.update({
			suppordID: null,
			activity: 'transfering',
			department: newDepartment,
		});
	}

	async function liveChatSetTyping(chatID, typing) {
		const liveChatRef = firestore.collection('liveChat').doc(chatID);
		await liveChatRef.update({
			typing: typing,
		});
	}

	const value = {
		getLiveChatsRef,
		getLiveChatRef,
		closeLiveChat,
		acceptChat,
		sendLiveChatMessage,
		transferLiveChat,
		liveChatSetTyping,
	};

	return <LiveChatDBContext.Provider value={value}>{children}</LiveChatDBContext.Provider>;
}
