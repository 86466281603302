import React from 'react';

export default function Privacypolicy() {
  return (
    <>
      <div style={{ padding: '5% 5%' }} className='blue-bg'>
        <p className='black ser'>
          <b>Privacy policy</b>
          <br></br> Welcome to Why Not for digital solutions,<br></br> in this page we provide you
          the privacy policy when you visit our site or using one of our products or services, that
          include all the information you need to know on how your personal information is dealt
          with. In the following lines we refer to Why Not’s website as “website / site”.
          <br></br>
          <br></br>
          legal responsibility<br></br>
          we collect personal information to provide better services for all our new and current
          customers, that include names, emails, phone numbers, IP address and other information.
          “Why Not” company respects your right in keeping your personal information in a safe place
          without sharing it with a third parties. We don’t make your personal information available
          to use by any third parties for advertising or what so. And we agree to keep this
          information in a safe place according to our abilities. The user agrees that they are the
          first responsible person on the way they use the site, and we are not held responsible for
          what the user might suffer of losses, damages or costs that might lie on the user by using
          or not using the site.
          <br></br>
          <br></br>
          What are the information that we gather?<br></br>
          Here follows all the personal information that we collect from the website users: Data
          that you provide to us: When using the site, requesting a service, or communicating with
          us, you will be asked to enter a set of personal information from your name, phone number
          (optional) and e-mail, which is essential to ensure your correspondence and provide the
          service at a later time. Data we collect from you: When you browse the site, we collect a
          variety of information about you, for example your IP address, country, referring site,
          operating system, and the time the site was used. This information may be obtained by
          third parties or through cookies. Date provided by a third party: we use some aiding tools
          to enhance our site and provide a group of information about how you use the site.
          <br></br>
          <br></br>
          What are your information used for?<br></br>
          We did not create the “Why Not” website to collect data from visitors or their devices, as
          it is protected by intellectual property rights and laws. Where your personal information
          is used in your communication with us, provide services, create accounts, and perform
          maintenance work. In addition, we use your information to provide you with new services
          and updates that occur; the main objective of collecting it is to understand user needs
          and progress and to improve the quality of the site and services provided. To achieve that
          we require your agreement, and for the information of services, your ordering of service
          will be considered an agreement of using your personal information to provide the service
          you ordered.
          <br></br>
          <br></br>
          What are your rights?<br></br>
          At “Why Not” we deal with all our customers and users equally, where all users have the
          right to:
          <br></br>
          <b>Knowing what data is collected about them.</b>
          <br></br>
          <b>View their personal data.</b>
          <br></br>
          <b>Remove their personal data.</b>
          <br></br>
          <b>How is this data stored.</b>
          <br></br>
          <b>Restricting data access.</b>
          <br></br>
          <b>Transfer their private data.</b>
          <br></br>
          <b>Objection to any use of data.</b>
          <br></br>
          For how long will we keep your information and date?<br></br>
          Your data will be deleted once it has been determined that it is no longer needed or there
          is no legal basis for its processing. The data associated with the implementation of the
          services will remain until the completion of the implementation of the service, after
          which it will be deleted directly. In addition, you have the right to delete your personal
          data at any time.
          <br></br>
          <br></br>
          While surfing the site<br></br>
          While surfing “Why Not’s” website you will have to deal with:<br></br>
          Cookies: we use cookies to provide a better user experience, through installing a file on
          your browser, to track how you browse the site, which can be deleted or canceled from your
          browser settings.
          <br></br>Linking to other sites: The site may include links directed to other sites,
          whether it is a third party's site, tool, or program, and we disclaim all responsibility
          for the information and data collected when you visit or use these sites. As stated on our
          site, we do not use or deal with advertising companies.
          <br></br>IP Address: Such as any other site, our server will record your IP information,
          the time and date of the visit and the browser used. Secrecy of your personal information
          <br></br>
          <br></br>
          “Why Not” respects the privacy and confidentiality of all personal information, and this
          information has not been disclosed to any third parties without your consent, except when
          required to do so by law or for the defense of the site's or the parties' property rights.
          Using services<br></br>
          When you order a service from “Why Not” we need to access all personal information and
          data to provide the required service. Where we ask for your permission to provide them
          according to your own free will. Which makes communication and execution of your order
          easier and done in the best way. It is necessary to mention that any information you
          provided us with will not be used in any other thing except the execution of your service
          and they will not be available to a 3rd party without your agreement.
          <br></br>
          This privacy policy can be changed or edited at any time, we advise you to revise them
          from time to time to understand the changes made, if you have any inquires you can contact
          us through the Contact Us page
        </p>
      </div>
      <div style={{ padding: '5% 5%' }} className='blue-bg'>
        <p dir='rtl' className='black ser'>
          <b>صفحة سياسة الخصوصية</b> <br></br>
          مرحبا بك في شركة واي نوت للحلول الرقمية في هذه الصفحة نقدم لك سياسة الخصوصية عند زيارة
          الموقع أو استخدام أحد منتجاتنا أو خدماتنا، مشتملة على كافة المعلومات التي تحتاج معرفتها
          لطريقة التعامل مع بياناتك الخاصة. وفي الأسطُر التالية يُشار بلفظ "الموقع" إلى موقع شركة
          واي نوت.
          <br></br>
          <br></br>
          المسؤولية القانونية
          <br></br>
          نحن نجمع المعلومات الشخصية لتقديم خدمات أفضل لكل عملائنا الجُدد والدائمين، من الأسماء
          والبريد الإلكتروني وأرقام الهواتف وعنوان الـ ip ومعلومات أخرى. وتحترم شركة واي نوت حقك في
          الحفاظ على بياناتك الخاصة في مكان آمن وبدون مشاركتها مع أي طرف ثالث.
          <br></br>
          فنحن لا نتيح المعلومات الشخصية لأي طرف ثالث لاستخدامها للأغراض الإعلانية أو ما شابه ذلك.
          ونقر على تحفظنا على هذه البيانات في مكان آمن وفي حدود إمكانياتنا.
          <br></br>
          ويقر المستخدم أنه المسؤول الأول عن طريقته لاستخدام الموقع، ونخلي طرفنا للحد الأقصى من
          الخسائر أو الأضرار أو النفقات التي قد يتكبدها المستخدم جراء استخدام أو عدم استخدام الموقع.
          <br></br>
          <br></br>
          ما هي البيانات التي نجمعها؟
          <br></br>
          فيما يلي كافة البيانات الشخصية التي نجمعها من مستخدمي الموقع:
          <br></br>
          بيانات تقدمها لنا: عند استخدام الموقع أو طلب أحد الخدمات أو التواصل معنا سيُطلب منك ادخال
          مجموعة من المعلومات الشخصية من الاسم ورقم الهاتف (اختياري) والبريد الإلكتروني والتي تعد
          أساسية لضمان مراسلتك وتقديم الخدمة لأحقًا. المعلومات التي نجمعها منك: عند تصفح الموقع نجمع
          مجموعة من المعلومات عنك، على سبيل المثال عنوان الـ ip والبلد وموقع الإحالة ونظام التشغيل
          وزمن استخدام الموقع. وهذه المعلومات قد نحصل عليها من قبل طرف ثالث او من خلال ملفات تعريف
          الارتباط. المعلومات المقدمة من طرف ثالث: نستخدم بعض الأدوات المساعدة في التحسين من الموقع
          والمقدمة مجموعة من المعلومات حول طريقتك لاستخدام الموقع.
          <br></br>
          <br></br>
          ماذا نفعل ببياناتك؟
          <br></br>
          موقع شركة واي نوت يخضع لقوانين وحقوق الملكية الفكرية، ولم يُنشئ بهدف جمع البيانات من
          الزوار أو من أجهزتهم. حيث نستخدم معلوماتك الشخصية للتواصل معنا وتقديم الخدمات وإنشاء
          الحسابات وأعمال الصيانة، كما نعمل على تزويدك من خلالها بالخدمات الجديدة والتحديثات التي
          تطرأ عليها، حيث يمثل الهدف الأساسي من جمعها هو فهم احتياجات المستخدم وتقدم والتحسين من
          جودة الموقع والخدمات المقدمة.
          <br></br>
          ولتحقيق ذلك نحتاج لموافقة منك، وبالنسبة لمعلومات الخدمات سيكون طلب الخدمة بمثابة موافقة
          على استخدام بياناتك الشخصية لتوفير الخدمة التي طلبتها.
          <br></br>
          ويُحتفظ بمعلوماتك الشخصية طالما كانت ضرورية للأغراض السابقة الذكر، وبعد الانتهاء من تقديم
          الخدمة وعدم الحاجة لها في أيّ خطوة إضافية سيتم حذفها أوعزلها من قواعد بياناتنا.
          <br></br>
          <br></br>
          ما هي حقوقك؟
          <br></br>
          في موقع شركة واي نوت نتعامل مع كافة عملائنا ومستخدمي موقعنا بمساواة، حيث أن كل مستخدم لديه
          الحق في:
          <br></br>
          <b>معرفة البيانات التي يتم جمعها عنه.</b> <br></br>
          <b>عرض بياناته الشخصية.</b> <br></br>
          <b>إزالة بياناته الشخصية.</b> <br></br>
          <b>كيف تُحفظ هذه البيانات. </b>
          <br></br>
          <b>تقييد الوصول إلى البيانات. </b>
          <br></br>
          <b>نقل بياناته الخاصة. </b>
          <br></br>
          <b> الاعتراض على أي استخدام للبيانات. </b>
          <br></br>
          <br></br>
          <b>كم من الوقت سنحتفظ ببياناتك؟</b>
          <br></br>
          ستعمل على حذف كافة بياناتك التي جمعناها عنك بعد التأكد من أنه لا حاجة لها أو لا يوجد أساس
          قانوني لمعالجتها، وبالنسبة لبيانات تنفيذ الخدمات ستكون موجودة لغاية الانتهاء من تنفيذ
          الخدمة، من بعد ذلك سيتم حذفها مباشرة. كما سيكون لديك الحق في حذف بياناتك الشخصية في أي
          وقت.
          <br></br>
          عند تصفح الموقع
          <br></br>
          عند تصفح موقع شركة واي نوت ستضطر للتعامل مع:
          <br></br>
          ملفات تعريف الارتباط: نستخدم ملفات تعريف الارتباط أو الكوكيز لتوفير أفضل تجربة مستخدم
          ممكنة، من خلال تثبيت ملف على المتصفح الخاص بك، لتتبع طريقة تصفحك للموقع، ومن الممكن حذفها
          أو إلغاء من خلال إعدادات المتصفح الخاص بك. الربط بالمواقع الأخرى: قد يشتمل الموقع على بعض
          الروابط الموجهة لطرف ثالث سواء كان موقع أو أداة أو برنامج ونحن نخلي مسؤوليتنا عن البيانات
          والمعلومات التي قد يتم جمعها منك عند التوجه أو استخدام هذه المواقع. ونذكر أننا لا نستخدم
          أو نتعامل مع الشركات الإعلانية داخل موقعنا. عنوان الآي بي (IP): مثل أي موقع آخر سيقوم
          السيرفر الخاص بالموقع بتسجيل معلومات عنوان الأي بي الخاص بك، وتاريخ ووقت الزيارة ونوع
          المتصفح.
          <br></br>
          سرية بياناتك الشخصية
          <br></br>
          نلتزم في موقع شركة واي نوت على المحافظة على خصوصية وسرية كافة بياناتك الشخصية، كما لم يتم
          الإفشاء عن هذه المعلومات لأي طرف ثالث إلا إذا كان بموجب القانون أو عندما يكون تنفيذ هذا
          النوع من الإجراءات يتماشى مع القانون أو الدفاع عن حقوق الملكية في الموقع أو الجهات
          المستفيدة.
          <br></br>
          تنفيذ الخدمات
          <br></br>
          عند طلب خدمة من شركة واي نوت فإننا نحتاج للحصول على كافة البيانات والمعلومات الشخصية
          لتقديم الخدمة المطلوبة. حيث نطلب منك تقديمها على محض إرادتك. بما يسهل من الاتصال وتنفيذ
          الطلب على أكمل وجه. ووجب التذكير إلى أنه لن تُستخدم أي بيانات زودتنا بها في أي شيء آخر غير
          تنفيذ الخدمة وعدم إتاحتها لطرف ثالث بدون الحصول على موافقتك.
          <br></br>
          سياسة الخصوصية هذه قابلة للتعديل في أيّ وقت، من المرجو مراجعتها من حين لآخر للتعرف على
          التحديثات التي تمت، وفي حال كان لديك أيّ استفسار تفضل بمراسلتنا عبر صفحة اتصل بنا.
        </p>
      </div>
    </>
  );
}
