import React from 'react';
// import { sendEmail } from '../lib/Emailer';

export default function Test() {
	function sendEmailHandler() {
		// sendEmail('khizerminai@gmail.com', 'Test', '<h1>Test</h1>');
	}

	return (
		<>
			<button onClick={sendEmailHandler}>Send</button>
		</>
	);
}
