import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import PrivateRoute from '../../utils/PrivateRoute';
import { useTicketDB } from '../../contexts/TicketDBContext';

import SideMenu from './components/SideMenu';
import UpdateInfo from './components/UpdateInfo';
import ChangePassword from './components/ChangePassword';
import MyTickets from './components/MyTickets';
import SubmitTicket from './components/SubmitTicket';
import TicketPage from './components/TicketPage';
import MyItems from './components/MyItems';

import TicketMouse from '../../assets/ticketmouse.svg';
import { useTranslation } from 'react-i18next';

export default function Profile({ name, mouse }) {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const location = useLocation().pathname;
  const { ticketID } = useParams();
  const { ticketGet } = useTicketDB();
  const [ticket, setTicket] = useState(null);

  useEffect(() => {
    ticketGet(ticketID).then(setTicket);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketID]);

  const Nome = () => {
    return t(name);
  };

  return (
    <>
      <PrivateRoute goto='/login' roles={['client', 'admin', 'support']}>
        <section className='header'>
          <h1 className='n-font head-title' style={{ color: 'white', fontWeight: 'bold' }}>
            {location.includes('/ticket-page/') ? 'Ticket: ' + ticket?.ticketCustomID : Nome()}
          </h1>
        </section>

        <div className='space'></div>
        <div
          style={{
            direction: `${i18n.language === 'ar' ? 'rtl' : ''}`,
            textAlign: `${i18n.language === 'ar' ? 'right' : 'left'}`,
          }}
          className='container'
        >
          <div className='row'>
            <div className='col-xl-3 col-lg-12 col-md-12 col-sm-12'>
              <SideMenu />
              {mouse && (
                <img
                  style={{ position: 'static', paddingTop: '3rem' }}
                  className='pics'
                  alt=''
                  src={TicketMouse}
                />
              )}
            </div>

            <div className='col-xl-1'></div>
            {location === '/profile' ? (
              <UpdateInfo />
            ) : location === '/change-password' ? (
              <ChangePassword />
            ) : location === '/my-tickets' ? (
              <MyTickets />
            ) : location === '/submit-ticket' ? (
              <SubmitTicket />
            ) : location.includes('/ticket-page/') ? (
              <TicketPage />
            ) : location === '/my-items' ? (
              <MyItems />
            ) : null}
          </div>
          <div className='space' />
        </div>
      </PrivateRoute>
    </>
  );
}
