import React, { useRef, useState, useEffect } from 'react';
import { Form, Alert } from 'react-bootstrap';

import { useAuth } from '../../../contexts/AuthContext';
import { useUserDB } from '../../../contexts/UserDBContext';

export default function UpdateInfo() {
	const emailRef = useRef();
	const userNameRef = useRef();
	const firstNameRef = useRef();
	const lastNameRef = useRef();
	const organizationRef = useRef();
	const phoneRef = useRef();
	const passwordRef = useRef();

	const { currentUser } = useAuth();
	const { userInfoUpdate } = useUserDB();

	const [error, setError] = useState(null);
	const [message, setMessage] = useState(null);
	const [loading, setLoading] = useState(false);
	const [pPop, setpPop] = useState(false);

	function saveChanges(e) {
		e.preventDefault();
		setpPop(true);
	}

	async function handleSubmit(e) {
		e.preventDefault();
		setError(null);
		setMessage(null);
		setLoading(true);
		try {
			await userInfoUpdate({
				email: emailRef.current.value,
				userName: userNameRef.current.value,
				firstName: firstNameRef.current.value,
				lastName: lastNameRef.current.value,
				organization: organizationRef.current.value,
				phoneNumber: phoneRef.current.value,
				password: passwordRef.current.value,
			});
			setMessage('Profile updated successfully');
		} catch (error) {
			setError(error.message);
		}
		setLoading(false);
	}

	useEffect(() => {
		emailRef.current.placeholder = currentUser.data.email;
		userNameRef.current.placeholder = currentUser.data.userName;
		firstNameRef.current.placeholder = currentUser.data.firstName;
		lastNameRef.current.placeholder = currentUser.data.lastName;
		organizationRef.current.placeholder = currentUser.data.organization;
		phoneRef.current.placeholder = currentUser.data.phoneNumber;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{pPop && (
				<section className='password-popup'>
					<div className='signup-form'>
						<h4 className='form-font center'>Confirm password</h4>
						<Form>
							<input ref={passwordRef} name='email' type='password' className='form-field' placeholder='Password' />
							<button onClick={handleSubmit} disabled={loading} variant='primary' type='submit' className='button signin-button'>
								Confirm Password
							</button>
						</Form>
					</div>
				</section>
			)}
			<div className='col-xl-8 col-lg-12 col-md-12 col-sm-12'>
				<div className='space'></div>

				<h4 className='semibold gray'>General information</h4>
				{error && <Alert variant='danger'>{error}</Alert>}
				{message && <Alert variant='success'>{message}</Alert>}
				<div className='d-table'>
					<div className='form-fields'>
						<Form>
							<div>
								<p className='input-lable'>Email:</p>
								<input ref={emailRef} type='text' className='form-field-small' />
							</div>
							<div>
								<p className='input-lable'>User Name:</p>
								<input ref={userNameRef} type='text' className='form-field-small' />
							</div>
							<div>
								<p className='input-lable'>First Name:</p>
								<input ref={firstNameRef} type='text' className='form-field-small' />
							</div>
							<div>
								<p className='input-lable'>Last Name:</p>
								<input ref={lastNameRef} type='text' className='form-field-small' />
							</div>
							<div>
								<p className='input-lable'>Organization:</p>
								<input ref={organizationRef} type='text' className='form-field-small' />
							</div>
							<div>
								<p className='input-lable'>Phone:</p>
								<input ref={phoneRef} type='text' className='form-field-small' />
							</div>
							<button disabled={loading} onClick={saveChanges} className='button-sm'>
								Save Changes
							</button>
						</Form>
					</div>
				</div>
			</div>
		</>
	);
}
