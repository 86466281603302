import React from 'react';
import { useAuth } from '../../../../contexts/AuthContext';
import { useUserDB } from '../../../../contexts/UserDBContext';
import { useTranslation } from 'react-i18next';
export default function Confirm({ setConfirm, setIsOpen, setActive }) {
  const { t } = useTranslation();
  const { closeLiveChat } = useUserDB();
  const { currentUser } = useAuth();

  function handleYes(e) {
    e.preventDefault();
    closeLiveChat(currentUser?.data?.liveChat);
    setConfirm(false);
    setIsOpen(false);
    setActive(false);
  }

  function handleNo(e) {
    e.preventDefault();
    setConfirm(false);
  }
  return (
    <>
      <div className='chat-body-start'>
        <h5 className='black center'>{t('ending-chat')}</h5>
        <hr></hr>
        <div
          style={{
            paddingTop: '25%',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <h5 className='bold black'>{t('are-you-sure')}</h5>
          <button onClick={handleYes} className='signin-button'>
            Yes
          </button>
          <button onClick={handleNo} style={{ marginTop: '1rem' }} className='signup-button'>
            No
          </button>
        </div>
      </div>
    </>
  );
}
